import { signal } from "@preact/signals-react";
import {
  FotFunctionJSPage,
  FunctionJsPageData,
  FunctionJsPageDataFunctionValue,
} from "@uikit/cases/fotNavigate/FotFunctionJSPage";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreateService } from "@uikit/service/CreateService";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { HandleSaveUtil } from "@uikit/util/HandleSaveUtil";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { CUSTOM_FUNCTION_VARS_REG, CUSTOM_FUNCTION_VARS_JS_REG } from "@views/thinking-layout-editor/constants";
import { allPreDefinedFunctions } from "@views/thinking-layout-editor/nodeTypeComponents/nodeTypeDispose";
import { FotAuthStore } from "imagica-corekit/dist/base/store/FotAuthStore";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import css from "./CustomJSFunction.module.css";
import { RelationSetDestCodeData } from "imagica-corekit/dist/base/api/graphqlTyped/RelationSetDest";
import { FunctionModel } from "@views/thinking-layout-editor/types";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { FeatureTags } from "imagica-corekit/dist/base/domain/project/FeatureTags";

class State {
  codeData = signal<Required<RelationSetDestCodeData>>({
    htmlStr: "",
    cssStr: "",
    jsStr: "",
  });
  isLoading = signal(false);
}

const customErrorTip = `window.onerror = function(e){
    const customdiv = document.getElementById('error-tips-auto')
    customdiv.setAttribute("style","display: block;");
    customdiv.innerHTML = e
    setTimeout(() => {
      customdiv.setAttribute("style","display: none");
    }, 5000)
    console.error('\\nerror ' + e + ':');
    return true;
  }`;
const errorStyl = `
  #error-tips-auto {
    background: red;
    color: #fff;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 999999;
    padding: 5px;
    border-radius: 5px;
    display: none;
  }
`;
export class CustomJSFunctionMainBloc {
  state: State = new State();

  contentBoxRef: HTMLDivElement | null = null;

  container = {
    gql: getIt(ObjectRelationGqlService),
    createService: getIt(CreateService),
    creatorNodesStore: getIt(CreatorNodesStore),
    creatorEdgesStore: getIt(CreatorEdgesStore),
    canvasDataRef: getIt(CanvasDataRef),
    showNavPageStore: getIt(ShowNavPageStore),
    fotFunctionJSPage: getIt(FotFunctionJSPage),
    fotReduxStore: getIt(FotReduxStore),
    creatorStoreMethods: getIt(CreatorStoreMethods),
    homeStore: getIt(HomeStore),
  };

  get featureTags(): FeatureTags {
    return this.container.homeStore.state.featureTags;
  }

  handleOk = async (): Promise<any> => {
    const createJSFuncData = this.container.fotFunctionJSPage.getPageData();

    const newFunctionData = {
      description: createJSFuncData?.parameter?.description || "",
      edgeArr: [],
      name: createJSFuncData?.parameter?.name || "",
      nodeIdArr: [],
      type: "CustomCode",
      codeData: this.state.codeData.value,
    };
    // const functionId = props.modifyCodeObj.id;
    const functionId = createJSFuncData?.select?.id;
    try {
      this.state.isLoading.value = true;
      if (functionId) {
        const renewData = await this.container.gql.updateObject(functionId, {
          name: "studio_function",
          attributes: newFunctionData,
        });

        HandleSaveUtil.handleUserSavedFunctions({
          each: { user: (createJSFuncData?.select as any)?.originElement?.user, ...renewData },
          isUpdate: true,
          nodeDataRef: this.container.canvasDataRef.nodeDataRef,
          personalFunctionStore: getIt(PersonalFunctionStore),
          creatorRefStore: creatorRefStore,
          allPreDefinedFunctions: allPreDefinedFunctions,
          isSort: true,
        });
      } else {
        const currentSingleFlow = creatorRefStore.singleFlowEdgeArrRef.current.find(
          x => x.name === newFunctionData.name
        );
        if (!func.isEmpty(currentSingleFlow)) {
          func.messageUtil("Duplicate Name", "error");
          this.state.isLoading.value = false;
          return;
        }

        const callback = (object: any): void => {
          HandleSaveUtil.handleUserSavedFunctions({
            each: object,
            isUpdate: false,
            // 原来调用方法并没有传入 nodeDataRef???, 所以这里变为空
            // nodeDataRef: nodeDataRef,
            nodeDataRef: { current: {} },
            personalFunctionStore: getIt(PersonalFunctionStore),
            creatorRefStore: creatorRefStore,
            allPreDefinedFunctions: allPreDefinedFunctions,
            isSort: true,
          });
        };
        await this.container.createService.createFunction(newFunctionData, false, callback);
      }

      this.state.isLoading.value = false;
      func.messageUtil("Success", "success");

      const clickFuncData = {
        edgeId: createJSFuncData.edgeId,
        functionName: newFunctionData.name,
      };
      this.container.fotReduxStore.setCreateFunctionClick(clickFuncData);
      this.onCancel();
      // 如果在首页则不继续执行
      if (this.container.showNavPageStore.state.showNavPage) return;
      // 给按钮下的文本赋值
      this.container.creatorStoreMethods.setEdgeLineParam({
        id: createJSFuncData.edgeId,
        enterText: `/${newFunctionData.name}`,
      });
      // 显示run all按钮
      this.container.creatorEdgesStore.setQueryLoading(createJSFuncData.edgeId, "isGetQuery", true);
    } catch (error) {
      console.error(error);
      this.state.isLoading.value = false;
      func.messageUtil("Failed to save function", "error");
    } finally {
      this.container.fotFunctionJSPage.changePageData({ loading: false });
    }
  };

  clickRun = ({ htmlStr, cssStr, jsStr }: RelationSetDestCodeData = {}): void => {
    const createJSFuncData = this.container.fotFunctionJSPage.getPageData();
    const variableList = this.container.fotReduxStore.getState().fot.variableList;

    let htmlValue = htmlStr || this.state.codeData.value.htmlStr || "";
    let cssValue = cssStr || this.state.codeData.value.cssStr || "";
    let jsValue = jsStr || this.state.codeData.value.jsStr || "";
    const nodes = this.container.creatorNodesStore.getNodes();
    const currentSourceNode = nodes.find(x => x.id === createJSFuncData.sourceNodeId);
    let currentSourceNodeValue = currentSourceNode?.data?.textAreaValue || "";
    if (currentSourceNodeValue instanceof Object && !func.isEmpty(currentSourceNodeValue.data.value)) {
      currentSourceNodeValue = JSON.stringify(currentSourceNodeValue.data.value);
    }

    const brainAuthToken = getIt(FotAuthStore).state.brainToken || "";

    // if(currentSourceNodeValue) {
    // htmlValue = htmlValue.replaceAll("${currentSourceNodeValue}", currentSourceNodeValue)
    htmlValue = htmlValue.replaceAll(CUSTOM_FUNCTION_VARS_REG, (match: string) => {
      if (match === "${currentSourceNodeValue}") return currentSourceNodeValue;
      if (match === "${brainAuthToken}") return brainAuthToken;
    });
    jsValue = jsValue.replaceAll(CUSTOM_FUNCTION_VARS_JS_REG, (match: string) => {
      // if (match === 'currentSourceNodeValue') return `'${currentSourceNodeValue}'`
      if (match === "currentSourceNodeValue") return JSON.stringify(currentSourceNodeValue);
      if (match === "brainAuthToken") return `'${brainAuthToken}'`;
      return match;
    });
    // jsValue = jsValue.replaceAll('currentSourceNodeValue', JSON.stringify(currentSourceNodeValue))

    // }
    variableList.map((x: { name: any; node: { data: { textAreaValue: any } } }) => {
      htmlValue = htmlValue.replaceAll("${" + `${x.name}` + "}", x?.node?.data?.textAreaValue);
      cssValue = cssValue.replaceAll("${" + `${x.name}` + "}", x?.node?.data?.textAreaValue);

      jsValue = jsValue.replaceAll(new RegExp(`\\w*${x.name}\\w*`, "g"), (match: any) => {
        if (match === x.name) return JSON.stringify(x?.node?.data?.textAreaValue);
        return match;
      });
      return x;
    });
    const lineFeed = htmlValue.match(/^<html>/) ? "" : "white-space: pre-wrap;";
    const htmlObjStr = `
      <html>
        <style>${cssValue}${errorStyl}</style>
        <body style="${lineFeed}">${htmlValue}<div id="error-tips-auto"></div></body>
        <script>{${customErrorTip};${jsValue}}</script>
      </html>
    `;
    this.injectHtml(htmlObjStr);
  };

  onCancel = (): void => {
    this.container.fotFunctionJSPage.close();
    this.state.codeData.value = new State().codeData.value;
    this.injectHtml("");
  };

  injectHtml = (htmlStr: any): void => {
    // if (util.isEmpty(iframeRef.current)) return;
    const existedIframe = document.getElementById("custom-iframe");
    // 移除旧的
    if (!func.isEmpty(existedIframe)) {
      if (func.isEmpty(this.contentBoxRef)) return;
      this.contentBoxRef?.removeChild(existedIframe as HTMLElement);
      if (func.isEmpty(htmlStr)) return;
    }
    // 创建新的iframe标签
    const iframe = document.createElement("iframe");
    // 设置属性
    iframe.setAttribute("id", "custom-iframe");
    iframe.setAttribute("class", css["preview-code"]);
    // 挂载到指定元素后
    this.contentBoxRef?.appendChild(iframe);

    let iframedoc = (iframe as any).document;
    if (iframe.contentDocument) iframedoc = iframe.contentDocument;
    else if (iframe.contentWindow) iframedoc = iframe.contentWindow.document;

    if (iframedoc) {
      iframedoc.open();
      iframedoc.writeln(htmlStr);
      iframedoc.close();
    } else {
      func.customMsg({
        content: "Unable to render html code",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.container.fotFunctionJSPage.changePageData({ loading: false });
    }, 500);
  };

  changeCodeData(key: keyof RelationSetDestCodeData, value: string): void {
    this.state.codeData.value = {
      ...this.state.codeData.value,
      [key]: value,
    };
  }

  /**
   * 当 pageData 中的 select 发生改变时触发,现在是在组件中用 useEffect 触发
   * @param pageData
   * @param uicallback
   * @returns
   */
  onChangeCodeData(pageData: FunctionJsPageData): void {
    if (func.isEmpty(pageData.select)) {
      return;
    }
    // copy function在 imageGenSelectOptions 中
    const imageGenSelectOptions = this.container.fotReduxStore.getState().fot.imageGenSelectOptions;

    let currFuncData = imageGenSelectOptions.find(x => x.label === pageData.select?.label) as FunctionModel | undefined;
    // 如果没有找到则直接使用当前函数上的数据, 当 ProjectFunction 和 UiFunctionService 合并后需要处理
    if (!currFuncData) {
      currFuncData = pageData.select;
    }

    const codeData = {
      htmlStr: currFuncData?.codeData?.htmlStr || "",
      cssStr: currFuncData?.codeData?.cssStr || "",
      jsStr: currFuncData?.codeData?.jsStr || "",
    };

    this.state.codeData.value = codeData;
    this.clickRun(codeData);
  }

  onChangeFunction(createJSFuncData: FunctionJsPageData): void {
    if (func.isEmpty(createJSFuncData.function) || !createJSFuncData.function) return;
    const data: Record<FunctionJsPageDataFunctionValue, (...args: any[]) => void> = {
      clickRun: this.clickRun,
      onCancel: this.onCancel,
      handleOk: this.handleOk,
    };
    if (typeof data[createJSFuncData.function] != "function") return;
    data[createJSFuncData.function]();
    this.container.fotFunctionJSPage.changePageData({ function: undefined });
  }
}
