import { useCreation } from "ahooks";
import style from "./UpgradeButton.module.scss";
import css from "../PopoverButton.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { UpgradeButtonBloc } from "@views/thinking-layout-editor/Upgrade/UpgradeButtonBloc";
import { useEffect } from "react";
import { Popover } from "antd";
import { getIt } from "@uikit/getIt";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { UpgradeIcon } from "./UpgradeIcon";
import classNames from "classnames";
type upgradeButtonProps = {
  disable?: boolean;
};

export function UpgradeButton(props: upgradeButtonProps): JSX.Element | null {
  const location = useLocation();
  const navigate = useNavigate();
  const bloc = useCreation(() => new UpgradeButtonBloc(), []);
  const homeStore = getIt(HomeStore);
  const featureTags = homeStore.state.featureTags;

  const handleClick = (): void => {
    bloc.handleClick();
    navigate(bloc.getPlanPath(location.pathname));
  };
  useEffect(() => {
    bloc.loadData();
  }, []);

  const content = (
    <div className={style.upgradeTipBox}>
      <p className={style.tipTitle}>Upgrade</p>
      <div className={style.tipContentBox} onClick={handleClick}>
        <UpgradeIcon />
        <p className={style.tipContent}>Upgrade your plan</p>
      </div>
    </div>
  );

  if (!featureTags.enableStudiosPaywall || !bloc.state.showUpgrade.value) {
    return null;
  }

  return (
    <Popover
      content={!props.disable ? content : ""}
      placement="bottom"
      showArrow={false}
      getPopupContainer={e => e}
      overlayClassName={css.popoverWrap}
    >
      <div
        data-testid="UpgradeButton"
        className={classNames(style.upgradeContent, !props.disable ? style.cursorPointer : style.cursorPointNoDrop)}
        onClick={handleClick}
      >
        <p className={style.upgradeBox}>Upgrade</p>
      </div>
    </Popover>
  );
}
