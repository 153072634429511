import style from "./PreviewHeaderViewV2.module.scss";
import cls from "classnames";
import { Popup } from "../BluePrint/Popup";
import { AppDetailModal } from "../MultiRoundChatOnboarding/components/appDetail/AppDetail";
import { getIt } from "@uikit/getIt";
import { PreviewPaneStore, SettingPageContentType } from "../PreviewPane/PreviewPaneStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import func from "@uikit/func";
import { SettingDesignIcon } from "@uiview/views/PreviewHeaderV2/icons/SettingDesignIcon";
import { SettingFeaturesIcon } from "@uiview/views/PreviewHeaderV2/icons/SettingFeaturesIcon";
import { Tooltip } from "antd";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { FullScreen, Collapse } from "./icons/CopyLInkIcon";
import { previewStore } from "@uiview/store/PreviewStore";
import { logEvent } from "@uikit/service/amplitude";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import classNames from "classnames";
import fpcss from "@uiview/views/FotPopover.module.css";
interface Props {
  inOrOutSetting: (bol: boolean) => void;
  backPreview: () => void;
}

const creatorSaasAppStore = getIt(CreatorSaasAppStore);
// 组件
const PreviewHeaderViewV2 = (headerProps: Props) => {
  const previewPaneStore = getIt(PreviewPaneStore);
  const previewState = useImagicaStore(previewStore).value;
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const chatOnboardingState = useImagicaStore(chatOnboardingStore).value;

  const previewPaneState = useImagicaStore(previewPaneStore).value;
  const isShowSettingDesign = previewPaneState.settingPageContentType === SettingPageContentType.design;
  const isShowSettingFeatures = previewPaneState.settingPageContentType === SettingPageContentType.features;
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const clickAppDetail = async () => {
    let currentgSaasUIData = saasUIData;
    if (func.isEmpty(saasUIData?.appDetail)) {
      currentgSaasUIData = {
        ...saasUIData,
        appDetail: {
          name: saasUIData.title,
          description: saasUIData.subTitle || "",
        },
      };
      creatorSaasAppStore.setSaasUIData(currentgSaasUIData);
    }
    previewPaneStore.openAppDetails(true);
    const popup = new Popup(AppDetailModal);
    popup.open({
      onOk: () => {},
      onCancel: () => {},
      saasUIData: currentgSaasUIData,
    });

    logEvent("click_app_detail");
  };

  const clickSettingDesign = () => {
    previewStore.setPreviewCollapseState(false);
    headerProps.inOrOutSetting(!isShowSettingDesign);
    previewPaneStore.setSettingPageContentType(
      isShowSettingDesign ? SettingPageContentType.none : SettingPageContentType.design
    );
  };

  const clickSettingFeatures = () => {
    previewStore.setPreviewCollapseState(false);
    headerProps.inOrOutSetting(!isShowSettingFeatures);
    previewPaneStore.setSettingPageContentType(
      isShowSettingFeatures ? SettingPageContentType.none : SettingPageContentType.features
    );
  };

  const hoverSettingBar = (bol: boolean) => {
    previewPaneStore.setUpgradeZindex(bol);
  };

  const handleCollapse = () => {
    if (!previewStore.state.previewCollapseState) {
      headerProps.inOrOutSetting(false);
      previewPaneStore.setSettingPageContentType(SettingPageContentType.none);
    }
    previewStore.setPreviewCollapseState(!previewStore.state.previewCollapseState);
  };

  return (
    <div className={cls(style.previewHeaderBox)} data-testid="PreviewHeaderView">
      <div className={style.title}>App Preview</div>
      <div className={style.setting}>
        <div
          className={style.buttonBox}
          style={{ backgroundColor: previewPaneState.openAppDetails ? "#BDE3FF" : "" }}
          onClick={() => clickAppDetail()}
        >
          App Details
        </div>
        <div
          onMouseEnter={hoverSettingBar.bind(null, true)}
          onMouseLeave={hoverSettingBar.bind(null, false)}
          className={style.settingBar}
        >
          <Tooltip
            mouseLeaveDelay={0}
            getPopupContainer={e => e}
            overlayClassName={style.popover}
            title="App Design"
            transitionName={""} // 取消显示和消失动画
          >
            <span
              className={cls(style.settingIcon, { [style.selected]: isShowSettingDesign })}
              onClick={() => clickSettingDesign()}
            >
              <SettingDesignIcon isActive={isShowSettingDesign} />
            </span>
          </Tooltip>
          <Tooltip
            mouseLeaveDelay={0}
            getPopupContainer={e => e}
            overlayClassName={classNames(
              previewPaneState.settingPageStatus ? fpcss.advancedFeaturesTop : fpcss.advancedFeaturesTopRight,
              previewPaneState.settingPageStatus ? style.popover : style.featuresPopover
            )}
            title={"Advanced Features"}
            placement={previewPaneState.settingPageStatus ? "top" : "topRight"}
            transitionName={""} // 取消显示和消失动画
          >
            <span
              className={cls(style.settingIcon, { [style.selected]: isShowSettingFeatures })}
              onClick={() => clickSettingFeatures()}
            >
              <SettingFeaturesIcon isActive={isShowSettingFeatures} />
            </span>
          </Tooltip>
        </div>
        {!chatOnboardingState.isOpenChat && (
          <div className={style.size} onClick={handleCollapse}>
            {previewState.previewCollapseState ? <FullScreen /> : <Collapse />}
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewHeaderViewV2;
