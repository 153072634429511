import { useSignal } from "@preact/signals-react";
import { Dropdown } from "antd";
import style from "./SortByStyle.module.scss";
import classNames from "classnames";
import fpcss from "@uiview/views/FotPopover.module.css";

export enum UIModel {
  web,
  mobile,
}

type SortByViewProps = {
  model: UIModel;
  onSelectSortBy: (value: SortType) => void;
};

export type SortType = {
  label: string;
  value: string;
};

export function SortByView(props: SortByViewProps): JSX.Element {
  const LE_key: SortType = { label: "Last edited", value: "-updated_at" };
  const DC_key: SortType = { label: "Date created", value: "-created_at" };

  const name = useSignal(LE_key);

  const showDrop = useSignal(false);

  function selectItem(value: SortType): void {
    name.value = value;
    showDrop.value = false;

    props.onSelectSortBy(value);
  }

  function dropOpenChange(value: boolean): void {
    showDrop.value = value;
  }

  const items = (
    <div className={props.model === UIModel.mobile ? style.drop_content_mobile : style.drop_content}>
      <div
        className={classNames(
          style.item,
          style.actionItem,
          name.value?.label === LE_key.label ? style.mobile_hItem : undefined
        )}
        onClick={() => {
          selectItem(LE_key);
        }}
      >
        <div className={style.itemleft}>
          {name.value?.label === LE_key.label && (
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.5 4L4.5 7L10.5 1"
                stroke="#0099FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
        {LE_key.label}
      </div>
      <div
        className={classNames(
          style.item,
          style.actionItem,
          name.value?.label === DC_key.label ? style.mobile_hItem : undefined
        )}
        onClick={() => {
          selectItem(DC_key);
        }}
      >
        <div className={style.itemleft}>
          {name.value?.label === DC_key.label && (
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.5 4L4.5 7L10.5 1"
                stroke="#0099FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
        {DC_key.label}
      </div>
    </div>
  );

  return (
    <Dropdown
      overlayClassName={fpcss.sortbyview}
      open={showDrop.value}
      onOpenChange={dropOpenChange}
      overlay={items}
      getPopupContainer={e => e}
    >
      <div
        className={classNames(
          fpcss.relative,
          props.model === UIModel.mobile ? style.content_mobile : style.content_web
        )}
      >
        {name.value.label}
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 9L10 13L6 9"
            stroke="#12192B"
            strokeOpacity="0.65"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </Dropdown>
  );
}
