import { UiFunctionService } from "@uikit/service/UiFunctionService";
import { UploadFile } from "@uiview/views/BluePrint/BringOwnApi/UploadFile";
import { Command } from "cmdk";
import { useMemo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import func from "@uikit/func";
import style from "./css/CustomDropdown.module.css";
import { groupTitleOptions } from "./nodeTypeComponents/nodeTypeDispose";
import { Spin } from "antd";
import { DropdownItem } from "./DropdownItem";
import { BlueprintCommandItem } from "@uiview/views/BluePrint/components/BlueprintCommandItem";
import { SearchBlueprintsToolPieces } from "@uiview/views/BluePrint/SearchBlueprintsToolPieces/SearchBlueprintsToolPieces";
import { UnsupportedBlueprintFlowModal } from "@uiview/views/BluePrint/UnsupportedBlueprintFlowModal/UnsupportedBlueprintFlowModal";
import { UnsupportedBlueprintFlow } from "@uiview/views/BluePrint/UnsupportedBlueprintFlow";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { useSignal } from "@preact/signals-react";
import {
  CreateAGenerativeFunction,
  CreateAGenerativeFunctionType,
} from "@uiview/views/BluePrint/CreateAGenerativeFunction";
import { BlueprintFunctionType } from "imagica-corekit/dist/base/storyV2/function/BlueprintFunction";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";

export function CustomDropdownV2(props) {
  const commandInputRef = useRef(null);
  const scrollRef = useRef();
  const homeStore = getIt(HomeStore);
  const featureTags = homeStore.state.featureTags;
  const [hasSuggested, setHasSuggested] = useState([]);
  const [funcGroup, setFuncGroup] = useState([]);
  const [blueprintDropDown, setBlueprintDropDown] = useState(null);
  const [disableShowBlueprintDetail, setDisableShowBlueprintDetail] = useState();
  const webConfigState = useStore(getIt(WebConfigStore)).value;
  const enableToolboxFeature = useSelector(state => state.fot.enableToolboxFeature);
  const showEdgeBluePrint = featureTags.showEdgeBluePrint && enableToolboxFeature;
  const currentBlueprintToolPiecesDetail = useSignal();

  const selectedBlueprints = useMemo(() => {
    const blueprints = funcGroup.find(func => func.title === "Blueprints")?.funcList ?? [];
    return blueprints.filter(blue => props.selectedBlueprintIds.includes(blue.id));
  }, [props.selectedBlueprintIds, funcGroup]);

  const functionGroupConfig = useMemo(() => {
    const fromConfig = webConfigState.functionGroupConfig;
    return fromConfig.length > 0 ? fromConfig : groupTitleOptions;
  }, [webConfigState.functionGroupConfig, groupTitleOptions]);

  const handleClickBlueprintToolPiecesDetail = value => {
    currentBlueprintToolPiecesDetail.value = value;
  };

  const handleClickUnsupportedBlueprintFlow = missing_apis => {
    setBlueprintDropDown(
      <UnsupportedBlueprintFlowModal
        onClose={handleHideBlueprintDropDown}
        missing_apis={missing_apis}
        onOk={handleHideBlueprintDropDown}
      />
    );
  };

  const handleHideBlueprintDropDown = () => {
    setBlueprintDropDown(null);
  };

  const handleClickExecutionFlowV2 = () => {
    props.clickToSelectValue({
      type: "bluePrint",
      label: props.aiDescription,
      blueJson: JSON.stringify({
        functionType: BlueprintFunctionType.BLUEPRINTS,
      }),
    });
  };

  const handleShowUploadFile = () => {
    setBlueprintDropDown(
      <UploadFile
        onClose={handleHideBlueprintDropDown}
        style={props.style}
        className={`${style["raycast"]} nowheel ${props.disableHover ? style["disableHover"] : ""}`}
      />
    );
  };

  const handleShowSearchBlueprintsToolPieces = () => {
    setBlueprintDropDown(
      <SearchBlueprintsToolPieces
        searchQuery={props.aiDescription}
        onClose={handleHideBlueprintDropDown}
        style={props.style}
        className={`${style["raycast"]} nowheel ${props.disableHover ? style["disableHover"] : ""}`}
      />
    );
  };

  const handleClick = e => {
    e.stopPropagation();
    setDisableShowBlueprintDetail(Date.now());
    currentBlueprintToolPiecesDetail.value = undefined;
  };

  const changeFuncGroup = () => {
    if (func.isEmpty(props.apiArr) && func.isEmpty(props.suggestedFunctions) && func.isEmpty(props.suggestedQueries)) {
      setHasSuggested(false);
      setFuncGroup([]);
      return;
    }

    if (scrollRef.current) {
      scrollRef.current.contentWrapperEl.scrollTop = 0;
    }

    // apiArr
    const result = UiFunctionService.transform(
      props.aiDescription.length > 0,
      props.apiArr,
      props.suggestedQueries,
      props.suggestedFunctions,
      props.sourceNodeDisplayType,
      props.subSourceNodeDisplayType,
      functionGroupConfig
    );
    setHasSuggested(result.hasSuggested);
    setFuncGroup(result.group);
  };

  const updateFuncGroup = () => {
    props.updateApiArr();
  };

  useEffect(() => {
    changeFuncGroup();
  }, [
    props.apiArr,
    props.suggestedFunctions,
    props.suggestedQueries,
    props.sourceNodeDisplayType,
    props.subSourceNodeDisplayType,
    props.aiDescription,
  ]);

  useEffect(() => {
    const onDocumentClick = e => {
      console.log(e);
      props.onHideDropdown(e);
    };

    const onContextMenu = e => {
      props.onHideDropdown(e);
    };

    const onKeydown = e => {
      if (e.code === "Escape") {
        props.onHideDropdown(e);
      }
    };

    document.addEventListener("click", onDocumentClick, false);
    document.addEventListener("contextmenu", onContextMenu, false);
    document.addEventListener("keydown", onKeydown, true);

    return () => {
      document.removeEventListener("click", onDocumentClick, false);
      document.removeEventListener("contextmenu", onContextMenu, false);
      document.removeEventListener("keydown", onKeydown, true);
    };
  }, []);

  useEffect(() => {
    if (props.aiDescription.includes("/")) {
      commandInputRef.current?.focus();
    }
  }, [props.aiDescription]);

  useEffect(() => {
    props.inputOnRef(commandInputRef.current);
  }, []);

  /**
   * 当edge的输入框聚焦，并且此时dropdown也显示，那么这个时候如果继续输入，serchfunDescription就会将在edge输入框的文本追加起来，并且此时应该聚焦
   * 避免影响到v2，所以单独谢了一个focus
   */
  useEffect(() => {
    if (props.aiDescription.includes("/") && commandInputRef.current !== document.activeElement) {
      commandInputRef.current?.focus();
    }
  }, [props.serchFunDescription]);

  return (
    <>
      {blueprintDropDown ? (
        blueprintDropDown
      ) : (
        <div
          style={props.style}
          className={`${style["raycast"]} nowheel ${props.disableHover ? style["disableHover"] : ""}`}
          onClick={handleClick}
          data-testid="CustomDropdown"
        >
          <Command shouldFilter={false} onKeyDown={props.onCommandKeyDown}>
            {props.suggestedLoading && props.aiDescription.length === 0 ? (
              <div className={style["commandbar-suggestions-loading"]}>
                <Spin />
              </div>
            ) : (
              <>
                {enableToolboxFeature === false && (
                  <div className={style["commandbar-head"]} data-testid="CommandBar-SearchInput-Wrap">
                    {/* <SearchOutlined className={style['searchInput-icon']} /> */}
                    <span className={style["searchInput-icon"]}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.75 9C2.75 5.54822 5.54822 2.75 9 2.75C12.4518 2.75 15.25 5.54822 15.25 9C15.25 12.4518 12.4518 15.25 9 15.25C5.54822 15.25 2.75 12.4518 2.75 9ZM9 1.25C4.71979 1.25 1.25 4.71979 1.25 9C1.25 13.2802 4.71979 16.75 9 16.75C10.87 16.75 12.5853 16.0877 13.9242 14.9848L17.4697 18.5303C17.7626 18.8232 18.2374 18.8232 18.5303 18.5303C18.8232 18.2374 18.8232 17.7626 18.5303 17.4697L14.9848 13.9242C16.0877 12.5853 16.75 10.87 16.75 9C16.75 4.71979 13.2802 1.25 9 1.25Z"
                          fill="#020C26"
                          fillOpacity="0.35"
                        />
                      </svg>
                    </span>
                    <Command.Input
                      className={style["searchInput"]}
                      data-testid="CommandBar-SearchInput"
                      ref={commandInputRef}
                      value={props.searchFunc}
                      onValueChange={props.handleValueChange}
                      placeholder="Search for functions"
                    />
                  </div>
                )}

                <div className={style["commandbar-content"]}>
                  {/* functions list */}
                  <SimpleBar className={style["commandScroll"]} ref={scrollRef} forceVisible={true} autoHide={false}>
                    {/* slected blueprint list */}
                    {showEdgeBluePrint && selectedBlueprints.length > 0 && (
                      <div className={style["selected-blueprint-list"]}>
                        <Command.List data-testid="CommandBar-SelectedList">
                          <Command.Group key={"Selected Blueprints"} heading={"Selected Blueprints"}>
                            {selectedBlueprints.map(blueprint => (
                              <DropdownItem
                                id={props.id}
                                onClickBlueprintToolPiecesDetail={handleClickBlueprintToolPiecesDetail}
                                currentBlueprintToolPiecesDetail={currentBlueprintToolPiecesDetail.value}
                                updateFuncGroup={updateFuncGroup}
                                func={blueprint}
                                key={blueprint.id}
                                className={style["commandbar-item"]}
                                onSelect={() => {}}
                                onSelectBlueprint={() => {}}
                              />
                            ))}
                          </Command.Group>
                        </Command.List>
                      </div>
                    )}
                    {/* blueprint panel */}
                    {showEdgeBluePrint && props.showGenerateFunction && !hasSuggested && (
                      <>
                        <CreateAGenerativeFunction
                          disableShowBlueprintDetail={disableShowBlueprintDetail}
                          id={props.id}
                          bloc={props.generativeFunctionBloc}
                          aiDescription={props.aiDescription}
                          onSelect={props.clickToSelectValue}
                          createAGenerativeFunctionType={CreateAGenerativeFunctionType.BLUEPRINT_BUILDER_V2}
                          onClickBlueprintToolPiecesDetail={handleClickBlueprintToolPiecesDetail}
                          currentBlueprintToolPiecesDetail={currentBlueprintToolPiecesDetail.value}
                        >
                          Create a generative function
                        </CreateAGenerativeFunction>
                        <CreateAGenerativeFunction
                          disableShowBlueprintDetail={disableShowBlueprintDetail}
                          id={props.id}
                          bloc={props.generativeFunctionBlocV2}
                          aiDescription={props.aiDescription}
                          onSelect={props.clickToSelectValue}
                          createAGenerativeFunctionType={CreateAGenerativeFunctionType.BLUEPRINT_BUILDER_JUST}
                          onClickBlueprintToolPiecesDetail={handleClickBlueprintToolPiecesDetail}
                          currentBlueprintToolPiecesDetail={currentBlueprintToolPiecesDetail.value}
                        >
                          Just create a generative function
                        </CreateAGenerativeFunction>
                        <BlueprintCommandItem disabled={!props.aiDescription} onClick={handleShowUploadFile}>
                          Add a new tool piece
                        </BlueprintCommandItem>
                        <Command.List data-testid="CommandBar-SearchList">
                          <Command.Group
                            key={"Internal Use (Not User Facing)"}
                            heading={"Internal Use (Not User Facing)"}
                          >
                            <BlueprintCommandItem
                              disabled={!props.aiDescription}
                              onClick={handleShowSearchBlueprintsToolPieces}
                            >
                              Domain Mapping Test
                            </BlueprintCommandItem>
                            {/*
                             We do not need it presently
                             <BlueprintCommandItem
                              disabled={!props.aiDescription}
                              onClick={() => handleClickExecutionFlowV2()}
                            >
                              Sample Execution Flow
                            </BlueprintCommandItem>
                             */}
                            <UnsupportedBlueprintFlow onClick={handleClickUnsupportedBlueprintFlow}>
                              Unsupported Blueprint Flow
                            </UnsupportedBlueprintFlow>
                          </Command.Group>
                        </Command.List>
                      </>
                    )}
                    {/* other function list */}
                    <Command.List data-testid="CommandBar-SearchList">
                      <Command.Empty>No results found</Command.Empty>
                      {!func.isEmpty(funcGroup)
                        ? funcGroup.map(group => {
                            return (
                              <Command.Group key={group.title} heading={group.title}>
                                {!func.isEmpty(group.funcList)
                                  ? group.funcList.map((func, index) => {
                                      if (group.isTopSuggestions) {
                                        return (
                                          <Command.Item
                                            key={group.isTopSuggestions ? func : func.value + index}
                                            className={style["commndbar-suggestions-item"]}
                                            style={func.hide === true ? { display: "none" } : undefined}
                                            onSelect={() => props.clickToHandlePromptGeneration(func)}
                                          >
                                            <div className={style["commandbar-item-inner"]}>
                                              <div className={style["commandbar-item-content"]}>
                                                <div className={style["commndbar-suggestions-item-label"]}>{func}</div>
                                              </div>
                                            </div>
                                          </Command.Item>
                                        );
                                      } else {
                                        return (
                                          <DropdownItem
                                            id={props.id}
                                            onClickBlueprintToolPiecesDetail={handleClickBlueprintToolPiecesDetail}
                                            currentBlueprintToolPiecesDetail={currentBlueprintToolPiecesDetail.value}
                                            updateFuncGroup={updateFuncGroup}
                                            func={func}
                                            key={group.isTopSuggestions ? func : func.value + index}
                                            className={style["commandbar-item"]}
                                            style={func.hide === true ? { display: "none" } : undefined}
                                            onSelect={() =>
                                              func.isCreate === true
                                                ? props.clickCreateFunction(func)
                                                : props.clickToSelectValue(func)
                                            }
                                            onSelectBlueprint={props.clickToSelectValue}
                                          />
                                        );
                                      }
                                    })
                                  : null}
                              </Command.Group>
                            );
                          })
                        : null}
                    </Command.List>
                  </SimpleBar>
                </div>
              </>
            )}
          </Command>
        </div>
      )}
    </>
  );
}
