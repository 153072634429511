import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { useCreation } from "ahooks";
import Lottie, { AnimationItem } from "lottie-web";
import styles from "./preview.module.scss";
import { AISaasPreview } from "../AISaas";
import { Signal } from "@preact/signals-react";
import { PreviewPaneBloc } from "./PreviewPaneBloc";
import { ThemeType } from "imagica-corekit/dist/cases/theme/appTheme/AiAppThemeData";
import { ChatThemeType } from "imagica-corekit/dist/cases/theme/chatTheme/ChatAppThemeData";
import { FontType } from "imagica-corekit/dist/cases/theme/fontTheme/FontData";
import { SettingPage } from "./Settings";
import { getIt } from "@uikit/getIt";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { previewStore } from "@uiview/store/PreviewStore";
import source from "./asstes/generate.json";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { matchMobile } from "@uikit/func";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";

interface Props {
  backPreview: () => void;
  inOrOutSetting: (bol: boolean) => void;
  isShowPublish: boolean; // publish disable时 隐藏返回按钮
  silderState: Signal<boolean>;
  previewContainer: React.RefObject<HTMLDivElement>;
  setSaasTheme: (theme: any) => void;
  showSetting: boolean;
}

const Preview: React.FC<Props> = props => {
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const featureTags = homeStore.state.featureTags;
  const { setSaasTheme } = props;
  const previewRef = useRef(null);
  const { backPreview, inOrOutSetting, silderState } = props;
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const homePluginState = useImagicaStore(getIt(HomePluginStore)).value;

  const { id, theme, settings } = saasUIData;
  const isChatApp = saasUIData?.v2App?.type === "chat_ai";

  const previewState = useImagicaStore(previewStore).value;

  const bloc = useCreation(
    () =>
      new PreviewPaneBloc({
        saasUIData,
        setSaasUIData: creatorSaasAppStore.setSaasUIData,
        themeContainer: props.previewContainer,
      }),
    []
  );

  const LoadingRef = useRef(null);
  const animationRef = useRef<AnimationItem>();

  useEffect(() => {
    if (LoadingRef.current) {
      animationRef.current = Lottie.loadAnimation({
        animationData: source,
        container: LoadingRef.current,
      });
    }
    return () => {
      animationRef.current?.destroy();
    };
  }, [LoadingRef]);

  useEffect(() => {
    if (featureTags.enablePublishV3) {
      if (!id) return; // 当saasUIData被赋值app数据后再设置主题
      if (isChatApp) {
        bloc.changeChatTheme(theme as ChatThemeType);
      } else {
        bloc.changeTheme(theme as ThemeType);
      }
    } else {
      setSaasTheme(theme);
    }
  }, [bloc, featureTags.enablePublishV3, isChatApp, setSaasTheme, theme, id]);

  useEffect(() => {
    if (featureTags.enablePublishV3) {
      if (!id) return; // 当saasUIData被赋值app数据后再设置字体
      const fontFamily = settings?.fontFamily || FontType.AKREGULAR;
      bloc.changeFont(fontFamily);
    }
  }, [bloc, featureTags.enablePublishV3, settings?.fontFamily, id]);

  return (
    <div
      ref={previewRef}
      className={classNames(styles.previewPage, {
        [styles.newPreviewPage]: props.showSetting,
        [styles.previewPageMobile]: isMobile && featureTags.disableMobilePage,
      })}
    >
      <AISaasPreview silderState={silderState} backPreview={backPreview} inOrOutSetting={inOrOutSetting} />
      {/* v3 setting */}
      {/* FIXME: 分享页不应该显示 previewpanel，当该问题解决后可以去掉 isshare 的判断 */}
      {homePluginState.isShare ? null : <SettingPage bloc={bloc} />}
      <div className={previewState.loading ? styles.previewLoading : styles.previewLoadingHide}>
        <div ref={LoadingRef} className={styles.loading}></div>
      </div>
    </div>
  );
};

export default Preview;
