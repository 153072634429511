import { useMemo } from "react";
import { InputMain } from "./InputMain";
import { InputTitle } from "./InputTitle";
import { InputDelete } from "./InputAction/InputDelete";
import { InputEnter } from "./InputAction/InputEnter";
import { useAiSaasInputContainer } from "./container";
import css from "./index.module.scss";
import isBlank from "@sedan-utils/is-blank";
import { ReactFlowNodeUtil } from "@uikit/util/ReactFlowNodeUtil";
import { useSelector } from "react-redux";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";

const creatorSaasAppStore = getIt(CreatorSaasAppStore);
function InputInner(props: AISaas.AISaasInputItemInnerProps): JSX.Element {
  const { x, index } = props;
  const { id: itemId } = x.data;
  const { isFocus, isDarkMode, editPlaceholderData, onMouseLeaveBox, onMouseOverBox } = useAiSaasInputContainer();
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const projectRunStore = getIt(ProjectRunStore);
  const homeStore = getIt(HomeStore);
  const featureTags = homeStore.state.featureTags;
  const projectRunStoreState = useStore(projectRunStore).value;
  const disabledInput = projectRunStoreState.runAllLoading || saasUIData.staticMode;

  const isShare = useSelector((state: any) => state.fot.isShare);

  const isUploadFileInputNode = useMemo(() => {
    if (isShare) return ReactFlowNodeUtil.isUploadNode(x.data);
    return featureTags.disableUploadTypesAsInputNode === false && ReactFlowNodeUtil.isUploadNode(x.data);
  }, [featureTags.disableUploadTypesAsInputNode, x, isShare]);

  const isEditPlaceholder = useMemo(() => {
    return !isBlank(editPlaceholderData.value) && editPlaceholderData.value.id === itemId;
  }, [editPlaceholderData, itemId]);

  return (
    <div
      className={css["transparent-storey"]}
      onMouseLeave={onMouseLeaveBox}
      onMouseOver={() => onMouseOverBox(itemId)}
    >
      <div
        className={`${css["input-box"]} ${isFocus.value[itemId] ? css["enlarge-input-box"] : ""} ${
          css["top-suction"]
        } ${isEditPlaceholder && css["convention-padding"]} ${isDarkMode ? "dark" : ""} ${
          isUploadFileInputNode ? css["upload-file-box"] : ""
        }`}
      >
        <InputMain
          x={x}
          index={index}
          isEditPlaceholder={isEditPlaceholder}
          isUploadFileInputNode={isUploadFileInputNode}
          disabledInput={disabledInput}
        />
        <InputEnter x={x} />
        <InputDelete x={x} isEditPlaceholder={isEditPlaceholder} />
      </div>
    </div>
  );
}

export function AISaasInputItem(props: AISaas.AISaasInputItemProps): JSX.Element {
  const { x, index } = props;
  const itemId = x.data.id;

  return (
    <div key={itemId} className={css["saas-input-content"]}>
      <InputTitle x={x.data} />
      <InputInner x={x} index={index} />
    </div>
  );
}
