import React from "react";
import { useGuideContainer } from "./useGuideContainer";
import { GuideModal } from "./GuideModal";

export type GuideContainerProps = {};

export const GuideContainer: React.FC<GuideContainerProps> = React.memo(props => {
  const { guideConfig } = useGuideContainer();

  // TODO: revisit featureTags.disableMultiRoundChatOnboarding
  return <>{guideConfig && <GuideModal {...guideConfig} />}</>;
});
