import cls from "classnames";
import { motion } from "framer-motion";
import { find, isEmpty, isNil } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "@uikit/service/amplitude";
import { editorActions } from "../../../store/editor";
import { AModal } from "../../../uiview/views/AModal/AModal";
import { ChatPanel } from "./ChatPanel";
import { CotInputBloc } from "./CotInputBloc";
import styles from "./CotInputView.module.scss";
import { InputBar } from "./InputBar";
import { transition } from "./motion/motionConfig";
import { useCurrentStatus } from "./useCurrentStatus";
import { studioActions } from "../../../store/studio";
import { useDidMount } from "imagica-uikit/dist/hooks/useDidMount";
import { getIt } from "@uikit/getIt";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
// import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { ChoosingFormat, chooseFormatStore } from "@uikit/store/ChooseFormatStore";
import { ChatBoxManager } from "@uiview/views/MultiRoundChatOnboarding/components/Chatbox/ChatBoxManager";
import { CotStore } from "@uikit/store/CotStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { AddNode } from "@uikit/service/AddNode";
import { CreatorCotService } from "@uikit/service/CreatorCotService";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";

const variants = {
  normal: { top: "30%" },
  creating: { top: "40%" },
  completeCreating: {
    top: "80%",
    right: "180px",
    transition: {
      delay: 0.2,
    },
  },
};

export const CotInputView = ({ bloc }: { bloc: CotInputBloc; clickClosePreview: () => void }): JSX.Element => {
  const chooseFormatState = useImagicaStore(chooseFormatStore).value;
  const creatorNodesStore = getIt(CreatorNodesStore);
  const cotStore = getIt(CotStore);
  const addNode = getIt(AddNode);
  const creatorCotService = getIt(CreatorCotService);
  const cotStoreState = useImagicaStore(cotStore).value;

  const dispatch = useDispatch();

  const selectedTemplate = useSelector((state: any) => state.studio.selectedTemplate);
  const setSelectedTemplate = (val: any): void => {
    dispatch(studioActions.setSelectedTemplate(val));
  };
  const setEnableCOTStartAgain = (val: string): void => {
    dispatch(editorActions.setEnableCOTStartAgain(val));
  };

  const enableCOTStartAgain = useSelector((state: any) => state.editor.enableCOTStartAgain);

  const homeStore = getIt(HomeStore);
  const featureTags = homeStore.state.featureTags;
  const { currentStatus } = useCurrentStatus(bloc);

  const [model, setModel] = useState<React.ReactNode>(null);
  const [errorAlert] = useState<React.ReactNode>(null);
  const [startMotionComplete, setStartMotionComplete] = useState(false);

  const chatBoxManager = ChatBoxManager.use();
  const matchGoldenUtterance = (useInput: string): string | undefined => {
    const options = bloc.defaultOperationList.value;
    return find(options, item => item.label === useInput.trim())?.value;
  };

  const handleThinkEnter = (): void => {
    const value = bloc.thinkValue.value;
    if (isEmpty(value)) return;
    // logEvent("enter_cot_input", bloc.thinkValue.value);

    // bloc.waitingThinking.value = true;
    // bloc.cotChatStatus.value = "loading";
    // let result: QueryBuilderResponse | undefined;
    // try {
    //   setErrorAlert(null);
    //   result = await bloc.queryBuilder();
    // } catch (error) {
    //   setErrorAlert(<ErrorAlert key={Date.now()} onClose={() => setErrorAlert(null)} />);
    //   console.log(error, "error");
    // } finally {
    //   bloc.cotChatStatus.value = "waiting";
    //   bloc.waitingThinking.value = false;
    // }

    // if (result) {
    //   if (result.complete !== true) {
    //     bloc.question.value = NEED_MORE_INFO;
    //     bloc.inputSwitching.value = true;
    //     bloc.operationList.value = result.suggestions || [];
    //     bloc.selectCursorRef.current?.focus();
    //   } else {
    //     await createTemplate(result?.description || "");
    //     logEvent("enter_cot_done");
    //   }
    // }
    if (isEmpty(creatorNodesStore.getNodes()) === false) {
      return setModel(
        <AModal
          title={""}
          subTitle={"Your new project will replace your existing one"}
          buttons={["Cancel", "Enter"]}
          open={true}
          loading={false}
          shouldExecuteOkOnEnter={true}
          onCancel={() => {
            setModel(null);
          }}
          onDiscard={() => {
            setModel(null);
          }}
          onOk={() => {
            setModel(null);
            createTemplate(value);
          }}
        />
      );
    }

    createTemplate(value);
  };

  const createTemplate = async (value: string): Promise<void> => {
    const goldenUtterance = matchGoldenUtterance(value);
    bloc.cotChatStatus.value = "loading";
    dispatch(editorActions.setProcessingCOT(true));
    getIt(HomePluginStore).setInitWithCot(true);
    bloc.creating.value = true;
    await creatorCotService.addThink(goldenUtterance ? { option: goldenUtterance as string } : value);
    // after template generation save COT user query to selectedTemplate
    const dataVersion = "v3";
    const updatedProject = {
      ...selectedTemplate,
      [dataVersion]: { ...selectedTemplate[dataVersion], autoImagica: { query: value } },
    };
    setSelectedTemplate(updatedProject);
    console.log("updatedProject:::", updatedProject);
    bloc.cotChatStatus.value = "waiting";
    bloc.completeCreating.value = true;
    logEvent("enter_cot_done", value);
  };

  const handleOperationClick = (content: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    bloc.thinkValue.value = content;
    bloc.operationClickInfo.value = { rect: (event.target as HTMLElement)?.getBoundingClientRect(), content };

    handleThinkEnter();
  };

  const handleMotionComplete = (): void => {
    if (currentStatus === "completeCreating") {
      cotStore.setShowCot(false);
      setTimeout(() => {
        setEnableCOTStartAgain(bloc.thinkValue.value);
        bloc.resetThink();
      });
    }
  };

  const handleStartMotionComplete = (): void => {
    setStartMotionComplete(true);
  };

  useDidMount(() => {
    bloc.selectCursor();
  });

  useEffect(() => {
    if (chooseFormatState.chooseFormat === ChoosingFormat.AUTOIMAGICA) {
      chooseFormatStore.setChooseFormat(null);
      chatBoxManager.onOpenChat();
    }
    // eslint-disable-next-line
  }, [chooseFormatState.chooseFormat]);

  const handleClose = (): void => {
    logEvent("close_cot");

    if (isEmpty(creatorNodesStore.getNodes())) {
      return setModel(
        <AModal
          title={"Closing Auto-Imagica"}
          subTitle={"Would you like to close Auto-Imagica and continue in Manual Mode?"}
          buttons={["Cancel", "Continue"]}
          open={true}
          loading={false}
          onCancel={() => {
            logEvent("click_close_cot_x");
            setModel(null);
          }}
          onDiscard={() => {
            logEvent("click_close_cot_cancel");
            setModel(null);
          }}
          onOk={e => {
            setModel(null);
            if (featureTags.disableTriggerManualOnboardingInCanvas) {
              handleClickCloseOKBtn(e);
            } else {
              cotStore.triggerGuideManual(() => {
                handleClickCloseOKBtn(e);
              });
            }
          }}
        />
      );
    }

    cotStore.setShowCot(false);
  };

  const handleClickCloseOKBtn = (e: any): void => {
    logEvent("click_close_cot_enter");
    cotStore.setShowCot(false);
    addNode.addNodeV2(e, { isEnableTooltipNode: true });
    cotStore.setOldCotAnimationState({
      creating: false,
      inputIds: [],
      currentInputId: "",
    });
  };

  useEffect(() => {
    if (cotStoreState.showCot === true) {
      bloc.resetThink();
      if (isNil(enableCOTStartAgain) === false) {
        bloc.cotReStart(enableCOTStartAgain);
      } else {
        logEvent("start_cot");
      }
    }
    // eslint-disable-next-line
  }, [cotStoreState.showCot, enableCOTStartAgain]);

  if (cotStoreState.showCot === false) {
    return <></>;
  }

  const fotBoxClassName = cls(styles.fotInputBox, startMotionComplete === false && styles.startAnimation);

  return (
    <>
      <div className={styles.retryMask} />
      <motion.div
        initial={variants.normal}
        variants={variants}
        animate={currentStatus}
        transition={transition}
        className={styles["fotInput-wrapper"]}
        onAnimationComplete={handleMotionComplete}
      >
        {model}
        <motion.div
          animate={{ width: ["10px", "10px", "560px"], marginTop: ["50px", "0px", "0px"] }}
          transition={{ duration: 1, times: [0, 0.25, 1] }}
          className={fotBoxClassName}
          onAnimationComplete={handleStartMotionComplete}
        >
          <div>
            <ChatPanel bloc={bloc} onClose={handleClose} startMotionComplete={startMotionComplete} />
            {startMotionComplete === true ? (
              <>
                <InputBar
                  errorAlert={errorAlert}
                  onPressEnter={handleThinkEnter}
                  onClick={handleOperationClick}
                  bloc={bloc}
                />
              </>
            ) : null}
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};
