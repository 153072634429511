import { Transform, Viewport, XYPosition } from "reactflow";
import { FocusConsts } from "./FocusConsts";
import { PositionUtil } from "@uikit/util/FocusNodeUtil";
import { isEqual } from "lodash";
import { FocusOptions } from "./FocusOptions";

export type MoveToOptions = Partial<FocusOptions>;
export type MoveToStatusType = "skip" | "finished";
export type MoveToHandlerResult = {
  type: MoveToStatusType;
  options: ComposeResult;
};
export type MoveToHandler = (position: XYPosition, options?: MoveToOptions) => Promise<MoveToHandlerResult>;

type ComposeResult = MoveToOptions & {
  /**
   * 真实移动的坐标（计算后）
   */
  position: XYPosition;

  /**
   * react flow 视口状态
   */
  viewport: Viewport;

  transform: Transform;

  /**
   * 上次移动的整个窗口大小
   */
  container: { width: number; height: number };
};

/**
 * 用来实现调用 fitBounds,setCenter 等动画回调
 */
export class FocusController {
  prevOptions?: ComposeResult;

  /**
   * moveTo 方法移动状态
   */
  private moveToStatus: "stop" | "moveing" = "stop";
  private autoFitToStatus: "stop" | "moveing" = "stop";

  changeMoveToStatus(status: "stop" | "moveing"): void {
    this.moveToStatus = status;
  }
  changeAutoFitToStatus(status: "stop" | "moveing"): void {
    this.autoFitToStatus = status;
  }

  isMoveToMoving(): boolean {
    return this.moveToStatus === "moveing";
  }
  isAutoFitToMoving(): boolean {
    return this.autoFitToStatus === "moveing";
  }

  /**
   * 组合可对比参数
   * @param options
   * @returns
   */
  compose(options: ComposeResult): ComposeResult {
    const offsetPercent = options.offsetPercent;
    let position = options.position;

    // 计算百分比偏移
    position = PositionUtil.offsetPositionPercent({ position, offset: offsetPercent });
    // TODO: 将不是使用 flowmanager focus 的 setcenter或fitbounds 去掉后,需要将该逻辑移动到 flowcore 中
    let zoom = options.zoom ?? FocusConsts.FLOW_ZOOM;
    if (zoom < 1) {
      zoom = FocusConsts.FLOW_ZOOM;
    }
    return {
      duration: FocusConsts.FLOW_DURTAOIN,
      ...options,
      zoom: zoom,
      position,
    };
  }

  /**
   * 用来判断是否重复执行动画
   *
   * 根据上一次的对象所有值和现在的做一个对比(loadah.isEqual)
   *
   * @param options1
   * @param options2
   * @returns
   */
  isSkip(target: ComposeResult): boolean {
    return isEqual(target, this.prevOptions);
  }

  skip(options: ComposeResult): Promise<MoveToHandlerResult> {
    this.changeMoveToStatus("stop");
    this.changeAutoFitToStatus("stop");
    return Promise.resolve({ type: "skip", options } as MoveToHandlerResult);
  }

  finish(options: ComposeResult): Promise<MoveToHandlerResult> {
    this.prevOptions = options;

    return new Promise<MoveToHandlerResult>(resolve => {
      setTimeout(() => {
        this.changeMoveToStatus("stop");
        this.changeAutoFitToStatus("stop");
        resolve({ type: "finished", options: options });
      }, options.duration);
    });
  }

  resetPrevOptions(): void {
    this.prevOptions = undefined;
  }
}
