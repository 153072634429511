import { MoveToHandlerResult } from "@uikit/cases/canvasFocus/FocusController";
import { CustomNewEdgeArgsContainer } from "./CustomNewEdgeContainer";
import { CustomNewEdgeStore } from "./CustomNewEdgeStore";
import { FocusConsts } from "@uikit/cases/canvasFocus/FocusConsts";

const defaultConfig = {
  /**
   * 用于测试
   *
   * 打开command bar 模式
   * 1. 先打开输入框，完成动画后，在打开下拉框(原来逻辑)
   * 2. 完成动画后，一次性打开输入框和下拉框
   * @type {1 | 2}
   */
  openCommandBarMode: 2,

  /**
   * 用于测试
   *
   * 打开command bar 模式
   * 1. 开启动画就改变(原来逻辑)
   * 2. 完成动画后再改变
   * @type {1 | 2}
   */
  diableAllowZoomOrPanMode: 1,

  /**
   * 打开 commandbar 时动画持续时间
   */
  openCommandDuration: FocusConsts.FLOW_DURTAOIN,
};

/**
 * 专门用来处理 commandbar 显影的 class
 */
export class EdgeCommandbar {
  /**
   * 该属性用于控制edge 动画开关
   */
  moving = false;

  constructor(private store: CustomNewEdgeStore, private container: CustomNewEdgeArgsContainer) {}

  initDisplayType(isGetQuery: boolean, getQueryLoading: boolean): void {
    if (!isGetQuery) {
      if (!getQueryLoading) {
        this.changeCommandbarState({ edgeDisplayType: "input" });
      } else {
        this.changeCommandbarState({ edgeDisplayType: "generating" });
      }
    } else {
      this.changeCommandbarState({ edgeDisplayType: "run" });
    }
  }

  /**
   * TODO: 该方法后期 可能会用来线性更改 showCommandbar,edgeDisplayType 时做一些额外操作
   * @param params
   */
  changeCommandbarState(params: { showCommandbar?: boolean; edgeDisplayType?: CustomEdge.EdgeType }): void {
    this.store.emit(this.store.state.copyWith(params));
  }

  private openCommandbarBefore(): boolean {
    const { diableAllowZoomOrPanMode, openCommandBarMode } = defaultConfig;

    if (this.moving) {
      return false;
    }

    this.moving = true;

    // 禁用画板移动或缩放(动画开始时)
    if (diableAllowZoomOrPanMode === 1) {
      this.container.homeToolBarStore.setAllowZoomOrPan(false);
    }

    // 原来的逻辑, 先变成输入框
    if (openCommandBarMode === 1 && !this.store.isDisplayInput()) {
      this.changeCommandbarState({ edgeDisplayType: "input" });
    }

    return this.moving;
  }

  private openCommandbarAfter(): void {
    const { diableAllowZoomOrPanMode, openCommandBarMode } = defaultConfig;

    if (openCommandBarMode === 2) {
      // 本想是在动画完成后变成输入框再显示 commandbar，但是现在两个状态耦合高，代码先暂时保留
      this.changeCommandbarState({ showCommandbar: true });
    }
    // 原来逻辑,先变成输入框后,等待动画完成在显示commandbar
    else {
      this.changeCommandbarState({ showCommandbar: true });
    }

    // 禁用画板移动或缩放(动画结束后)
    if (diableAllowZoomOrPanMode === 2) {
      this.container.homeToolBarStore.setAllowZoomOrPan(false);
    }
  }

  /**
   * 打开 commandbar
   *
   * 该目前只控制 showCommandbar 状态, 没有也不应该和 edgeType 状态耦合,
   * 但是 showCommanbar 的前提是, edgetype 需要为 input
   *
   * 所以内部加上了防止重复调用的逻辑,原来调用代码没有删除
   * @returns
   */
  openCommandbar(): Promise<MoveToHandlerResult> | undefined {
    // 为了防止重复调用该方法
    if (this.store.hasShowCommandbar()) {
      return;
    }
    if (!this.openCommandbarBefore()) {
      return;
    }

    return this.container.focusNode
      .focusEdgeByIdV2(this.store.edgeProps.id, { duration: defaultConfig.openCommandDuration })
      .then(result => {
        this.openCommandbarAfter();
        return result;
      })
      .finally(() => {
        this.moving = false;
      });
  }

  /**
   * 关闭 commandbar
   * @param targetDom
   */
  closeCommandbar(targetDom?: unknown): void {
    if (!this.store.edgeProps.data.isUndoRedoAdd) {
      // const edgeInput = get(aiDescriptionInputRef.current, 'resizableTextArea.textArea')
      const edgeInput = this.store.refs.aiDescriptionInputRef.current;

      // 聚焦边的输入框
      if (edgeInput && edgeInput === targetDom) {
        edgeInput.focus();
      }
    }

    // 当关闭 commandbar 时， edgetype 需要回到初始状态，run
    // 但是现在逻辑后通过 initDisplayType 再 useEffect 中再次改变
    this.changeCommandbarState({ showCommandbar: false });

    // 允许画板移动或缩放(动画结束后)
    this.container.homeToolBarStore.setAllowZoomOrPan(true);

    this.moving = false;
  }
}
