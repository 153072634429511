import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Popconfirm, Skeleton } from "antd";
import { fotActions } from "../store/fot";
import plus from "../media/plus.png";
import Thought from "./components/Thought";
import TaskList from "./components/TaskList";
import Outfit from "./components/Outfit";
import Food from "./components/Food";
import Implication from "./components/Implication";
import Video from "./components/Video";
import Google from "./components/Google";
import Shopping from "./components/Shopping";
import Travel from "./components/Travel";
import Ingredient from "./components/Ingredient";
import Nutrition from "./components/Nutrition";
import CookingStep from "./components/CookingStep";
import Image from "./components/Image";
import Flight from "./components/Flight";
import PitchDesk from "./components/PitchDesk";
import JobDescription from "./components/JobDescription";
import Jira from "./components/Jira";
import "../views-css/CardHome.css";
import "./components-css/DefaultType.css";
import "./components-css/Angle.css";
import "./components-css/StudioUI.css";
import func from "@uikit/func";
import Setting from "./thinking-layout-editor/Setting";
import { displayTypeArray } from "../uiview/views/Nodes/nodeConfig";
import { Nodes } from "../uiview/views/Nodes/Nodes";
import { FoodNodeWithBloc } from "../uiview/views/foodNode/FoodNode";
import { MapView } from "../uiview/views/map-node/MapView";
import { MapViewBloc } from "../uiview/views/map-node/MapViewBloc";
import mapboxgl from "mapbox-gl";
import { useRenderData } from "../uiview/views/AISaas/hooks/useRenderData";
import { isBlank } from "imagica-corekit/dist/base/cutil/Extensions";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { Blank } from "imagica-uikit/dist/nodesV2/Blank/Blank";
import { ViewBuilderCpWrapper } from "imagica-uikit/dist/nodesV2/ViewBuilderComponents/ViewBuilderCpWrapper";
import { ViewBuilderWorker } from "imagica-uikit/dist/nodesV2/ViewBuilderComponents/ViewBuilderWorker";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppValueLangUtil } from "@uiview/views/PreviewApp/PreviewAppValueLangUtil";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { Code } from "@uiview/views/Nodes/Code/Code";

function CardHome(props) {
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const homeStore = getIt(HomeStore);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const featureTags = homeStore.state.featureTags;

  const outputRef = useRef(null);
  const dispatch = useDispatch();

  const plainTxtPages = ["home"];

  const mode = useSelector(state => state.fot.mode);

  const [isOpenDeleteBox] = useState(false);
  const [isMoveHighlight] = useState("");

  const editorImageScrollDireaction = useSelector(state => state.fot.editorImageScrollDireaction);
  const setImageScrollDireaction = val => {
    dispatch(fotActions.setImageScrollDireaction(val));
  };

  const isShare = useSelector(state => state.fot.isShare);
  const isStaticMode = useSelector(state => state.editor.isStaticMode);

  const bloc = new MapViewBloc(mapboxgl, props.card.previewId, props.card.content);

  const marginLeft = isShare ? 25 : 0;
  const marginStyle = isShare ? `20px ${marginLeft}px` : "20px 0";
  const shareClass = isShare ? "share" : "";

  const confirmDeleteBox = () => {
    let arr = [];
    // bsf-4912 replace thoughts
    const tempThoughts = creatorSaasAppStore.state.saasUIData.output;
    // 删除单个
    if (func.isEmpty(props.card.parentNode)) {
      tempThoughts.forEach(x => {
        const noDelArr = x.results.filter(r => r.previewId !== props.card.previewId);
        if (!func.isEmpty(noDelArr)) {
          arr.push({
            ...x,
            results: noDelArr,
          });
        }
      });
    } else {
      // 删除group
      if (editorImageScrollDireaction !== "horizontalScroll") {
        arr = tempThoughts.filter(x => x.groupId !== props.card.parentNode);
      } else {
        const results = tempThoughts[0].results;
        const filterResults = results.filter(x => x.parentNode !== props.card.parentNode);
        if (func.isEmpty(filterResults)) {
          setImageScrollDireaction("verticallyScroll");
        } else {
          arr = [
            {
              ...tempThoughts[0],
              results: filterResults,
            },
          ];
        }
      }
    }
    // bsf-4912 use setSassUIData
    creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: arr }));
  };
  const isImageType = card => {
    return card.type === "image" || (card.type === "customApiFunction" && card.content?.data?.type === "image");
  };
  const isDisplaySetting = () => {
    // map不显示setting
    if (props.card.type === "map") return false;
    // 单个node显示三个点图标
    if (func.isEmpty(props.card.parentNode)) return true;
    // group第一个元素显示三个点图标
    // bsf-4912 replace thoughts
    const tempThoughts = creatorSaasAppStore.state.saasUIData.output;
    if (editorImageScrollDireaction === "horizontalScroll" && isImageType(props.card)) {
      const currGroupResults = tempThoughts[0].results.filter(x => x.parentNode === props.card.parentNode);
      const idx = currGroupResults.findIndex(x => x.previewId === props.card.previewId);
      return idx === 0;
    }
    const currGroupThoughts = tempThoughts.filter(x => x.groupId === props.card.parentNode);
    const idx = currGroupThoughts.findIndex(x => x.results[0].previewId === props.card.previewId);
    return idx === 0;
  };
  const isDisplayDel = () => {
    // 单个node显示删除图标
    if (func.isEmpty(props.card.parentNode)) return true;
    // group最后一个元素显示删除图标
    // bsf-4912 replace thoughts
    const tempThoughts = creatorSaasAppStore.state.saasUIData.output;
    if (editorImageScrollDireaction === "horizontalScroll" && isImageType(props.card)) {
      const currGroupResults = tempThoughts[0].results.filter(x => x.parentNode === props.card.parentNode);
      const idx = currGroupResults.findIndex(x => x.previewId === props.card.previewId);
      return idx === currGroupResults.length - 1;
    }
    const currGroupThoughts = tempThoughts.filter(x => x.groupId === props.card.parentNode);
    const idx = currGroupThoughts.findIndex(x => x.results[0].previewId === props.card.previewId);
    return idx === currGroupThoughts.length - 1;
  };
  const menu = <Setting card={props.card} isImageType={isImageType} />;

  const renderData = useRenderData({ type: "output", previewId: props.card.previewId });
  const errorStyle = func.isEmpty(props.card.errorText) ? {} : { color: "#CC295F" };
  const { type } = props.card;

  const nodeData = type === "textbox" ? creatorNodesStore.getNodes().find(x => x.id === props.card.nodeId) : null;

  const [showBuilderUI, setShowBuilderUI] = useState(false);

  if (isBlank(props.card.content)) {
    return <Blank content={props.card?.blank} />;
  }
  const showViewBuilder = ViewBuilderWorker.isShowBuilderUI(nodeData?.data?.showBuilderUI ?? false);
  return (
    <article ref={outputRef} className="CardHome">
      {!props.currentPageLoadingState ? (
        displayTypeArray.includes(type) ? (
          <Nodes
            style={renderData.style}
            id={props.card.previewId}
            nodeId={props.card.nodeId}
            isInCanvas={false}
            isShare={props.isShare}
            displayType={props.card.type}
            content={props.card.content}
            textAreaValue={props.card.content}
            canvasType={"saas"}
            isStaticApp={PreviewAppValueLangUtil.isStaticApp(creatorSaasAppStore.state.saasUIData)}
            featureTags={featureTags}
            editorId={props.editorId || "fotId"}
            locus={props.locus}
            fontSize={props.card.fontSize}
            handleImageLoaded={props.handleImageLoaded}
          />
        ) : props.card.type == "thought" ? (
          <Thought content={props.card.content} />
        ) : // : props.card.type == "google" ?
        props.card.type === "map" ? (
          featureTags.showMap ? (
            <div style={{ margin: marginStyle }}>
              <MapView
                id={props.card.previewId}
                displayLabel={true}
                isOpenDeleteBox={isOpenDeleteBox}
                textAreaValue={props.card.content}
                bloc={bloc}
              ></MapView>
            </div>
          ) : null
        ) : props.card.type == "pitch_deck" ? (
          <PitchDesk content={props.card.content.pitch_deck}></PitchDesk>
        ) : props.card.type == "video" ? (
          <Video src={props.card}></Video>
        ) : props.card.type == "showFoodInterface" ? (
          <div style={{ margin: marginStyle }}>
            <FoodNodeWithBloc content={props.card.content} />
          </div>
        ) : props.card.type == "image" ? (
          <Image
            isCaptionToggle={renderData?.isCaptionToggle}
            content={props.card.content}
            className={props.className}
            handleImageLoaded={props.handleImageLoaded}
          ></Image>
        ) : props.card.type == "shopping" ? (
          <Shopping
            image={props.card.content.image}
            title={props.card.content.title}
            price={props.card.content.price}
          ></Shopping>
        ) : props.card.type == "info" ? (
          <section className={props.card.content.platform == "" ? "CardHomeInfo CardHomeInfo1col" : "CardHomeInfo"}>
            <p>{props.card.content.title}</p>
            <h4 className={props.card.content.platform == "" ? "Hidden" : ""}>{props.card.content.platform}</h4>
          </section>
        ) : props.card.type == "travel" ? (
          <Travel city={props.card.content.city} time={props.card.content.time} reason={props.card.content.reason} />
        ) : props.card.type === "jira" ? (
          <Jira content={props.card.content} />
        ) : props.card.type == "task" ? (
          <section className="CardHomeTask">
            <p>{props.card.content}</p>
            <img src={plus} />
          </section>
        ) : props.card.type == "list" ? (
          <TaskList id={props.id} content={props.card.content} len={props.len}></TaskList>
        ) : props.card.type == "flight" ? (
          <Flight flight={props.card.content} id={props.id} />
        ) : props.card.type === "outfit" ? (
          <Outfit image={props.card.content.image} name={props.card.content.name} price={props.card.content.price}>
            {props}
          </Outfit>
        ) : props.card.type == "implication" ? (
          <Implication>{props.card.content}</Implication>
        ) : props.card.type == "google" ? (
          <Google link={props.card.content.link} text={props.card.content.text}></Google>
        ) : props.card.type == "document" ? (
          <section className="CardHomeDocument">
            <p className="documentTitle">{props.card.content.title}</p>
            <p className="documentBody">{props.card.content.body}</p>
          </section>
        ) : props.card.type == "food" ? (
          featureTags.enableFood ? (
            <Food
              image={props.card.content.image}
              dish={props.card.content.dish}
              restaurant={props.card.content.restaurant}
            ></Food>
          ) : null
        ) : props.card.type === "textbox" ? (
          props.card.content || props.card.errorText ? (
            <section
              style={{
                background:
                  renderData?.style?.background === "transparent"
                    ? renderData?.style?.background
                    : "var(--nodeWrapperBgColor, 'rgba(255,255,255,.1)')",
                width: `calc(100% - ${marginLeft * 2}px)`,
                padding: showViewBuilder ? "5px" : "20px",
              }}
              className={`CardHomeTextbox ${shareClass} ${props.card.layout}`}
            >
              {/* 新增viewbuilder ui显示 后续和text合并 */}
              {!showViewBuilder && (
                <pre
                  style={{ ...renderData.style, background: "transprent", ...errorStyle }}
                  className={!props.isShare ? `${props.card.fontSize}` : "text-reset-padding"}
                >
                  {!func.isEmpty(props.card.errorText) ? props.card.errorText : props.card.content}
                </pre>
              )}
              <ViewBuilderCpWrapper
                showBuilderUI={showViewBuilder}
                isResized={false}
                isInCanvas={false}
                initialReportSize={{ width: "unset", height: "unset" }}
                resizeEventParams={{ width: "unset", height: "unset" }}
                viewBuilderData={props.card?.viewBuilderData}
                clickViewBuilderBtn={() => {
                  // 重新渲染cardhome
                  setShowBuilderUI(!showBuilderUI);
                  creatorNodesStore.setNodes(l => {
                    return l.map(item => {
                      if (item.id === nodeData.id) {
                        return { ...item, data: { ...item.data, showBuilderUI: !nodeData?.data?.showBuilderUI } };
                      }
                      return item;
                    });
                  });
                }}
              />
            </section>
          ) : null
        ) : props.card.type == "ingredient" ? (
          <Ingredient id={props.id} content={props.card.content}></Ingredient>
        ) : props.card.type == "nutrient" ? (
          <Nutrition id={props.id} content={props.card.content}></Nutrition>
        ) : props.card.type == "cooking-step" ? (
          <CookingStep id={props.id} len={props.len} content={props.card.content}></CookingStep>
        ) : props.card.type == "job_description" ? (
          <JobDescription content={props.card.content.job_description} />
        ) : props.card.type == "code" ? (
          <Code
            content={props.card.content.code}
            fontSize={props.card.fontSize}
            handleImageLoaded={props.handleImageLoaded}
          />
        ) : (
          <section className="CardHomeThought">
            <p>{props.card.content}</p>
          </section>
        )
      ) : // 这里是数据loading显示
      plainTxtPages.includes(props.page) ? (
        <Skeleton className="Skeleton-Custom" loading={true} paragraph={false} active round>
          <section className="CardHomeThought">
            <p>{props.card.content}</p>
          </section>
        </Skeleton>
      ) : (
        <section className="CardHomeThought">
          <p>{props.card.content}</p>
        </section>
      )}

      {props.zone !== "editorCustomNode" &&
      mode === "editorMode" &&
      isMoveHighlight === props.card.nodeId &&
      !isShare &&
      isOpenDeleteBox &&
      !isStaticMode ? (
        <>
          {isDisplaySetting() ? (
            <Dropdown overlay={menu} trigger={["click"]}>
              <div className="more-box" onClick={e => e.preventDefault()}>
                <span className="iconfont icon-gengduo"></span>
              </div>
            </Dropdown>
          ) : null}
          {isDisplayDel() ? (
            <div className="delete-box">
              <Popconfirm
                overlayStyle={{ fontFamily: "Ak-Regular" }}
                title="Delete editor?"
                onConfirm={confirmDeleteBox}
                okText="Yes"
                cancelText="No"
              >
                <span className="iconfont icon-shanchu"></span>
              </Popconfirm>
            </div>
          ) : null}
        </>
      ) : null}
    </article>
  );
}

export default CardHome;
