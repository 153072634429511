import { get, omit } from "lodash";
import { AISaasInput } from "../AISaasInput";
import { useAISaasContainer } from "../container";
import { AiSaasInputProvider, useAiSaasInputContainer } from "../AISaasInput/container";
import { AISaasTitle } from "./AISaasTitle";
import LoadingOutline from "@views/components/LoadingOutline";
import css from "./index.module.scss";
import AddToContent from "../AddToContent";
import { WITHOUT_INPUT_ID } from "../AddToContent/conts";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { getIt } from "@uikit/getIt";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { ChoosingFormat } from "@uikit/store/ChooseFormatStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { FeatureTags } from "imagica-corekit/dist/base/domain/project/FeatureTags";

/**
 * Preview
 */
function AiSaasHeaderInner(): JSX.Element {
  const { props } = useAISaasContainer();
  const { clickSubmit, disableButton, sortableList } = useAiSaasInputContainer();
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const projectRunStore = getIt(ProjectRunStore);
  const homeStore = getIt(HomeStore);
  const featureTags: FeatureTags = homeStore.state.featureTags;
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const projectRunStoreState = useImagicaStore(projectRunStore).value;

  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const chatOnboardingState = useImagicaStore(chatOnboardingStore).value;

  const showSubmit =
    sortableList.length > 0 || get(sortableList, ["0", "data", "data", "displayType"]) === "uploadFile";
  return (
    <div className={"AiSaasHeader"} id={"AiSaasHeader"} data-testid="AiSaasHeader">
      <div className={css["AiSaasHeader-title"]}>
        {/* Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef() */}
        <AISaasTitle {...omit(props, "ref")} />
      </div>

      <div className={css["AiSaasInput-wrap"]}>
        <AISaasInput />
      </div>
      {!saasUIData.staticMode && chatOnboardingState.selectTemplateInModal !== ChoosingFormat.AUTOIMAGICA && (
        <AddToContent id={WITHOUT_INPUT_ID} className={css.inputAddToContent} type="input" />
      )}

      {showSubmit && featureTags.disableViewBuilderToolBoxIntegration !== false ? (
        <div className={`${css["submit-button"]} ${disableButton() && css["cannot-select"]}`} onClick={clickSubmit}>
          {projectRunStoreState.runAllLoading ? (
            <div className={css["saas-loading"]}>
              <LoadingOutline />
            </div>
          ) : (
            "Submit"
          )}
        </div>
      ) : null}
    </div>
  );
}

export function AISaasHeader(): JSX.Element {
  return (
    <AiSaasInputProvider>
      <AiSaasHeaderInner />
    </AiSaasInputProvider>
  );
}
