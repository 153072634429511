import React, { useEffect, useRef } from "react";
import { Input } from "antd";
import { useNodesTitle } from "@custom-hooks/HomeUsePluginRoot/useNodesTitle";
import style from "../index.module.scss";
import func from "@uikit/func";
import { TextAreaRef } from "antd/lib/input/TextArea";
import { useRenderData } from "../../hooks/useRenderData";
import { useSignal } from "@preact/signals-react";
import { useAiSaasInputContainer } from "@uiview/views/AISaas/AISaasInput/container";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { isEqual } from "lodash";
import { logEvent } from "@uikit/service/amplitude";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";

type PreviewTitleProps = {
  id: string;
  title: string;
  isShare: boolean;
  showInputTitleId: string;
  saasUIInputData: any;
  inputTitleId: string;
  onSaasInputTitleChange: (e: any, id: string) => void;
  onInputTitleEnter: (e: any, id: string) => void;
  onInputTitleBlur: (id: string) => void;
  onClickEdit: (e: any, data: any, tips: string | string[]) => void;
};

export function PreviewTitle({
  isShare,
  saasUIInputData,
  inputTitleId,
  onInputTitleBlur,
  onInputTitleEnter,
  onSaasInputTitleChange,
  onClickEdit,
}: PreviewTitleProps): React.JSX.Element {
  const isHover = useSignal(false);
  const inputTitleRef = useRef(null);
  const projectNodeStore = getIt(ProjectNodeStore);
  const { handleBlur, handleChange, handleFocus, saveTitle, inputTitle } = useNodesTitle(saasUIInputData.id);

  const renderData = useRenderData({ type: "inputLabel", id: saasUIInputData.id });

  const { selectTips } = useAiSaasInputContainer();
  const inputRef = useRef<TextAreaRef>(null);
  const homeStore = getIt(HomeStore);
  const featureTags = homeStore.state.featureTags;
  // 开始编辑时的title，用于编辑结束时对比title是否变化(暂用于Amplitude判断是否修改了title),下同
  const startEditingTitle = useSignal("");

  const clickEdit = (tips: string | string[]): void => {
    onClickEdit(null, saasUIInputData, tips);
    // onPreviewItemMouseEnter(getMouseEnterParams());
  };

  const hideAndDisplay = (key: boolean): string => {
    return key ? style["display-box"] : style["hide-box"];
  };

  const isEditing = saasUIInputData.id === inputTitleId;
  useEffect(() => {
    if (selectTips.value.id === saasUIInputData.id && selectTips.value.type === "inputNodeLabel") {
      // onPreviewItemMouseEnter(getMouseEnterParams());
    }
    // eslint-disable-next-line
  }, [selectTips.value]);

  useEffect(() => {
    //  这里存在一个input框渲染先后问题，当isEditing为ture，input框渲染后，再去聚焦
    if (isEditing && !func.isEmpty(inputRef.current)) {
      inputRef.current?.focus({
        cursor: "end",
      });
    }
  }, [isEditing]);

  useEffect(() => {
    const projectNode = projectNodeStore.getNode(saasUIInputData.id);
    // stud-2493 get input title(nodes)
    if (!projectNode?.generatedLabel && !projectNode?.inputTitle) {
      if (featureTags.disableViewBuilderToolBoxIntegration === true) {
        saveTitle("User Input");
      }
    }
    // eslint-disable-next-line
  }, []);

  const onFocus = (): void => {
    handleFocus();
    startEditingTitle.value = inputTitle;
  };

  const judgeThenLogEvent = (): void => {
    const old_text = startEditingTitle.value;
    const new_text = inputTitle;
    if (isEqual(old_text, new_text)) {
      return;
    }

    logEvent("click_edit_preview_text", { target: "input_label", new_text, old_text });
  };

  return (
    <div
      onClick={() => clickEdit("Edit text")}
      style={{ ...renderData?.style, flex: 1, width: "100%" }}
      ref={inputTitleRef}
      onMouseEnter={() => {
        isHover.value = true;
      }}
      onMouseLeave={() => {
        isHover.value = false;
      }}
    >
      {!isEditing && (
        <div style={renderData?.style} className={`${style["input-title-piece"]} ${hideAndDisplay(!isEditing)}`}>
          {inputTitle}
        </div>
      )}
      {!isShare && (
        <Input.TextArea
          autoFocus
          style={{ ...renderData?.style, display: isEditing ? "initial" : "none" }}
          value={inputTitle}
          ref={inputRef}
          autoSize={{ minRows: 1, maxRows: 10 }}
          className={`${style["input-title"]} ${hideAndDisplay(isEditing)} ${
            isEditing ? style["input-title-textArea-display-zIndex"] : null
          }`}
          bordered={false}
          onChange={e => {
            onSaasInputTitleChange(e, saasUIInputData.id);
            handleChange(e);
          }}
          onPressEnter={e => {
            onInputTitleEnter(e, saasUIInputData.id);
            inputRef.current?.blur();
          }}
          onFocus={() => onFocus()}
          onBlur={() => {
            onInputTitleBlur(saasUIInputData.id);
            handleBlur();
            judgeThenLogEvent();
          }}
        />
      )}
    </div>
  );
}
