import { useSignal } from "@preact/signals-react";
import func from "@uikit/func";
import { Api } from "@uikit/service/api";
import { PriceText } from "@uiview/views/ShareAccount/PriceText";
import { AccountStatus } from "@uiview/views/ShareAccount/AccountStatus";
import LoadingOutline from "@views/components/LoadingOutline";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { get, omit } from "lodash";
import { useEffect } from "react";
import { NavigateLogo } from "../../AISaasPreview/icons";
import { useAISaasContainer } from "../../container";
import { AISaasInput } from "../AISaasInput";
import { AiSaasInputProvider, useAiSaasInputContainer } from "../AISaasInput/container";
import { SaasHeadPc } from "./SaasHeadPc";
import css from "./index.module.scss";
import { PublishSubscriptionStore } from "imagica-corekit/dist/cases/store/PublishSubscriptionStore";
import { getIt } from "@uikit/getIt";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { FeatureTags } from "imagica-corekit/dist/base/domain/project/FeatureTags";

const creatorSaasAppStore = getIt(CreatorSaasAppStore);
function AiSaasHeaderInner(): JSX.Element {
  const projectRunStore = getIt(ProjectRunStore);
  const { props } = useAISaasContainer();
  const { btnText, clickSubmit, disableButton } = useAiSaasInputContainer();
  const { saasUIInputData = [] } = props;
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;

  const api = new Api(false);
  const uploadLoge = useSignal("");
  const projectRunStoreState = useImagicaStore(projectRunStore).value;

  const store = getIt(PublishSubscriptionStore);
  const state = useStore(store).value;
  const homePluginStore: HomePluginStore = getIt(HomePluginStore);

  const homeStore = getIt(HomeStore);
  const featureTags: FeatureTags = homeStore.state.featureTags;

  const isMobile = window.navigator.userAgent.includes("Mobile");

  const showSubmit = saasUIInputData.length > 0 || get(saasUIInputData, ["0", "data", "displayType"]) === "uploadFile";

  const initializationLogo = async (): Promise<void> => {
    uploadLoge.value = "";
    const url = saasUIData?.settings?.logoImg || "";
    if (saasUIData.id === previewStore.state.previewAppAcivieId && !func.isEmpty(url)) {
      const { fileUrl } = await api.getByCustomType(`/api/me/upload/im?url=${url}`, "blob");
      uploadLoge.value = fileUrl || "";
    }
  };

  const navigateLogo = (): void => {
    const isShare = homePluginStore.state.isShare;
    if (isShare) {
      window.location.href = settings.ImagicaUrl;
      if (settings.ImagicaUrl.includes(window.location.host)) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    initializationLogo();
  }, [saasUIData?.settings?.logoImg]);

  //  数据加载完成，且是付费APP，且没有付费
  const isShowPrice = !state.loading && state.isPaidApp && !state.isPay;
  const isViewBuilderGraphCard =
    (featureTags.disableViewBuilderToolBoxIntegration === false &&
      !func.isEmpty(saasUIData?.viewBuilderGraph?.design)) ??
    false;

  return (
    <div className={css["AiSaasHeader"]} id={"AiSaasHeader"} data-testid="AiSaasHeader">
      <header className={css["saasLoginBox"]}>
        <div className={css["saasLogin"]} onClick={() => navigateLogo()}>
          {!func.isEmpty(uploadLoge.value) ? (
            <img className={css.logoBoxImg} src={uploadLoge.value} alt="" />
          ) : (
            <NavigateLogo />
          )}
          <div className={css["other-operate-box"]}></div>
        </div>
        <AccountStatus className={css.account} />
      </header>

      <div className={css["AiSaasHeader-title"]}>
        <SaasHeadPc {...omit(props, "ref")} />
      </div>
      <div className={`${css["saas-input-content"]} ${isViewBuilderGraphCard && css["spacingBox"]}`}>
        <AISaasInput />
      </div>
      {isShowPrice ? (
        <div className={css["saas-price-text"]}>
          <PriceText className={css.priceText} />
        </div>
      ) : (
        <>
          {showSubmit && !isViewBuilderGraphCard ? (
            <div
              className={`${css["submit-button"]} ${disableButton() && css["cannot-select"]} ${
                isMobile && css["mobileSubmitStyle"]
              }`}
              onClick={clickSubmit}
            >
              {projectRunStoreState.runAllLoading ? (
                <div className={css["saas-loading"]}>
                  <LoadingOutline />
                </div>
              ) : (
                btnText.value
              )}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export function AISaasHeader(): JSX.Element {
  return (
    <AiSaasInputProvider>
      <AiSaasHeaderInner />
    </AiSaasInputProvider>
  );
}
