import Icon from "../Icon";
import Text from "../Text";
import { FunctionItemProps } from "./FunctionItem";
import Dropdown, { Triggers } from "../Dropdown";
import { useSignal } from "@preact/signals-react";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";
import { animated, useSpring } from "@react-spring/web";
import { getAssetsIcon, getAssetsImage } from "@views/thinking-layout-editor/utilities";
import style from "./FunctionCellStyle.module.scss";
import { FunctionDropdownItems } from "./type";
import fpcss from "@uiview/views/FotPopover.module.css";

export function FunctionCellItem(props: FunctionItemProps): JSX.Element {
  const name = useSignal("");
  const isDropdownOpen = useSignal(false);
  const [templateStyle, templateApi] = useSpring(() => ({
    config: { duration: 0 },
    to: [{ transform: "scale(1)" }, { transform: "scale(1)" }],
  }));

  const updateTime = useMemo(() => {
    return dayjs(props.func.updatedAt).fromNow();
  }, [props.func]);

  const createTime = useMemo(() => {
    return dayjs(props.func.createdAt).fromNow();
  }, [props.func]);

  useEffect(() => {
    const newName = props.func.label || "";
    name.value = newName;
  }, [props.func]);

  const trigger = useMemo<Triggers>(
    () => (isDropdownOpen.value ? Triggers.Click : Triggers.Focus),
    [isDropdownOpen.value]
  );

  const handleClick = useCallback(async () => {
    props.onClick?.(props.func);
    templateApi.start({
      config: { duration: 500 },
      to: [{ transform: "scale(0.96)" }, { transform: "scale(1)" }],
    });
  }, [props.func, templateApi, props.onClick]);

  const handleDropdownClick = useCallback(
    (type: FunctionDropdownItems) => () => {
      isDropdownOpen.value = false;
      props.onDropdownClick?.({
        type,
        func: props.func,
      });
    },
    [props.func, props.onDropdownClick]
  );

  const handleDropdownOpenChange = useCallback((visible: boolean) => {
    isDropdownOpen.value = visible;
  }, []);

  const imageUrl = useMemo(() => getAssetsImage("empty-thumbnail.png"), []);

  return (
    <animated.div
      className={classNames(style.cellContent, isDropdownOpen.value ? style.cellSelect : null)}
      style={templateStyle}
      onClick={handleClick}
      onContextMenu={event => {
        event.preventDefault();
        isDropdownOpen.value = true;
      }}
    >
      <div className={style.template__image}>
        <img src={imageUrl} alt="template" />
      </div>
      <div className={style.title}>{name.value}</div>
      <div className={style.title}>{updateTime}</div>
      <div className={style.title}>{createTime}</div>
      <Dropdown
        isOpen={isDropdownOpen.value}
        trigger={trigger}
        placement="bottom"
        className={style.templateToolTipsBox}
        showArrow={true}
        overlayClassName={fpcss.projectcell}
        content={
          <div className={style.dropDown_content}>
            <div className={style.dropdown__item}>
              <div className={style.dropdownItem} onClick={handleDropdownClick(FunctionDropdownItems.Duplicate)}>
                <div className={style.dropdownItem__icon}>
                  <Icon url={getAssetsIcon("copy.svg")} />
                </div>
                <div className={style.dropdownItem__text}>
                  <Text>Duplicate</Text>
                </div>
              </div>
              <div className={style.dropdownItem} onClick={handleDropdownClick(FunctionDropdownItems.Delete)}>
                <div className={style.dropdownItem__icon}>
                  <Icon url={getAssetsIcon("bin.svg")} />
                </div>
                <div className={style.dropdownItem__text}>
                  <Text>Delete</Text>
                </div>
              </div>
            </div>
          </div>
        }
        onOpenChange={handleDropdownOpenChange}
      >
        <div className={style.rightIcon}></div>
      </Dropdown>
    </animated.div>
  );
}
