import { DEFAULT_ALLOWZOMMORPAN } from "@custom-hooks/HomeUsePluginRoot";
import { useSignal } from "@preact/signals-react";
import { TextAreaRef } from "antd/lib/input/TextArea";
import { useCallback, useRef, useState } from "react";

export function useEdgeCommonState() {
  const searchFuncSignal = useSignal("");
  const aiDescriptionSingle = useSignal("");
  const selectedBlueprintsSingle = useSignal<string[]>([]);
  const disableHoverSignal = useSignal(false);
  const apiArrSignale = useSignal<any[]>([]);

  const setSearchFunc = useCallback((type: string) => {
    searchFuncSignal.value = type;
  }, []);
  const setAiDescription = useCallback((type: CustomEdge.EdgeType) => {
    aiDescriptionSingle.value = type;
  }, []);
  const setDisableHover = useCallback((value: boolean) => {
    disableHoverSignal.value = value;
  }, []);

  const setApiArr = useCallback((value: any[]) => {
    apiArrSignale.value = value;
  }, []);

  return {
    selectedBlueprintsSingle,
    searchFuncSignal,
    aiDescriptionSingle,
    disableHoverSignal,
    apiArrSignale,

    // 下列为了兼容 `CustomEdge.js` 组件曾使用的名字
    /**
     * @deprecated `edgeStateSignal.value`
     */
    apiArr: apiArrSignale.value,
    /**
     * @deprecated `edgeStateSignal.value`
     */
    setApiArr,

    /**
     * @deprecated `searchFuncSignal.value`
     */
    searchFunc: searchFuncSignal.value,
    /**
     * @deprecated `searchFuncSignal.value`
     */
    setSearchFunc,

    /**
     * @deprecated `aiDescriptionSingle.value`
     */
    aiDescription: aiDescriptionSingle.value,
    /**
     * @deprecated `aiDescriptionSingle.value`
     */
    setAiDescription,

    /**
     * @deprecated `disableHoverSignal.value`
     */
    disableHover: disableHoverSignal.value,
    /**
     * @deprecated `disableHoverSignal.value`
     */
    setDisableHover,
  };
}
