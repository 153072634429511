import { useSignal } from "@preact/signals-react";
import isBlank from "@sedan-utils/is-blank";
import { editorActions } from "@store/editor";
import { fotActions } from "@store/fot";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { FeatureTags } from "imagica-corekit/dist/base/domain/project/FeatureTags";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { ViewbuilderStore } from "imagica-corekit/dist/cases/store/ViewbuilderStore";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createContainer } from "unstated-next";
import { useAISaasContainer } from "../container";
import { AISaasInputConverter } from "./util";
import { BlueprintExecution } from "@uikit/util/BlueprintExecution";
import { ProjectNodeStore } from "@uikit/projectNode/ProjectNodeStore";

// eslint-disable-next-line
function useAiSaasInput() {
  const isMobile = window.navigator.userAgent.includes("Mobile");
  const creatorNodesStore = getIt(CreatorNodesStore);
  const viewbuilderStore = getIt(ViewbuilderStore);
  const homeStore = getIt(HomeStore);
  const projectRunStore = getIt(ProjectRunStore);
  const featureTags: FeatureTags = homeStore.state.featureTags;

  const outputListRef = useRef<HTMLDivElement>(null);
  const aiSaasContentRef = useRef<HTMLDivElement>(null);
  const editPlaceholderRef = useRef(null);
  const inputListRef = useRef<Array<any>>([]);
  const inputBoxRef = useRef<HTMLDivElement>(null);
  const titleBoxRef = useRef<{
    [key: number]: HTMLDivElement;
  }>({});

  const isFocus = useSignal<Record<any, any>>({});
  const inputTitleId = useSignal("");
  const editInputId = useSignal("");
  const inputTitle = useSignal("");
  // const btnText = useSignal('Generate Script');
  const noScroll = useSignal(false);
  const onMouseInputId = useSignal("");
  const showInputTitleId = useSignal("");
  const changeInputText = useSignal(false);
  const showBottomPrompt = useSignal(false);
  const editPlaceholderData = useSignal<{
    id?: string;
    placeholder?: string;
  }>({});
  const isEditPlaceholder = useSignal({ id: "", isEdit: false });
  const isStaticMode = useSelector((state: any) => state.editor.isStaticMode);
  const applicationTheme = useSelector((state: any) => state.editor.applicationTheme);
  /**
   * 记录当前选择的tips，id： nodeid, tips: 具体选择的tips
   */
  const selectTips = useSignal({ id: "", tips: "", type: "" } as { id: string; tips: string | string[]; type: string });
  const dispatch = useDispatch();
  const { props: aiSaasProps } = useAISaasContainer();
  const { isShare, closeInputId, clickCreateContent, saasUIInputData } = aiSaasProps;
  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const projectNodeStore = getIt(ProjectNodeStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const blueprintExecution = new BlueprintExecution(featureTags, saasUIInputData, viewbuilderStore, saasUIData);
  const setHighlightData = (val: any): void => {
    dispatch(fotActions.setHighlightData(val));
  };

  // 将 inputData 变成 sortableList
  const sortableList = useMemo(() => {
    if (saasUIData.staticMode) {
      return [];
    }
    return AISaasInputConverter.toSoratbleList(saasUIInputData);
  }, [saasUIInputData, saasUIData.staticMode]);

  const onChangeSaasUIInputData = useCallback((sortableList: SortableList.SortableData<AISaas.SaasUIInputType>[]) => {
    const saasUiInputDataList = AISaasInputConverter.toSaasUIInputData(sortableList);
    // stud-2493 set input(preview)
    creatorSaasAppStore.setSaasUIData((prev: any) => ({ ...prev, input: saasUiInputDataList }));
    projectNodeStore.composeNodes(creatorNodesStore.getNodes(), creatorSaasAppStore.state.saasUIData);
  }, []);

  const setIsStaticMode = (val: any): void => {
    dispatch(editorActions.setIsStaticMode(val));
  };

  useEffect(() => {
    setIsStaticMode(false);

    // const theme = props?.saasUIData?.theme

    // // setSaasTitleStyle(props.saasUIData?.titleStyle)

    // if(!util.isEmpty(theme)) {
    //   setApplicationTheme(theme)
    // }
    // setIsStaticMode(props?.saasUIData?.staticMode || false)
    // setShowBottomPrompt(true)
    // setTimeout(() => {
    //   if(!isEmpty(contentRef.current)) contentRef.current.scrollTop = 200
    // }, 200)

    // if(props.isShare) return;

    // props.creatorSaasAppStore.setSaasUIData(prevData => {
    //   return {
    //     ...prevData,
    //     title: prevData.title || selectedTemplate.template_name || WEB_APP_DEFAULT_TITLE,
    //     subTitle: prevData.subTitle || WEB_APP_DEFAULT_SUBTITLE,
    //   }
    // })
    // // const obj = {}
    // const foucsObj = {}
    // props.saasUIInputData.forEach(x => {
    //   // obj[x.id] = false
    //   foucsObj[x.id] = false
    // })
    // // setCursorStatus(obj)
    // setIsFoucs(foucsObj)
  }, []);

  const onClickCloseContent = (e: any, nodeId: string): void => {
    if (isStaticMode) return;
    e.preventDefault();
    e.target.value = "";
    onSaasInputChange(e, nodeId);
  };

  const onInputTitleBlur = (id: string): void => {
    console.log("onInputTitleBlur");
    judgeCleanSelectTips(id);
    inputTitleId.value = "";
  };

  const onInputTitleEnter = (e: any, nodeId: string): void => {
    e?.stopPropagation();
    e?.preventDefault();

    const newInput = saasUIInputData.map((x: any) => {
      if (x.id === nodeId) {
        return {
          ...x,
          data: {
            ...x.data,
            // stud-2493 set input title(preview)
            inputTitle: inputTitle.value?.trim(),
          },
        };
      }
      return x;
    });
    // stud-2493 deprecated
    // 更新 input title
    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        input: newInput,
      };
    });
    projectNodeStore.updateById(nodeId, { inputTitle: inputTitle.value?.trim() });

    inputTitleId.value = "";
    inputTitle.value = "";
    showInputTitleId.value = "";
  };

  const onInputEnter = async (e: any): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const emptyNode = saasUIInputData.find((x: any) => isBlank(x?.data?.textAreaValue));
    //input都有值，点击submit
    if (isBlank(emptyNode)) {
      const blueprintExecuted = await blueprintExecution.execute();
      if (blueprintExecuted) {
        return;
      }

      clickSubmit();
      return;
    }
    //聚焦到空的input
    setTimeout(() => {
      const currentInput = inputListRef.current.find((x: any) => x.refKey === emptyNode.id);
      currentInput && currentInput?.refValue?.focus();
    }, 300);
  };

  const disableButton = (): boolean => {
    const disableUpdate = !changeInputText;
    return hasEmptyInput() || disableUpdate || isStaticMode;
  };

  const hasEmptyInput = (): boolean => {
    return saasUIInputData.some((x: any) => isBlank(x?.data?.textAreaValue));
  };

  const clickSubmit = async (): Promise<void> => {
    if (disableButton()) return;

    if (hasEmptyInput()) {
      func.customMsg({
        content: "Need to input something",
        type: "warning",
      });
      return;
    }
    // e.preventDefault();
    //使输入框失去焦点
    blurInput();
    // loading不可点击
    if (projectRunStore.state.runAllLoading) return;

    setContentBoxMinHeight();
    // 滚动动画及修改状态
    // calcTitleHideScroll()

    noScroll.value = true;
    await clickCreateContent();
    noScroll.value = false;
    showBottomPrompt.value = false;
  };

  const setContentBoxMinHeight = (): void => {
    if (!aiSaasContentRef.current) {
      return;
    }
    const screenHeight = aiSaasContentRef.current?.clientHeight;
    if (outputListRef.current) {
      outputListRef.current.style.minHeight = `${screenHeight - 20}px`;
      outputListRef.current.style.height = `${screenHeight - 20}px`;
    }
  };

  const blurInput = (): void => {
    const active = document.activeElement;
    if (active?.nodeName === "TEXTAREA") {
      (active as HTMLTextAreaElement).blur();
    }
    const focusObj: { [key: string]: boolean } = {};
    saasUIInputData.forEach((x: any) => {
      focusObj[x.id] = false;
    });
    isFocus.value = focusObj;
  };

  const onSaasInputTitleChange = (e: any, id: string): void => {
    inputTitleId.value = id;
    inputTitle.value = e.target.value;
  };

  const onClickInputTitleClose = (e: any, nodeId: string): void => {
    if (isStaticMode) return;
    e?.stopPropagation();
    const newInput = saasUIInputData.map((x: any) => {
      if (x.id === nodeId) {
        return {
          ...x,
          data: {
            ...x.data,
            // stud-2493 set input title(preview)
            inputTitle: "",
          },
        };
      }
      return x;
    });
    // stud-2493 deprecated
    // 更新 input title
    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        input: newInput,
      };
    });
    projectNodeStore.updateById(nodeId, { inputTitle: "" });

    inputTitleId.value = "";
    inputTitle.value = "";
    showInputTitleId.value = "";
  };

  const onClickInputTitle = (e: any, x: any, tips: string | string[]): void => {
    if (isStaticMode) return;
    setSelectTips(x.id, tips, "inputNodeLabel");
    // 默认重置
    // stud-2493 get input title(preview)
    // const dataTitle = x?.data?.inputTitle || "";
    const dataTitle = projectNodeStore.getNode(x.id)?.inputTitle || "";

    e?.stopPropagation();
    e?.preventDefault();
    // 输入后 点击不重置
    if (isShare) {
      return;
    }
    inputTitleId.value = x.id;
    inputTitle.value = dataTitle;
  };

  const onMouseLeaveBox = (): void => {
    onMouseInputId.value = "";
  };

  const onMouseOverBox = (id: string): void => {
    if (editInputId.value === id || getIsEditPlaceholder(id)) return;
    onMouseInputId.value = id;
  };

  const onPlaceholderBlurEnter = (e?: React.FormEvent<HTMLTextAreaElement>): void => {
    e?.preventDefault();
    e?.stopPropagation();
    const newInput = saasUIInputData.map((x: any) => {
      if (getIsEditPlaceholder(x.id)) {
        setEditPlaceholder(x.id, false, selectTips.value.id === x.id ? selectTips.value.tips : "");
        return {
          ...x,
          data: {
            ...x.data,
            placeholder: editPlaceholderData.value.placeholder,
            webPlaceholder: editPlaceholderData.value.placeholder,
          },
        };
      }
      return x;
    });

    judgeCleanSelectTips(editPlaceholderData.value.id ?? "");

    // 更新 input placeholder
    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        input: newInput,
      };
    });

    //  同步更新text node中的placeholder
    creatorNodesStore.setNodes(prevList => {
      return prevList.map(pre => {
        if (pre.id === editPlaceholderData.value.id) {
          return {
            ...pre,
            data: {
              ...pre.data,
              placeholder: editPlaceholderData.value.placeholder,
            },
          };
        }
        return pre;
      });
    });
  };

  const setEditPlaceholder = (id: string, val: boolean, tips: string | string[]): void => {
    isEditPlaceholder.value = { id, isEdit: val };
    setSelectTips(id, tips);
  };
  const setSelectTips = (id: string, tips: string | string[], type: string = "inputField"): void => {
    selectTips.value = { id: id, tips: tips, type: type };
  };

  const onPlaceholderBlurClean = (id: string): void => {
    if (isEditPlaceholder.value.id === id) {
      isEditPlaceholder.value = { id, isEdit: false };
      editPlaceholderData.value = {};
    }
    judgeCleanSelectTips(id);
    /* setTimeout(() => {
      editPlaceholderData.value = {};
    }, 300) */
  };

  const getIsEditPlaceholder = (id: string): boolean => {
    return !isBlank(editPlaceholderData) && editPlaceholderData.value.id === id;
  };

  const onClickEditInputId = (e: any, id: string): void => {
    if (isStaticMode) return;
    e?.stopPropagation();
    e?.preventDefault();

    if (getIsEditPlaceholder(id)) {
      onPlaceholderBlurEnter();
      return;
    }
    setTimeout(() => {
      editInputId.value = id;
      const currentInput = inputListRef.current.find((x: any) => x.refKey === id);
      currentInput?.refValue &&
        currentInput.refValue.focus({
          cursor: "end",
        });
    }, 300);
  };

  const onDeleteInput = (e: any, id: string): void => {
    if (isStaticMode) return;
    e?.stopPropagation();
    e?.preventDefault();
    const newInput = saasUIInputData.filter((x: any) => x.id !== id);
    // stud-2493 set input(preview)(delete)
    // 删除 input
    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        input: newInput,
      };
    });
    projectNodeStore.composeNodes(creatorNodesStore.getNodes(), creatorSaasAppStore.state.saasUIData);

    closeInputId(id);
  };

  const getByPlaceholderText = (x: any, index: number, isEllipsis = true): any => {
    // 默认 Enter something
    let placeholderText = saasUIData?.placeholder || "";
    // placeholder 不是默认值。
    if (x?.data?.data?.placeholder && x?.data?.data?.placeholder !== "Enter input") {
      placeholderText = x?.data?.data?.placeholder || "";
    }

    if (!isBlank(isFocus.value) && isFocus.value[x.id]) {
      return placeholderText;
    }

    const inputRef = inputListRef.current[index]?.refValue?.resizableTextArea?.textArea;
    // 减去 input padding 22px
    const scrollWidth = inputRef?.scrollWidth - 22 || 369;

    const byteProportion = 7.48;

    const byteNum = Math.floor(scrollWidth / byteProportion);

    // 内容大于最宽字符换行
    const isOmit = placeholderText.length > byteNum;

    // 一行最大显示文字
    let oneLineText = placeholderText;

    // 换行显示...
    if (isOmit) {
      oneLineText = `${placeholderText.substr(0, byteNum)}...`;
    }

    // placeholder 文本过多，只显示一行。
    return isEllipsis ? oneLineText : placeholderText;
  };

  const onSaasPlaceholderChange = (e: any, id: string): void => {
    const placeholderData = Object.assign({}, { ...editPlaceholderData }, { placeholder: e?.target?.value, id });
    editPlaceholderData.value = placeholderData;
  };

  const onInputFocus = (nodeId: string, index: number): void => {
    if (isMobile && isShare) {
      calcScrollDistanceTop();
    }

    const focusObj: { [key: string]: boolean } = {};
    saasUIInputData.forEach((x: any) => {
      focusObj[x.id] = x.id === nodeId;
    });
    isFocus.value = focusObj;
    const inputRef = inputListRef.current[index]?.refValue?.resizableTextArea?.textArea as HTMLTextAreaElement;

    if (inputRef) {
      inputRef.setSelectionRange(inputRef.value.length, inputRef.value.length);
      inputRef.focus();
    }

    let indexPlaceholder = saasUIInputData.find((x: any) => x.id === nodeId)?.data?.placeholder;
    if (indexPlaceholder === "Enter input") {
      indexPlaceholder = saasUIData?.placeholder;
    }
    // 改变 input 高度 && placeholder 没有截断 不改变高度。
    if (!isBlank(inputRef) && isBlank(inputRef.value) && indexPlaceholder !== inputRef.placeholder) {
      inputRef.style.height = "auto";
    }
  };

  const calcScrollDistanceTop = (): void => {
    const screenHeight = document.body.scrollHeight;
    const element = inputBoxRef.current;
    if (!element || !outputListRef.current) {
      return;
    }
    // 目前存在多个 input 高度计算 确保高度正确
    const scrollHeight = element?.scrollHeight || 200;
    outputListRef.current.style.minHeight = `${screenHeight - scrollHeight}px`;
    outputListRef.current.style.height = `${screenHeight - scrollHeight}px`;

    setTimeout(() => {
      if (isBlank(element)) return;
      element.scrollIntoView(false);
      // 聚焦
    }, 300);
  };
  /**
   * 如果当前保存的是当前node的数据，那么就清空记录的tips
   * @param nodeId
   */
  const judgeCleanSelectTips = (nodeId: string): void => {
    if (selectTips.value.id === nodeId) {
      setSelectTips("", "", "");
    }
  };
  const onInputBlur = (nodeId: string, index: number): void => {
    // 失焦，那么就需要清空对应的tips记录
    judgeCleanSelectTips(nodeId);
    editInputId.value = "";
    setTimeout(() => {
      isFocus.value = {
        ...isFocus.value,
        [nodeId]: false,
      };
      const inputRef = inputListRef.current[index]?.refValue?.resizableTextArea?.textArea;
      // 失去焦点重置 input 高度
      if (!isBlank(inputRef) && isBlank(inputRef.value)) {
        inputRef.style.height = inputRef.style.minHeight;
      }
    }, 200);
  };

  const onMouseOver = (nodeId: string): void => {
    const obj = { nodeId };
    setHighlightData(obj);
  };

  const onMouseLeave = (): void => {
    setHighlightData({});
  };

  const onSaasInputChange = (e: any, nodeId: string): void => {
    changeInputText.value = true;
    const newInput = saasUIInputData.map((x: any) => {
      if (x.id === nodeId) {
        return {
          ...x,
          data: {
            ...x.data,
            textAreaValue: e.target.value,
          },
        };
      }
      return x;
    });
    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        input: newInput,
      };
    });
  };

  return {
    isDarkMode: applicationTheme === "dark",
    isFocus,
    // btnText,
    inputListRef,
    titleBoxRef,
    editInputId,
    saasUIData,
    inputTitleId,
    sortableList,
    onMouseInputId,
    showInputTitleId,
    editPlaceholderData,
    setEditPlaceholder,
    isEditPlaceholder: isEditPlaceholder.value,
    getIsEditPlaceholder,
    editPlaceholderRef,
    onPlaceholderBlurClean,
    onPlaceholderBlurEnter,
    onInputTitleBlur,
    onInputTitleEnter,
    onInputEnter,
    onInputFocus,
    onInputBlur,
    onMouseOver,
    onMouseLeave,
    onDeleteInput,
    onMouseOverBox,
    onMouseLeaveBox,
    onClickInputTitle,
    onClickCloseContent,
    onClickEditInputId,
    onSaasInputTitleChange,
    onClickInputTitleClose,
    onChangeSaasUIInputData,
    onSaasPlaceholderChange,
    getByPlaceholderText,
    onSaasInputChange,
    aiSaasProps,
    disableButton,
    clickSubmit,
    setSelectTips,
    selectTips,
  };
}

/**
 * AiSaasInput 容器
 *
 * - 只要是 AiSaasInput 组件子节点都可以通过 `useAiSaasInput` 得到内部数据和逻辑，可避免深度传递
 *
 * @returns
 */
export const AiSaasInputContainer = createContainer(useAiSaasInput);

export const { Provider: AiSaasInputProvider, useContainer: useAiSaasInputContainer } = AiSaasInputContainer;
