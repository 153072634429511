import { FunctionV2Store } from "imagica-corekit/dist/base/store/FunctionV2Store";
import store from "@store/index";
import { Lateload } from "imagica-corekit/dist/base/cutil/Lateload";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { isEmpty, set } from "lodash";
import { getIt } from "@uikit/getIt";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { RelationSetDestAttribute } from "imagica-corekit/dist/base/api/graphqlTyped/RelationSetDest";
import { CDNPathUtil } from "imagica-corekit/dist/base/util/CDNPathUtil";
import { ToolPieceStore } from "imagica-corekit/dist/base/store/ToolPieceStore";
import { BlueprintsStore } from "imagica-corekit/dist/base/store/BlueprintsStore";

export class UiFunctionService {
  constructor(
    private beFuncStore: FunctionV2Store,
    private personalFuncStore: PersonalFunctionStore,
    private toolPieceStore: ToolPieceStore,
    private blueprintsStore: BlueprintsStore,
    private homeStore: HomeStore
  ) {
    beFuncStore.on((value: any) => {
      this.dirty();
    });
    personalFuncStore.on((value: any) => {
      this.dirty();
    });
    toolPieceStore.on((value: any) => {
      this.dirty();
    });
  }

  dirty() {
    this.lateload.dirty();
  }

  get(): any[] {
    return this.lateload.get();
  }

  renew(): any[] {
    return this.lateload.renew();
  }

  static transform(
    hasUserInput: Boolean,
    funcs: any[] | undefined,
    suggestedQueries: any[] | undefined,
    suggestedFuncs: any[] | undefined,
    sourceNodeDisplayType: string,
    subSourceNodeDisplayType: string,
    groupTitleOptions: [{ label: string; value: string }]
  ): { group: FunctionGroup[]; hasSuggested: Boolean } {
    let allItems = funcs || [];
    let suggestedItems = suggestedFuncs || [];

    /// 隐藏hide === true
    allItems = UiFunctionUtil.filterHide(allItems);
    suggestedItems = UiFunctionUtil.filterHide(suggestedItems);

    /// 过滤黑白名单
    allItems = UiFunctionUtil.filterWhiteBlackList(allItems, sourceNodeDisplayType, subSourceNodeDisplayType);
    suggestedItems = UiFunctionUtil.filterWhiteBlackList(
      suggestedItems,
      sourceNodeDisplayType,
      subSourceNodeDisplayType
    );

    /// 方法排序
    allItems = UiFunctionUtil.sort(allItems);
    suggestedItems = UiFunctionUtil.sort(suggestedItems);

    /// 分组
    const group = UiFunctionUtil.group(allItems, groupTitleOptions);
    if (hasUserInput) {
      return { group: group, hasSuggested: false };
    } else {
      let suggestedGroup: FunctionGroup[] = [];
      if (suggestedQueries && !isEmpty(suggestedQueries)) {
        suggestedGroup.push(new FunctionGroup("Top Suggestion", suggestedQueries, true));
      }
      if (suggestedItems && !isEmpty(suggestedItems)) {
        suggestedGroup.push(new FunctionGroup("Suggested Functions", suggestedItems));
      }
      return { group: suggestedGroup, hasSuggested: !isEmpty(suggestedGroup) };
    }
  }

  /// 源数据：BE funcs + presonal funcs + 权限过滤 + develop mode过滤 + Experimental过滤
  private lateload: Lateload<any[]> = new Lateload(() => {
    const enableToolboxFeature = store.getState().fot.enableToolboxFeature;

    let all: any[] = [];

    // only show toolbox panel
    if (enableToolboxFeature) {
      /// tool pieces funcs
      let toolPieceFuncs = this.toolPieceStore.state.map((toolPiece, index) => {
        return {
          label: toolPiece.name,
          value: toolPiece.name,
          groupType: "toolPieces",
          groupTitle: "toolPieces",
          groupDisplayTitle: "Tool Pieces(Not User Facing)",
          description: toolPiece.description,
          order: index,
          id: toolPiece.id,
        };
      });
      toolPieceFuncs = UiFunctionUtil.checkIcon(toolPieceFuncs);

      all = [...all, ...toolPieceFuncs];

      /// blueprint funcs
      let blueprintFuncs = (this.blueprintsStore.state || []).map((blueprint, index) => {
        return {
          label: blueprint.name,
          value: blueprint.name,
          groupType: "blueprints",
          groupTitle: "blueprints",
          groupDisplayTitle: "Blueprints",
          description: blueprint.description,
          order: index,
          id: blueprint.id,
          type: "bluePrint",
        };
      });
      blueprintFuncs = UiFunctionUtil.checkIcon(blueprintFuncs);

      all = [...all, ...blueprintFuncs];
    } else {
      /// BE funcs
      let beFuncs = this.beFuncStore.state.map((element: any) => {
        return Object.assign({}, element.attributesV2, { id: element.id });
      });
      beFuncs = UiFunctionUtil.checkIcon(beFuncs);
      beFuncs = UiFunctionUtil.checkOrder(beFuncs);
      all = [...all, ...beFuncs];

      /// presonal funcs
      const presonalFuncs = UiFunctionUtil.parseSaveFunc(this.personalFuncStore.state);
      all = [...all, ...presonalFuncs];

      /// 权限过滤
      const featureTags = this.homeStore.state.featureTags;

      all.forEach(element => {
        if (element.value === "map") {
          set(element, "hide", featureTags.showMap === false);
        }
        if (element.value === "showFoodInterface") {
          set(element, "hide", featureTags.enableFood === false);
        }
        if (element.value === "askBrain") {
          set(element, "hide", false);
        }
        if (element.value === "midjourneyV2") {
          set(element, "hide", featureTags.enableMidjourneyV2 === false);
        }
        if (element.value === "Protein") {
          set(element, "hide", featureTags.showProtein === false);
        }
        if (element.value === "chatInterface") {
          set(element, "hide", false);
        }
        if (element.value === "interviewBot") {
          set(element, "hide", !featureTags.showInterviewBot);
        }
        if (element.value === "showFoodInterface") {
          set(element, "hide", !featureTags.enableFood);
        }
        if (element.value === "map") {
          set(element, "hide", !featureTags.showMap);
        }
        if (element.value === "midjourneyV2") {
          set(element, "hide", !featureTags.enableMidjourneyV2);
        }
        if (element.groupType === "toolPieces") {
          set(element, "hide", featureTags.showEdgeBluePrint === false);
        }
        if (element.groupType === "blueprints") {
          set(element, "hide", featureTags.showEdgeBluePrint === false);
        }
      });

      /// develop mode过滤
      if (store.getState().fot.developerMode) {
        const createTitle = "Create";
        const createJs = {
          label: "Create new custom JS function",
          value: "createCustomJSFunc",
          icon: CDNPathUtil.getFilePath("brain-logo.png"),
          groupDisplayTitle: createTitle,
          isCreate: true,
        };
        const createApi = {
          label: "Create API function",
          value: "createAPIFunc",
          icon: CDNPathUtil.getFilePath("brain-logo.png"),
          groupDisplayTitle: createTitle,
          isCreate: true,
        };
        all = [...all, createJs, createApi];
      }

      /// Experimental过滤
      let disableExperimental = !store.getState().fot.enableExperimentalFeature;
      if (!featureTags.showExpFeatures) {
        disableExperimental = true;
      }
      all.forEach(element => {
        if (element.experimental) {
          set(element, "hide", disableExperimental);
        }
        if (element.value === "Video" || element.label === "Video") {
          set(element, "hide", disableExperimental);
        }
        if (element.value === "Jd Shopping" || element.label === "Jd Shopping") {
          set(element, "hide", disableExperimental);
        }
        if (element.value === "Amazon Shopping(Old)" || element.label === "Amazon Shopping(Old)") {
          set(element, "hide", disableExperimental);
        }
        if (element.value === "Weee Shopping(Old)" || element.label === "Weee Shopping(Old)") {
          set(element, "hide", disableExperimental);
        }
      });
    }

    return all;
  });

  onFunctionsChange(callback: (functions: any[]) => void) {
    this.beFuncStore.on(functions => {
      callback(this.lateload.get());
    });

    this.personalFuncStore.on(functions => {
      callback(this.lateload.get());
    });
  }

  /**
   * 该方法用于等待用户 function 加载是否完成
   *
   * 因为在这之前,没有可以准确确定用户的 function 是否加载成功, 上面的 onFunctionsChange 也只是回调
   *
   * @returns
   */
  awaitProjectFunctions(): Promise<RelationSetDestAttribute[]> {
    return this.personalFuncStore.get();
  }
}

export class FunctionGroup {
  constructor(public title: string, public funcList: any[], public isTopSuggestions: Boolean = false) {}
}

export class UiFunctionUtil {
  static filterHide(funcs: any[]): any[] {
    return funcs.filter(e => e.hide !== true);
  }

  static filterWhiteBlackList(funcs: any[], nodeType: string, subNodeType: string): any[] {
    let parseType = nodeType;
    if (parseType === "customGroup") {
      parseType = "groupNode";

      return funcs.filter((func: any) => {
        const blacklist = func.blacklist;
        if (blacklist && blacklist instanceof Array && blacklist.length !== 0) {
          return !blacklist.includes(subNodeType) && !blacklist.includes(parseType);
        }

        const whitelist = func.whitelist;
        if (whitelist && whitelist instanceof Array && whitelist.length !== 0) {
          return whitelist.includes(subNodeType) || whitelist.includes(parseType);
        }

        return true;
      });
    }

    return funcs.filter((func: any) => {
      const blacklist = func.blacklist;
      if (blacklist && blacklist instanceof Array && blacklist.length !== 0) {
        return !blacklist.includes(parseType);
      }

      const whitelist = func.whitelist;
      if (whitelist && whitelist instanceof Array && whitelist.length !== 0) {
        return whitelist.includes(parseType);
      }

      return true;
    });
  }

  static sort(funcs: any[]): any[] {
    const arr = funcs.sort((a, b) => {
      if (a.order !== b.order) {
        return a.order - b.order;
      }
      const aFirstLetter = a.label.substring(0, 1).toLowerCase();
      const bFirstLetter = b.label.substring(0, 1).toLowerCase();
      return aFirstLetter.localeCompare(bFirstLetter);
    });
    return arr;
  }

  static group(funcs: any[], groupTitleOptions: [{ label: string; value: string }]): FunctionGroup[] {
    /// 对象，key = 分组名，值 = 方法数组
    const groupTypeSelectOptions: any = {};
    funcs.forEach((x: any) => {
      const group = x.groupDisplayTitle || "Other";
      groupTypeSelectOptions[group] = groupTypeSelectOptions[group] || [];
      groupTypeSelectOptions[group].push(x);
    });

    /// 按照预定义文件排序
    let groups: string[] = Object.keys(groupTypeSelectOptions);
    const groupSortOrder = groupTitleOptions.map(x => x.label);
    let groupSort: string[] = [];
    groupSortOrder.forEach(x => {
      if (groupTypeSelectOptions[x]) {
        groupSort.push(x);
      }
      groups = groups.filter(y => y !== x);
    });
    groupSort = groupSort.concat(groups);

    /// 组织新的数据结构返回
    const funcGroups: FunctionGroup[] = groupSort.map(title => {
      const funcInGroup = groupTypeSelectOptions[title];
      return new FunctionGroup(title, funcInGroup);
    });

    return funcGroups;
  }

  static parseSaveFunc(originalDatas: RelationSetDestAttribute[]): any[] {
    const transArr = originalDatas.map(each => {
      return {
        id: each.destId,
        author: each.author,
        codeData: each?.codeData || {},
        apiData: each?.apiData || {},
        type: each?.type || "",
        isCreatedManual: true,
        isV3Function: each.isV3Function || false,
        value: each.name || each.function_name,
        label: each.name || each.function_name,
        description: each.description,
        projectName: each.projectName,
        edgeArr: "",
        nodeArr: "",
        groupTitle: "myFunctions",
        groupDisplayTitle: "My Functions",
        hide: false,
        icon: CDNPathUtil.getFilePath("brain-logo.png"),
        order: Infinity,
      };
    });

    return transArr;
  }

  static checkIcon(funcs: any[]): any[] {
    const newArr = funcs.map(func => {
      const icon = func.icon;
      if (icon !== undefined && icon !== "") {
        return func;
      }
      return Object.assign({}, func, { icon: CDNPathUtil.getFilePath("brain-logo.png") });
    });
    return newArr;
  }

  static checkOrder(funcs: any[]): any[] {
    const newArr = funcs.map(func => {
      const order = func.order;
      if (order !== undefined) {
        return func;
      }
      return Object.assign({}, func, { order: Infinity });
    });
    return newArr;
  }
}
