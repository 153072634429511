import { FotNavigateController } from "@uikit/service/FotNavigateController";
import { FotNavigatePage, FotNavigateRoutes } from "./FotNavigatePage";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { MyModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { MyModificationDataStroeState } from "@uikit/store/MyModificationDataStroe";

type PageData = MyModificationDataStroeState["myModificationData"];

/**
 * 该 class 为普通函数页面
 *
 * MyModificationDataStroe 应该和 FotFunctionNoramlPage 合并,保存的数据正是 普通函数页面使用的数据
 *
 * @implements FotNavigatePage
 */
export class FotFunctionNoramlPage implements FotNavigatePage<PageData> {
  readonly route = FotNavigateRoutes.FUNCTION_NORMAL_ROUTE;

  constructor(
    readonly controller: FotNavigateController,
    private showNavPageStore: ShowNavPageStore,
    private myModificationDataStroe: MyModificationDataStroe
  ) {}

  /**
   * @override
   */
  open(pageData: PageData): void {
    this.controller.gotoRoute(this.route, { id: pageData.id });
    this.setOpenPageData(pageData);
  }

  /**
   * @override
   */
  close(): void {}

  /**
   * @override
   */
  changePageData(): void {}
  /**
   * @override
   */
  getPageData(): void {}

  setOpenPageData(pageData: PageData): void {
    this.showNavPageStore.setShowNavPage(false);
    this.myModificationDataStroe.setShowMyModification(true);
    this.myModificationDataStroe.setMyModificationData(pageData);
  }
}
