import classNames from "classnames";
import css from "./NavbarCanvasSwitcher.module.scss";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { ChatBoxManager } from "@uiview/views/MultiRoundChatOnboarding/components/Chatbox/ChatBoxManager";
import React, { useCallback, useRef } from "react";
import { NavBarImagicaIcon } from "../icons/NavBarImagicaIcon";
import { NavBarCanvasIcon } from "../icons/NavBarCanvasIcon";
import { SwitcherModeType, NavBarStore } from "../NavBarStore";
import { Popover } from "antd";
import cls from "classnames";
import { previewStore } from "@uiview/store/PreviewStore";
import { getIt } from "@uikit/getIt";
import func from "@uikit/func";
import { PreviewAppValueLangUtil } from "@uiview/views/PreviewApp/PreviewAppValueLangUtil";
import fpcss from "@uiview/views/FotPopover.module.css";

type navbarCanvasSwitcherProps = {
  disable?: boolean;
};
const PopoverContents = {
  imagica: {
    selectedTitle: "Auto-Imagica",
    selectedSubTitle: "Build your app with AI chat",
    hoverTitle: "Switch to Auto-Imagica",
    hoverSubTitle: "Build your app with AI chat",
  },

  canvas: {
    selectedTitle: "Canvas",
    selectedSubTitle: "Build your app with nodes and edges",
    hoverTitle: "Switch to Canvas",
    hoverSubTitle: "Build your app with nodes and edges",
  },
} as const;

export function NavbarCanvasSwitcher(props: navbarCanvasSwitcherProps): JSX.Element {
  const navBarStore = getIt(NavBarStore);
  const navBarState = useImagicaStore(navBarStore).value;

  const chatBoxManager = ChatBoxManager.use();
  const navbarCanvasSwitcherRef = useRef<HTMLDivElement>(null);

  const isChatApp = PreviewAppValueLangUtil.isChatAiApp(previewStore.state.previewAppList?.[0]);

  const onSwitch = useCallback(
    (e: React.MouseEvent, type: SwitcherModeType) => {
      if (navBarState.switchMode === type || isChatApp) {
        return;
      }

      //  如果preview app正在build则不能开关chat
      if (previewStore.state.loading || previewStore.state.aiBuildLoading) {
        return func.customMsg({
          content: "Please wait a moment",
          type: "warning",
        });
      }

      chatBoxManager.onSwitchChatBox(e, type);
    },
    [chatBoxManager, isChatApp, navBarState.switchMode]
  );

  const renderPopverContent = useCallback(
    (type: SwitcherModeType) => {
      const selectedTitle = PopoverContents[type];
      const selectedTyped = navBarState.switchMode === type;
      const title = selectedTyped ? selectedTitle.selectedTitle : selectedTitle.hoverTitle;
      const subTitle = selectedTyped ? selectedTitle.selectedSubTitle : selectedTitle.hoverSubTitle;
      return (
        <div className={css.popverContent}>
          <div className={css.popverTitle}>{title}</div>
          <div className={css.popverSubTitle}>{subTitle}</div>
        </div>
      );
    },
    [navBarState.switchMode]
  );

  const getPopupContainer = useCallback((e: HTMLElement) => navbarCanvasSwitcherRef.current || e, []);
  const navTemplateCls = cls(css.switcher, isChatApp ? css.disableAutoImagica : "");

  return (
    <div
      data-testid="NavbarCanvasSwitcher"
      className={cls(css.wrapper, !props.disable ? css.cursorPointer : css.cursorPointNoDrop)}
      ref={navbarCanvasSwitcherRef}
    >
      <div className={navTemplateCls}>
        {isChatApp ? (
          <span
            data-mode={SwitcherModeType.imagica}
            className={classNames(css.chatIcon, css.iconImagica, css.iconBox, {
              [css.selected]: navBarState.switchMode === SwitcherModeType.imagica,
            })}
            onClick={e => onSwitch(e, SwitcherModeType.imagica)}
          >
            <NavBarImagicaIcon />
          </span>
        ) : (
          <Popover
            placement="bottom"
            getPopupContainer={getPopupContainer}
            content={renderPopverContent(SwitcherModeType.imagica)}
            showArrow={false}
            overlayClassName={classNames(css.popoverWrap, fpcss.ncs)}
          >
            <span
              data-mode={SwitcherModeType.imagica}
              className={classNames(css.icon, css.iconImagica, css.iconBox, {
                [css.selected]: navBarState.switchMode === SwitcherModeType.imagica,
              })}
              onClick={e => onSwitch(e, SwitcherModeType.imagica)}
            >
              <NavBarImagicaIcon />
            </span>
          </Popover>
        )}

        <Popover
          placement="bottom"
          getPopupContainer={getPopupContainer}
          content={renderPopverContent(SwitcherModeType.canvas)}
          showArrow={false}
          overlayClassName={classNames(css.popoverWrap, fpcss.ncs)}
          // open
        >
          <span
            data-mode={SwitcherModeType.canvas}
            className={classNames(css.icon, css.iconCanvas, css.iconBox, {
              [css.selected]: navBarState.switchMode === SwitcherModeType.canvas,
            })}
            onClick={e => onSwitch(e, SwitcherModeType.canvas)}
          >
            <NavBarCanvasIcon />
          </span>
        </Popover>
      </div>
    </div>
  );
}
