import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const editorSlice = createSlice({
  name: "editor",
  initialState: {
    // reviseCustomCode: false,
    isCustomNodeInputFocus: false,
    isClickLogin: false,
    isClickSignup: false,
    meLoading: true,
    showGuideVideo: false,
    backHomeModal: false,
    enableTooltipWhatTodo: false,
    isStaticMode: false,
    isDraggingCreatingNewLine: {
      state: false,
      nodeId: "",
    },
    isEdgeRunning: false,

    /**第二个input node id*/
    secondInputNodeId: null,
    promptHide: 0,
    optimizeGuideVideo: "",
    currentGuideTitlePage: "",
    currentModalLineParamStr: "",
    showTooltip: "",
    loginMethod: "",
    me: {},
    sameTargetEdgeIds: [],
    multiSameTargetEdgeIds: [],
    needDisableEdgeIds: [],
    hoverEdgeData: {
      state: false,
      lineIds: [],
      edgeId: "",
      isSelected: false,
    },
    socket: {
      isDisconnect: true,
      isConnected: false,
    },
    /**
     * 该状态建议移动到 FotFunctionJSPage 中
     * @type {import("@uikit/cases/fotNavigate/FotFunctionJSPage").FunctionJsPageData}
     */
    createJSFuncData: {
      open: false,
      loading: false,
      sourceNodeId: "",
      targetNodeId: "",
      edgeId: "",
      function: "",
      state: "",
      select: {},
      parameter: {},
    },
    createAPIFuncData: {
      open: false,
      loading: false,
      sourceNodeId: "",
      targetNodeId: "",
      edgeId: "",
      function: "",
      state: "",
      select: {},
      parameter: {},
    },
    settingPreviewData: {
      name: "",
      price: "",
      otherLink: "",
      description: "",
      subscriptionPeriod: "",
      interval: "month",
      currentPriceOption: "Free",
      subscriptionLoading: false,
      isEmpower: false,
    },
    subscriberParamsData: {},
    researchAgentWebsocketData: {},
    researchAgentWSKey: "",
    applicationTheme: "light",
    fontSize: 12,
    showEditArrStore: [],
    commandEnterNodeLineParam: {},
    discardExitSaveRestartConfirm: {},
    closeToolTipArr: [],
    processingCOT: false,
    reloadMap: false,
    enableCOTStartAgain: null,
    cotSnapshotProjectId: "",
  },
  reducers: {
    setCurrentModalLineParamStr(state, action) {
      state.currentModalLineParamStr = action.payload;
    },
    setEnableCOTStartAgain(state, action) {
      state.enableCOTStartAgain = action.payload;
    },
    setMe(state, action) {
      state.me = action.payload;
    },
    setProcessingCOT(state, action) {
      state.processingCOT = action.payload;
    },
    // setReviseCustomCode(state, { payload }) {
    //   state.reviseCustomCode = payload;
    // },
    setCreateJSFuncData(state, { payload }) {
      state.createJSFuncData = Object.assign({}, state.createJSFuncData, payload);
    },
    setCreateAPIFuncData(state, { payload }) {
      state.createAPIFuncData = Object.assign({}, state.createAPIFuncData, payload);
    },
    setSettingPreviewData(state, { payload }) {
      state.settingPreviewData = Object.assign({}, state.settingPreviewData, payload);
    },
    setSocket(state, { payload }) {
      state.socket = Object.assign({}, state.socket, payload);
    },
    setResearchAgentWebsocketData(state, { payload }) {
      const key = payload.key;
      if (payload.clearAll) {
        if (key) {
          const tmp = state.researchAgentWebsocketData;
          delete tmp[key];
          state.researchAgentWebsocketData = tmp;
          return;
        }
        state.researchAgentWebsocketData = {};
        return;
      }
      // state.researchAgentWebsocketData = state.researchAgentWebsocketData.concat(payload.data)
      const data = (state.researchAgentWebsocketData[key] || []).concat(payload.data);
      const tmp = state.researchAgentWebsocketData;
      tmp[key] = data;
      state.researchAgentWebsocketData = tmp;
    },
    setResearchAgentWSKey(state, { payload }) {
      state.researchAgentWSKey = payload;
    },
    setIsCustomNodeInputFocus(state, { payload }) {
      state.isCustomNodeInputFocus = payload;
    },
    setIsClickLogin(state, { payload }) {
      state.isClickLogin = payload;
    },
    setIsClickSignup(state, { payload }) {
      state.isClickSignup = payload;
    },
    setCurrentGuideTitlePage(state, { payload }) {
      state.currentGuideTitlePage = payload;
    },
    setApplicationTheme(state, { payload }) {
      state.applicationTheme = payload;
    },
    setFontSize(state, { payload }) {
      state.fontSize = payload;
    },
    setShowEditArrStore(state, { payload }) {
      state.showEditArrStore = payload;
    },
    setMeLoading(state, { payload }) {
      state.meLoading = payload;
    },
    setShowGuideVideo(state, { payload }) {
      state.showGuideVideo = payload;
    },
    setEnableTooltipWhatTodo(state, { payload }) {
      state.enableTooltipWhatTodo = payload;
    },
    setOptimizeGuideVideo(state, { payload }) {
      state.optimizeGuideVideo = payload;
    },
    setBackHomeModal(state, { payload }) {
      state.backHomeModal = payload;
    },
    setDiscardExitSaveRestartConfirm(state, { payload }) {
      state.discardExitSaveRestartConfirm = payload;
    },
    setShowTooltip(state, { payload }) {
      if (isEmpty(payload)) {
        state.closeToolTipArr = [];
      }
      state.showTooltip = payload;
    },
    setCloseToolTipArr(state, { payload }) {
      state.closeToolTipArr = state.closeToolTipArr.concat(payload);
    },
    setSubscriberParamsData(state, { payload }) {
      state.subscriberParamsData = payload;
    },
    setSameTargetEdgeIds(state, { payload }) {
      state.sameTargetEdgeIds = payload;
    },
    setMultiSameTargetEdgeIds(state, { payload }) {
      state.multiSameTargetEdgeIds = payload;
    },
    setReloadMap(state, { payload }) {
      state.reloadMap = payload;
    },
    setLoginMethod(state, { payload }) {
      state.loginMethod = payload;
    },
    setIsStaticMode(state, { payload }) {
      state.isStaticMode = payload;
    },
    setSecondNodeId(state, { payload }) {
      state.secondInputNodeId = payload;
    },
    setNeedDisableEdgeIds(state, { payload }) {
      state.needDisableEdgeIds = payload;
    },
    setCotSnapshotProjectId(state, { payload }) {
      state.cotSnapshotProjectId = payload;
    },
    setHoverEdgeData(state, { payload }) {
      state.hoverEdgeData = payload;
    },
    setIsDraggingCreatingNewLine(state, { payload }) {
      state.isDraggingCreatingNewLine = payload;
    },
    setCommandEnterNodeLineParam(state, { payload }) {
      state.commandEnterNodeLineParam = payload;
    },
    setIsEdgeRunning(state, { payload }) {
      state.isEdgeRunning = payload;
    },
  },
});

export const editorActions = editorSlice.actions;
export default editorSlice.reducer;
