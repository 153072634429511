import { Popover } from "antd";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { logEvent } from "@uikit/service/amplitude";
import { editorActions } from "../../../store/editor";
import func from "@uikit/func";
import styles from "../../thinking-layout-editor/css/HomeToolBar.module.css";
import HelpButton from "../HelpButton";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { WindowSizeStore } from "@uikit/store/WindowSizeStore";
import style from "./Help.module.scss";

const Help = () => {
  const dispatch = useDispatch();

  const setCurrentGuideTitlePage = val => {
    dispatch(editorActions.setCurrentGuideTitlePage(val));
  };

  const [openHelp, setOpenHelp] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  const webconfigState = useStore(getIt(WebConfigStore)).value;

  const clickSupportCommunity = () => {
    setOpenHelp(false);
    logEvent("user_guide");
    window.open("https://imagica.zendesk.com/hc/en-us");
  };

  const clickQuickStartVideo = () => {
    setOpenHelp(false);
    logEvent("quickstart_video");
    window.open("https://vimeo.com/833817095");
  };

  const requestFutrue = () => {
    window.open("https://imagica.ideas.aha.io/ideas/new");
  };

  const clickDiscordCommunity = () => {
    logEvent("discord");
    setOpenHelp(false);
    window.open(webconfigState.static_links?.discord_community);
  };

  const clickGoNewPage = () => {
    setOpenHelp(false);
    window.open("https://imagica.ideas.aha.io/");
  };

  const handleReportBugClick = useCallback(() => {
    document.getElementById("birdeatsbug-default-button")?.click();
    setOpenHelp(false);
  }, []);

  const helpMenu = [
    {
      title: "Quick start video",
      oneIcon: "startVideo",
      twoIcon: "externalLink",
      onClick: clickQuickStartVideo,
      disable: false,
    },
    {
      title: "Imagica user guide",
      oneIcon: "userGuide",
      twoIcon: "externalLink",
      onClick: clickSupportCommunity,
    },
    {
      title: "Discord community",
      oneIcon: "discordCommunity",
      twoIcon: "externalLink",
      onClick: clickDiscordCommunity,
      dataAmplitude: "discord",
    },
    {
      title: "Request a feature",
      oneIcon: "requestafeature",
      twoIcon: "externalLink",
      onClick: requestFutrue,
    },
  ];

  const helpContent = (
    <div style={{ width: "300px" }}>
      {helpMenu.map((x, index) => {
        if (x?.disable) return null;
        return (
          <div
            data-testid={`helpContent-${index}`}
            className={styles["help-menu"]}
            key={index}
            onClick={() => x.onClick()}
          >
            <div className={styles["box-display"]} data-amplitude={x.dataAmplitude}>
              <div className={x.oneIcon}></div>
              <div className={`${styles["help-title"]} ${x.class}`}>{x.title}</div>
              {!func.isEmpty(x.twoIcon) && <div className={x.twoIcon}></div>}
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={styles["help-wrapper"]}>
      <div
        className={styles["help-hover-wrapper"]}
        onMouseOver={() => {
          setShowAnimation(true);
          setOpenHelp(true);
        }}
        onMouseLeave={() => {
          setShowAnimation(false);
          setOpenHelp(false);
        }}
      >
        <Popover
          open={openHelp}
          onOpenChange={() => {
            setOpenHelp(!openHelp);
          }}
          getPopupContainer={e => e}
          overlayClassName={style.helpPopover}
          placement="topRight"
          content={helpContent}
          color="#fff"
        >
          <HelpButton showAnimation={showAnimation} />
        </Popover>
      </div>
    </div>
  );
};

export { Help };
