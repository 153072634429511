import { NodeResizer } from "@reactflow/node-resizer";
import "@reactflow/node-resizer/dist/style.css";
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty } from "lodash";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useStore as useReduxStore, useSelector } from "react-redux";
import { Handle, Position, useStore as useReactFlowStore } from "reactflow";
import { editorActions } from "../../store/editor";
import { fotActions } from "../../store/fot";
import func from "@uikit/func";
import NodeToolBar from "./NodeToolBar";
import style from "./css/CustomNode.module.css";
import { DataSyncService } from "@uikit/service/DataSyncService";
import { useSignal } from "@preact/signals-react";
import AddToolTip from "@uiview/views/AISaas/AddToContent/addTooltip";
import { AddEdgeBackView } from "@views/thinking-layout-editor/AddEdgeBackView/AddEdgeBackView";
import cls from "classnames";
import { eventbus } from "imagica-corekit/dist/base/cutil/Eventbus";
import { EdgeRunMsg } from "imagica-corekit/dist/base/msg/EdgeRunMsg";
import { NodeContentEnterMsg } from "imagica-corekit/dist/base/msg/NodeContentEnterMsg";
import { AddHandle } from "@uikit/service/AddHandle";
import { debounce, get, throttle } from "lodash";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import { CreatorCanvasFocus } from "@uikit/cases/canvasFocus/CreatorCanvasFocus";
import useGroupDraggable from "../../custom-hooks/useGroupDraggable";
import { getTopParentNodeId } from "../../custom-hooks/useGroupDraggable/GroupDragUtil";
import useIntroTooltip from "../../custom-hooks/useIntroTooltip";
import useNodeEdgeMaxIndex from "../../custom-hooks/useNodeEdgeMaxIndex";
import { useObserverNodeSize } from "imagica-uikit/dist/hooks/useObserverNodeSize";
import { BluePrintInfoModal } from "@uiview/views/Nodes/BluePrintNode/components/BluePrintInfoModal";
import { logEvent } from "@uikit/service/amplitude";
import { Nodes } from "@uiview/views/Nodes/Nodes";
import { DesignBox } from "../../uiview/views/DesignBox/DesignBox";
import { nodeConfig, disableEdgesTypeArray } from "@uiview/views/Nodes/nodeConfig";
import RejectTip from "../../uiview/views/RejectTip/RejectTip";
import UsupportedTypeModal from "../../uiview/views/UsupportedTypeModal";
import ThreeBotsLoading from "../components/ThreeBotsLoading";
import { TooltipWhatTodo } from "../components/TooltipWhatTodo";
import { AI_INPUT_PLACEHOLDER, AI_INPUT_PLACEHOLDER_MULTI_NODES, HANDLES_V3 } from "./constants";
import { CustomNodeUtil } from "./CustomNodeUtil";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { getIt } from "@uikit/getIt";
import { ReactFlowNodeUtil } from "@uikit/util/ReactFlowNodeUtil";
import { useSetSignal } from "@uikit/hooks/useSetSignal";
import { NodeConfigUtil } from "@uiview/views/Nodes/NodeConfigUtil";
import { previewStore } from "@uiview/store/PreviewStore";
import { useStore as useImagincStore } from "imagica-uikit/dist/hooks/useStore";
import { ShareAppService } from "@uikit/service/ShareAppService";
import { EdgeAddIcon } from "@views/assets/EdgeAddIcon";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { PreviewAppValueLangUtil } from "@uiview/views/PreviewApp/PreviewAppValueLangUtil";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { CreatorPreviewService } from "@uikit/service/CreatorPreviewService";
import { CreatorNodesConsts } from "@uikit/const/CreatorNodesConsts";
import { CreatorCanvasDeleteMethods } from "@uikit/service/CreatorCanvasDeleteService";
import { OpenAiParam } from "@uikit/service/OpenAiParam";
import { PostUrlCanAbortService } from "@uikit/service/PostUrlCanAbortService";
import { EdgeRunAll } from "@uikit/service/EdgeRunAll";
import { FotReactFlow } from "@uikit/model/FotReactFlow";
import { CotStore } from "@uikit/store/CotStore";
import { StoryNodeDisplayType } from "imagica-corekit/dist/base/storyV2/domain/StoryNodeDisplayType";

const gql = getIt(ObjectRelationGqlService);

const creatorAISaasStore = getIt(CreatorAISaasStore);
const typeFormat = {
  htmlTemplate: "code",
  stockInfo: "stock",
  imageGen: "image",
  midjourney: "image",
  imageSearch: "image",
};
const container = {
  show: {
    transition: {
      staggerChildren: 0.08,
    },
  },
  hide: {
    opacity: 0,
    y: "30px",
    transition: {
      duration: 0.32,
    },
  },
};
const tradeOptionsItem = {
  init: {
    opacity: 0,
    y: "10px",
  },
  show: {
    opacity: 1,
    y: "0",
  },
};
const htmlReg = /<[^>]*>[^<]*<\/[^>]*>/g;
const creatorSaasAppStore = getIt(CreatorSaasAppStore);
const connectionNodeIdSelector = state => state.connectionNodeId;

const nodeVariants = {
  hidden: {
    scale: 0.6,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: 0.2,
      ease: "easeInOut",
    },
  },
};

export default memo(({ data, ...props }) => {
  const fotReactFlow = getIt(FotReactFlow);
  const homeStore = getIt(HomeStore);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const creatorCanvasDeleteMethods = getIt(CreatorCanvasDeleteMethods);
  const openAiParam = getIt(OpenAiParam);
  const postUrlCanAbortService = getIt(PostUrlCanAbortService);
  const edgeRunAll = getIt(EdgeRunAll);
  const creatorPreviewState = useImagincStore(previewStore).value;
  const creatorAISassState = useImagincStore(creatorAISaasStore).value;
  const cotStore = getIt(CotStore);
  const cotStoreState = useImagincStore(cotStore).value;
  const feature_tags = homeStore.state.featureTags;
  const customNodeRef = useRef(null);
  const { size } = useObserverNodeSize(customNodeRef, Boolean(data.parentNodeId) === false);

  const store = useReduxStore();
  const dispatch = useDispatch();
  const creatorStoreMethods = getIt(CreatorStoreMethods);
  const creatorPreviewService = getIt(CreatorPreviewService);

  const addHandle = getIt(AddHandle);
  const shareAppService = getIt(ShareAppService);

  const isStaticApp = useSignal(false);

  // FIXME: 可能需要移除
  useEffect(() => {
    isStaticApp.value = PreviewAppValueLangUtil.isStaticApp(creatorSaasAppStore.state.saasUIData);
  }, [creatorPreviewState.selectedUI]);

  const textAreaValueRef = useRef({
    angle: "",
    results: [
      {
        angles: [],
        content: "",
        dislikes: [],
        filters: [],
        hc_tag: -1,
        likes: [],
        reactions: [],
        thought: "",
        type: "",
      },
    ],
  });

  const customJsFunctionRef = useRef(null);
  const nodeDomRef = useRef(null);
  const customImageNodeRef = useRef(null);

  const [showLeftHoverItems, setShowLeftHoverItems] = useState(false);
  const [errorText, setErrorText] = useState(data.errorText || "");
  const [showRejectTip, setShowRejectTip] = useState(!func.isEmpty(data.errorText));
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [openDesignBox, setOpenDesignBox] = useState(false);

  /**
   * fitSize 展开状态 0：fitSize  1: resetSize
   * 目前仅 textNode 使用
   */
  const [fitItemStatus, setFitItemStatus] = useState(0);

  /**
   * 字段说明：
   * 1、在原有的自动适应高度的逻辑外，增加一个控制字段，控制是否自适应高度
   * 2、优先级最高，状态true: 执行原自适应逻辑，状态false:禁止自适应
   * 3、适用UI交互字段，需要动态控制Node是否自适应。例：TextNode有交互逻辑禁用自适应的逻辑
   * 4、目前仅TextNode和AskImagica使用, askBrain初始为自适应高度，text为固定的初始高度。
   */
  const [allowFitSize, setAllowFitSize] = useState(data.displayType === "askBrain");

  const [textAreaValue, setTextAreaValue] = useState([textAreaValueRef.current]);
  const [currentHoverDirection, setCurrentHoverDirection] = useState("");

  const isOpenBluePrintInfo = useSignal(false);

  const [minWidthAndHeight, setMinWidthAndHeight] = useState(CustomNodeUtil.getMinSize(data));

  // STUD-1315: hover node 时显示 toolbar
  const nodeHovered = useSignal(false);
  const showBluePrintFeedbackModal = useSignal(false);

  const [addIconHoverItems, setAddIconHoverItems] = useState([
    {
      label: "",
      loading: false,
    },
  ]);
  const checkNodeArr = useSelector(state => state.fot.checkNodeArr);
  const highlightData = useSelector(state => state.fot.highlightData);
  const preSelectNodeId = useSelector(state => state.fot.preSelectNodeId);
  const developerMode = useSelector(state => state.fot.developerMode);
  const disableAddPreview = useSelector(state => state.fot.disableAddPreview);
  const enableDesignSpaceFeature = useSelector(state => state.fot.enableDesignSpaceFeature);

  const setPreSelectNodeId = val => {
    dispatch(fotActions.setPreSelectNodeId(val));
  };
  const setCommandEnterNodeId = useCallback(
    val => {
      dispatch(fotActions.setCommandEnterNodeId(val));
    },
    [dispatch]
  );

  const setVariableList = val => {
    dispatch(fotActions.setVariableList(val));
  };

  const isCustomNodeInputFocus = useSelector(state => state.editor.isCustomNodeInputFocus);
  const setIsCustomNodeInputFocus = useCallback(
    val => {
      dispatch(editorActions.setIsCustomNodeInputFocus(val));
    },
    [dispatch]
  );
  const setShowTooltip = val => {
    dispatch(editorActions.setShowTooltip(val));
  };
  const setCloseToolTipArr = val => {
    dispatch(editorActions.setCloseToolTipArr(val));
  };

  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);
  const isDraggingCreatingNewLine = useSelector(state => state.editor.isDraggingCreatingNewLine);

  const startNodeIds = useSelector(state => state.fot.startNodeIds);

  // const setSelectedTemplate = (val) => { dispatch(studioActions.setSelectedTemplate(val)) };

  const creatorCanvasFocus = getIt(CreatorCanvasFocus);
  const focusNodeTopBarById = useCallback(
    (id, options) => {
      return creatorCanvasFocus.focusNodeTopBarById(id, {
        showPreviewPanel: previewStore.state.showPreviewPanel,
        ...options,
      });
    },
    [creatorCanvasFocus]
  );
  const inputRef = useRef(null);
  const typeInputRef = useRef(null);
  const generateResultRef = useRef("");
  const prevTextAreaVal = useRef("");
  const resizeNodeParam = useRef({});

  const connectionNodeId = useReactFlowStore(connectionNodeIdSelector);
  const isTarget = connectionNodeId && connectionNodeId !== props.id;
  const [isOpenDeleteBox, setIsOpenDeleteBox] = useState(false);
  const [isLongPress, setIsLongPress] = useState(false);
  const [resizeEventParams, setResizeEventParams] = useState({});

  /// 粘贴的node，可能被手动resize过，需要同步内部大小
  useEffect(() => {
    // opt:
    const nodeEle = ReactFlowNodeUtil.getNodeById(props.id, creatorNodesStore.getNodes());
    // const nodeEle = getNode(props.id);
    if (nodeEle && data.isResized) {
      setResizeEventParams({ width: Math.min(nodeEle.width, nodeEle.style.maxWidth), height: nodeEle.height });
    }
    CustomNodeUtil.setNodeMaxLayout(data, props.id, creatorNodesStore.setNodes);
  }, [props.id]);

  /// 记录同一个Node，DisplayType的变化
  const lastType = useSetSignal("", (newv, lastv) => {
    if (lastv && newv && newv !== lastv) {
      setResizeEventParams({});
      CustomNodeUtil.reductionNodeEventSize(creatorNodesStore.setNodes, props.id);
      CustomNodeUtil.setNodeMaxLayout(data, props.id, creatorNodesStore.setNodes);
    }
    return true;
  });

  const [focusNodeId, setFocusNodeId] = useState("");

  const targetHandleStyle = { zIndex: isTarget ? 3 : 1 };

  const { getNodeEdgeMaxIndex } = useNodeEdgeMaxIndex();

  //  通过aichat模板创建的node，及自动生成的chatInterface node需要显示对应的tooltip
  const isAiChatOfNode = data.chatAi || data.showChatInterfaceTooltip;
  const { introTooltipObj } = useIntroTooltip(props.id, data.displayType, undefined, undefined, isAiChatOfNode);
  // const bloc = new MapViewBloc(mapboxgl, props.id, data.textAreaValue)

  const [feedback] = useState(creatorNodesStore.getNodes().find(x => x.id === props.id)?.feedback);
  const [isTargetNode] = useState(creatorEdgesStore.getEdges().some(y => y.target === props.id));
  // FIXME:nodesRef: getTopParentNodeId
  const nodesRef = useMemo(
    () => ({
      get current() {
        return creatorNodesStore.getNodes();
      },
    }),
    []
  );
  // use creatorNodesStore
  const nodeId = getTopParentNodeId({ id: data.parentNodeId, nodesRef });
  useGroupDraggable({ nodeId, nodeRef: customNodeRef, disable: false });

  // const [iframeRef] = useHtmlIframe({
  //   htmlStr: data.textAreaValue,
  //   deps: [data.textAreaValue]
  // })
  const textAreaChangeValue = useSetSignal("", undefined, (newvalue, _) => {
    if (typeof newvalue === "string") {
      return newvalue;
    } else {
      return undefined;
    }
  });

  useEffect(() => {
    textAreaChangeValue.value = textAreaValue[0].results.content;
  }, [data.displayType]);

  const setTextAreaValueWithParam = param => {
    textAreaChangeValue.value = param.content;
    setTextAreaValue(prevList => {
      return prevList.map(x => {
        const newResults = x.results.map(r => {
          return {
            ...r,
            ...param,
          };
        });
        return {
          ...x,
          results: newResults,
        };
      });
    });
  };
  const handleNodeContentChangeUndo = useCallback(
    text => {
      if (prevTextAreaVal.current !== text) {
        creatorStoreMethods.onNodeContentChange(
          HomeMethodsUtil.getStartEndNodesContentArr(
            [
              {
                id: props.id,
                textAreaValue: prevTextAreaVal.current,
              },
            ],
            [
              {
                id: props.id,
                textAreaValue: text,
              },
            ],
            creatorNodesStore.state.nodes
          )
        );
      }
    },
    [data, props.id]
  );

  const onTextAreaBlur = useCallback(
    (e, value) => {
      setIsCustomNodeInputFocus(false);
      setFocusNodeId("");
      let text = e?.target?.value || value;
      if (typeof text === "string") {
        text = text.trim();
        setTextAreaValueWithParam({ content: text });
      }

      handleNodeContentChangeUndo(text);
      // bsf-5585
      creatorNodesStore.setNodes(prevNodes => {
        return prevNodes.map(l => {
          if (l.id === props.id) {
            return {
              ...l,
              data: {
                ...l.data,
                textAreaValue: text,
              },
            };
          }
          return l;
        });
      });
    },
    [data, handleNodeContentChangeUndo, props.id, setIsCustomNodeInputFocus]
  );

  const onTextAreaFocus = useCallback(() => {
    focusNodeTopBarById(props.id);
    setFocusNodeId(props.id);
    setIsCustomNodeInputFocus(true);
    prevTextAreaVal.current = textAreaValue[0].results[0].content;
  }, [focusNodeTopBarById, props.id, setIsCustomNodeInputFocus, textAreaValue]);

  const onTextAreaEnter = useCallback(
    e => {
      // ctrl + enter执行run（需要修改为command+enter）
      if (e.metaKey) {
        let text = e.target.value;
        if (typeof text === "string") {
          text = e.target.value.trim();
        }
        setTextAreaValueWithParam({
          content: text,
        });
        onTextAreaBlur(e);
        /// 触发edge run，以下两种方式分别在v2 v3的边起作用
        setTimeout(() => {
          setCommandEnterNodeId(props.id);
          eventbus.emit(new NodeContentEnterMsg(props.id));
        }, DataSyncService.timeoutNeedNodeDataUpdate);
      }
    },
    [onTextAreaBlur, props.id, setCommandEnterNodeId]
  );

  const onMouseEnter = () => {
    nodeHovered.value = true;
    if (isOpenDeleteBox) return;
    setIsOpenDeleteBox(true);
  };
  const onMouseLeave = () => {
    if (showBluePrintFeedbackModal.value) return;
    nodeHovered.value = false;
    setIsOpenDeleteBox(false);
  };

  const onChangeShowBluePrintFeedbackModal = bool => {
    showBluePrintFeedbackModal.value = bool;
  };

  useEffect(() => {
    if (preSelectNodeId !== props.id) {
      setIsLongPress(false);
    }
  }, [preSelectNodeId]);

  const clickWholeNode = e => {
    // BSF-3007：点击聚焦
    if (data.displayType !== "text" && data.displayType !== "json" && data.displayType !== "inputTextarea") {
      return;
    }

    setIsLongPress(true);
    setPreSelectNodeId(props.id);
    // 选中边框高亮状态才进入
    if (func.isEmpty(checkNodeArr) || !checkNodeArr.includes(props.id)) return;

    const typeInput = typeInputRef.current;
    const contentInput = inputRef.current;

    e?.preventDefault();
    // e?.stopPropagation()
    /// 先取消input聚焦
    /// typeInput的blur事件有方法响应处理，只在第一次点击node且点击target为typeInput时才blur
    if (typeInput && e.target === typeInput.input && preSelectNodeId !== props.id) {
      typeInput?.blur();
    }

    /// 顶部输入框聚焦
    if (!func.isEmpty(typeInput)) {
      const input = typeInput.input;
      /// 顶部输入框
      if (input && e.target === input) {
        typeInput.focus();
        return;
      }
    }

    /// 内容输入框聚焦
    if (!func.isEmpty(contentInput)) {
      contentInput.focus();
    }
  };

  const copyContent = useCallback(
    e => {
      e?.preventDefault();
      const text = textAreaValue[0].results[0].content || data.placeholder;
      navigator?.clipboard?.writeText(text);
      func.customMsg({
        content: "Copied",
        type: "info",
      });
    },
    [data.placeholder, textAreaValue]
  );

  const retryLastEdge = () => {
    let currLine = null;
    if (func.isEmpty(data.parentNodeId)) {
      const ids = store.getState().editor.sameTargetEdgeIds;
      const lines = creatorEdgesStore.getEdges().filter(item => {
        return item.target === props.id && ids.includes(item.id);
      });
      currLine = lines.last();
    } else {
      //for group nodes
      currLine = creatorEdgesStore.getEdges().find(x => x.target === data.parentNodeId);
    }
    if (!currLine) {
      return;
    }

    const sourceEdge = getSourceNode(currLine);
    const errorStore = errorText;
    const msg = new EdgeRunMsg(sourceEdge.id, true, ableRun => {
      if (!ableRun) {
        setErrorText(errorStore);
        setShowRejectTip(true);
      }
    });
    /// 触发edge run，以下两种方式分别在v2 v3的边起作用
    setCommandEnterNodeId(sourceEdge.id);
    eventbus.emit(msg);
  };

  const getSourceNode = useCallback(currEdge => {
    return creatorNodesStore.getNodes().find(x => x.id === currEdge.source);
  }, []);

  const getCurrentEdgeV2 = useCallback(() => {
    const allNodes = creatorNodesStore.getNodes();
    const allEdges = creatorEdgesStore.getEdges();
    let currEdge = null;
    if (func.isEmpty(data.parentNodeId)) {
      const currLine = allEdges.find(x => x.target === props.id);
      currEdge = allNodes.find(x => x.id === currLine.source);
    } else {
      //for group nodes
      const currLine = allEdges.find(x => x.target === data.parentNodeId);
      currEdge = allNodes.find(x => x.id === currLine.source);
    }
    return currEdge;
  }, [data.parentNodeId, props.id]);

  const getCurrentSourceNodeV2 = useCallback(() => {
    const allNodes = creatorNodesStore.getNodes();
    const edge = getCurrentEdgeV2();
    const sourceNodeID = edge.data.flows[0].sourceNodeId;
    const node = allNodes.find(x => x.id === sourceNodeID);
    return node;
  }, [getCurrentEdgeV2]);

  const setNodeFeedback = useCallback(
    async (feedbackValue, isLike, isCancel) => {
      try {
        const isCurrNodeSaved =
          !func.isEmpty(selectedTemplate.v2?.nodes?.filter(x => x.id === props.id)) ||
          !func.isEmpty(selectedTemplate.v3?.nodes?.filter(x => x.id === props.id)); //if curr project / node is not saved, save the feedback later when the project is saved
        let currEdge = null;
        if (func.isEmpty(data.parentNodeId)) {
          currEdge = creatorEdgesStore.getEdges().find(x => x.target === props.id);
        } else {
          //for group nodes
          currEdge = creatorEdgesStore.getEdges().find(x => x.target === data.parentNodeId);
        }
        let sourceNode = getSourceNode(currEdge);
        currEdge = getCurrentEdgeV2();
        sourceNode = getCurrentSourceNodeV2();
        const lineType = currEdge?.data?.lineParam?.lineType || ""; //'identifier' for user-entered prompts, 'prompt' for functions
        const promptId = currEdge?.data?.lineParam?.identifier?.value || ""; //prompt id for user-entered prompts
        const function_name = currEdge?.data?.lineParam?.enterText || ""; //function name for functions
        let feedback = null;
        if (lineType === "prompt") {
          //functions
          feedback = {
            user_input: feedbackValue,
            type: lineType,
            function_name: function_name,
          };
        } else {
          //user-entered prompts, lineType == "identifier"
          feedback = {
            user_input: feedbackValue,
            type: lineType,
            id: promptId,
          };
        }
        const updatednNodes = creatorNodesStore.getNodes().map(l => {
          //to be saved to db
          if (l.id === props.id) {
            return {
              ...l,
              feedback: feedback,
            };
          }
          return l;
        });
        creatorNodesStore.setNodes(updatednNodes); // make sure save does not roll back user feedback

        const updatedProject = {
          ...selectedTemplate,
          v2: { ...selectedTemplate.v2, nodes: updatednNodes },
          v3: { ...selectedTemplate.v3, nodes: updatednNodes },
        };
        // save to db
        if (!func.isEmpty(selectedTemplate.index) && isCurrNodeSaved) {
          //if project is not newly created AND current node is already saved, call api to save feedback to node
          await gql.updateObject(parseInt(selectedTemplate.id), {
            name: "studio_project",
            attributes: updatedProject,
          });
        }
        const eventProperties = {
          line_param: currEdge?.data?.lineParam,
          feedback: feedback,
          created_from_cot: currEdge?.data?.lineParam?.createFromCot,
          source_type: sourceNode.data.displayType,
          source_value: sourceNode.data.textAreaValue,
          target_type: data.displayType,
          target_value: data.textAreaValue,
        };
        if (isLike) {
          if (isCancel) {
            //cancel like
            logEvent("user_feedback_like_cancel", eventProperties);
          } else {
            //like
            logEvent("user_feedback_like", eventProperties);
          }
        } else {
          if (isCancel) {
            //cancel dislike
            logEvent("user_feedback_dislike_cancel", eventProperties);
          } else {
            //dislike
            logEvent("user_feedback_dislike", eventProperties);
          }
        }
      } catch (error) {
        func.messageError(error);
        console.error(`setNodeFeedback error: ${error}`);
      }
    },
    [
      props.id,
      data.displayType,
      data.parentNodeId,
      data.textAreaValue,
      getCurrentEdgeV2,
      getCurrentSourceNodeV2,
      getSourceNode,
      selectedTemplate,
    ]
  );

  const updateFeedbackLike = useCallback(() => {
    setNodeFeedback(1, true, false);
  }, [setNodeFeedback]);

  const updateFeedbackLikeCancel = useCallback(() => {
    setNodeFeedback(0, true, true);
  }, [setNodeFeedback]);

  const updateFeedbackDislike = useCallback(() => {
    setNodeFeedback(-1, false, false);
  }, [setNodeFeedback]);

  const updateFeedbackDislikeCancel = useCallback(() => {
    setNodeFeedback(0, false, true);
  }, [setNodeFeedback]);

  const highlightName = () => {
    let highlightId = "";
    // 是group则不高亮node
    if (!func.isEmpty(highlightData)) {
      if (func.isEmpty(highlightData.parentNode)) {
        highlightId = highlightData.nodeId === props.id ? "highlight" : "lowBright";
      } else {
        highlightId = highlightData.parentNode === data.parentNodeId ? "" : "lowBright";
      }
    }
    return highlightId;
  };
  const onShrinkAndExpand = className => {
    let tempStyle = {};
    const type = data.displayType;
    let initSize = NodeConfigUtil.getInitSize(type);
    const initWidth = initSize.width;
    const isAskImagica = type === "askBrain";
    if (isAskImagica) {
      initSize.height = undefined; // askImagica高度自适应
    }
    switch (className) {
      case "FitSize":
        setAllowFitSize(true);
        const textContent = isAskImagica ? data.textAreaValue.answer : data.textAreaValue;
        if (func.isEmpty(textContent) || (textContent && textContent.length < 30)) {
          setNodeFitSize({ width: initWidth, maxHeight: 850, height: "auto", minHeight: 150 });
          onResize(undefined, { width: initWidth, height: undefined });
          tempStyle.width = initWidth;
        } else {
          setNodeFitSize({ width: 400, maxHeight: 850, height: "auto", minHeight: 150 });
          onResize(undefined, { width: 400, height: undefined });
          tempStyle.width = 400;
        }
        setFitItemStatus(1);
        break;
      case "ResetSize":
        setAllowFitSize(isAskImagica);
        tempStyle = { ...initSize };
        setNodeFitSize(initSize);
        onResize(undefined, initSize);
        setFitItemStatus(0);
        break;
      default:
        setNodeFitSize(initSize);
        onResize(undefined, initSize);
        setFitItemStatus(0);
    }
    creatorNodesStore.setNodes(prevList => {
      return prevList.map(l => {
        if (l.id === props.id) {
          return {
            ...l,
            style: {
              ...l.style,
              width: tempStyle.width,
              height: tempStyle.height,
            },
          };
        }
        return l;
      });
    });
  };

  const setDisplayType = type => {
    creatorNodesStore.setNodes(prevList => {
      return prevList.map(l => {
        if (l.id === props.id) {
          return {
            ...l,
            data: {
              ...l.data,
              displayType: type,
            },
          };
        }
        return l;
      });
    });
  };
  const clickRunBtn = async (identifier, variables) => {
    try {
      // const postParam = {
      //   identifier_type: "file",
      //   identifier_value: identifier,
      //   variables
      // }
      const postParam = await openAiParam.getCompletionsGenerationParam("file", identifier, variables, false);
      const res = await postUrlCanAbortService.postData("/be/bas-demo-v4/nlp/completions_generation", postParam);
      return res?.data?.choices?.[0]?.text || res?.data?.choices?.[0]?.message?.content || "";
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const addIconMouseEnter = async direction => {
    if (!developerMode) return;
    setCurrentHoverDirection(direction);
    if (func.isEmpty(data.textAreaValue) || data.displayType !== "inputTextarea") return;

    if (!func.isEmpty(addIconHoverItems[0].label) || addIconHoverItems[0].loading) {
      setShowLeftHoverItems(true);
      return;
    }

    setShowLeftHoverItems(true);
    setAddIconHoverItems([
      {
        label: "",
        loading: true,
      },
    ]);
    // generate/summary ai description适配
    // TODO: revisit requirement_1
    // const generateParam = {
    //   "engine": 'text-davinci-003',
    //   "frequency_penalty": 0,
    //   "max_tokens": 256,
    //   "presence_penalty": 0,
    //   "stop": [],
    //   "prompt": `You are given a query. Based on the type of problem, generate an instruction for an AI to solve the given query. For example, given a query that asks for a legal document generation, the instruction can be \"you are a legal AI that generates legal documents given a query based on the applicable laws, and explain why.\" Do not repeat the query and always ask for it to generate the reason as shown in the example. \n\nQuery:<input>`
    // }, summarizeParam = {
    //   "engine": 'text-davinci-003',
    //   "frequency_penalty": 0,
    //   "max_tokens": 15,
    //   "presence_penalty": 0,
    //   "stop": [],
    //   "prompt": `You are given an AI description. Generate the summary of the AI description in a few words. no more than 5 words.\n\nAI description:<input>\nSummary:`
    // }
    try {
      // TODO: revisist use prompt_generation for 1st step
      generateResultRef.current = await clickRunBtn("brain_studios_edge_suggestion_instruction_generation.model", {
        input: data.textAreaValue,
      });
      const summarizeResult = await clickRunBtn("brain_studios_edge_suggestion_summary_generation.model", {
        input: generateResultRef.current,
      });
      // 设置黑色悬浮item的值
      setAddIconHoverItems([
        {
          label: summarizeResult,
          loading: false,
        },
      ]);
    } catch (error) {
      console.error("generate AI description failed");
      setAddIconHoverItems([
        {
          label: "",
          loading: false,
        },
      ]);
    }
  };
  const clickHoverItem = direction => {
    // 生成一条新的edge及node
    const currentNode = creatorNodesStore.getNodes().find(x => x.id === props.id);
    addHandle.clickAddBtn(
      direction,
      {
        ...props,
        width: currentNode.style?.width || currentNode.width,
        height: currentNode.style?.height || currentNode.height,
        enterText: addIconHoverItems[0].label,
        queryValue: `${generateResultRef.current}\nInput:<input>\nOutput:`,
      },
      fotReactFlow.setCenter,
      fotReactFlow.getZoom,
      data.displayType
    );
    const { maxNodeIndex, maxEdgeIndex } = getNodeEdgeMaxIndex();

    // 显示run all按钮
    creatorEdgesStore.setQueryLoading(`edge-${maxEdgeIndex}`, "isGetQuery", true);
    // auto run
    setCommandEnterNodeId({
      nodeId: props.id,
      targetNodeId: `editor-${maxNodeIndex}`,
    });
  };
  const addIconMouseLeave = () => {
    setCurrentHoverDirection("");
    setShowLeftHoverItems(false);
  };
  const setContentFrameDataWhenValueChange = () => {
    let currContent = data.textAreaValue;
    let currType = typeFormat[data.displayType] || "";
    // 设置html type
    if (htmlReg.test(data.textAreaValue) && data.displayType === "htmlTemplate") {
      setDisplayType("htmlTemplate");
      currContent = {
        code: [
          {
            codex_generation:
              typeof data.textAreaValue !== "string" ? JSON.stringify(data.textAreaValue) : data.textAreaValue,
            notUseAnimation: true,
          },
        ],
      };
      currType = typeFormat["htmlTemplate"];
    }
    if (data.displayType !== "imageGen" && data.displayType !== "htmlTemplate") {
      setTimeout(() => {
        handleGroupStyle();
      });
    }
    setTextAreaValueWithParam({
      content: currContent,
      type: currType,
    });
  };
  const setContentFrameDataWhenTypeChange = () => {
    const valueStr = typeof data.textAreaValue !== "string" ? JSON.stringify(data.textAreaValue) : data.textAreaValue;
    // 兼容不同类型的值数据结构
    const typeContent = {
      htmlTemplate: {
        code: [
          {
            codex_generation: valueStr,
            notUseAnimation: true,
          },
        ],
      },
      stockInfo:
        data.textAreaValue instanceof Object && !Array.isArray(data.textAreaValue)
          ? data.textAreaValue
          : {
              name: "N/A",
              symbol: "N/A",
              price: "N/A",
              volume: "N/A",
              businessSummary: "N/A",
            },
      imageGen: typeof data.textAreaValue === "string" ? JsonUtil.parse(data.textAreaValue) : data.textAreaValue,
    };
    let currContent = typeContent[data.displayType] || valueStr;
    setTextAreaValueWithParam({
      content: currContent,
      type: typeFormat[data.displayType] || "",
    });
  };
  const handleGroupStyle = () => {
    // if (util.isEmpty(data.parentNodeId)) return;
    // resetGroupPosition()
  };
  const getStartEndResizeArr = resizeNode => {
    return {
      startResizeNode: resizeNodeParam.current,
      endReizeNode: {
        id: props.id,
        width: resizeNode.width,
        height: resizeNode.height,
        position: {
          x: resizeNode.x,
          y: resizeNode.y,
        },
      },
    };
  };
  const onResizeStart = debounce((e, param) => {
    setAllowFitSize(false);
    resizeNodeParam.current = {
      id: props.id,
      width: param.width,
      height: param.height,
      position: {
        x: param.x,
        y: param.y,
      },
    };
  }, 500);
  const onResizeEnd = debounce((e, param) => {
    creatorStoreMethods.onNodesResize(getStartEndResizeArr(param), setResizeEventParams);
  }, 500);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetGroupPosition = (init = false) => {
    const nodes = CustomNodeUtil.resetGroupPosition({
      init,
      disableAddPreview,
      nodeData: data,
      nodesCurrent: creatorNodesStore.getNodes(),
      id: props.id,
    });
    creatorNodesStore.setNodes(nodes);
  };

  useEffect(() => {
    if (
      !isEmpty(data.parentNodeId) &&
      creatorAISassState.groupDeleteChildNode &&
      creatorAISassState.groupDeleteChildNode.parentNodeId === data.parentNodeId
    ) {
      resetGroupPosition(true);
      creatorAISaasStore.setGroupDeleteChildNode(undefined);
    }
  }, [creatorAISassState.groupDeleteChildNode]);

  useEffect(() => {
    if (data?.parentNodeId && size.height !== 0 && size.width !== 0) {
      setTimeout(() => {
        resetGroupPosition();
      }, 100);
      // TODO Remove the other `resetGroupPosition`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size.height, size.width]);

  useEffect(() => {
    if (data.displayType === "blueprint") {
      setTimeout(() => {
        resetGroupPosition();
      }, 800);
    }
  }, []);

  const onResize = throttle((_, params) => {
    /// 目前group中不允许拖动大小
    resetGroupPosition();
    // 设置node最大可变宽高，reactflow最新版本支持noderesizer组件传maxwidth,maxheight的props
    // todo: 使用最新reactflow版本后throttle可以还原为debounce
    const size = CustomNodeUtil.setMaxNodeResize(data, params);
    setResizeEventParams(size);
    if (data.isResized) return;
    setTimeout(() => {
      creatorNodesStore.setNodes(prevList => {
        return prevList.map(x => {
          if (x.id === props.id) {
            return {
              ...x,
              data: {
                ...x.data,
                isResized: true,
              },
            };
          }
          return x;
        });
      });
    });
  }, 100);

  const changeInputContent = useCallback(e => {
    setTextAreaValueWithParam({
      content: e.target.value,
    });
  }, []);

  const clickNodeAddIcon = (handle, autoSelectFunc) => {
    if (props.id === secondInputId) {
      setCloseToolTipArr([secondInputId]);
    }
    const nodes = creatorNodesStore.getNodes();
    const edge = "new-edge-2";
    setShowTooltip(nodes.length === 1 ? edge : "close");
    setCloseToolTipArr(["editor-1"]);

    const currentNode = nodes.find(x => x.id === props.id);
    addHandle.clickAddBtn(
      handle.direction,
      {
        ...props,
        autoSelectFunc,
        width: currentNode.style?.width || currentNode.width || 0,
        height: currentNode.style?.height || currentNode.height || 0,
      },
      fotReactFlow.setCenter,
      fotReactFlow.getZoom,
      data.displayType
    );
  };
  const isV3LeftHandleConnectable = useCallback(
    handle => {
      return (
        handle.direction === "left" &&
        (!isDraggingCreatingNewLine.state || isDraggingCreatingNewLine.nodeId === props.id)
      );
    },
    [props.id, isDraggingCreatingNewLine.state, isDraggingCreatingNewLine.nodeId]
  );

  const onChangeDesignBoxShow = useCallback(open => {
    setOpenDesignBox(open);
  }, []);

  const toggleIsOpenBluePrintInfo = useCallback(bool => {
    isOpenBluePrintInfo.value = bool;
  }, []);

  /**
   * 如果是通过new onboarding模板创建的node
   * 需自动生成边
   */
  useEffect(() => {
    if (data?.chatAi) {
      const handle = HANDLES_V3.find(h => h.direction === "right");
      setTimeout(() => {
        //  新建的uploadFile位置移动到位后再执行自动点击生成边
        clickNodeAddIcon(handle, "chatInterface");
      }, 1500);
    }
    // eslint-disable-next-line
  }, [data?.chatAi]);

  // 有autoRunEdge属性，自动运行边
  useEffect(() => {
    if (data?.autoRunEdge) {
      edgeRunAll.clickRunAllBtn();
    }
  }, [data?.autoRunEdge]);

  useEffect(() => {
    let text = data.textAreaValue;
    if (typeof text === "string") {
      text = data.textAreaValue.trim();
    }

    setContentFrameDataWhenValueChange();
    if (
      !data.loadingNodeHtml &&
      data.displayType === "customJsFunction" &&
      !func.isEmpty(customJsFunctionRef?.current)
    ) {
      customJsFunctionRef?.current.injectHtml(data.textAreaValue);
    }

    const newlyVariableList = store.getState().fot.variableList;
    const newVariableList = newlyVariableList?.map(x => {
      if (x.node.id === props.id) {
        return {
          ...x,
          node: {
            ...x.node,
            data: {
              ...x.node.data,
              textAreaValue: text,
            },
          },
        };
      }
      return x;
    });
    setVariableList(newVariableList);
  }, [JSON.stringify(data.textAreaValue)]);

  useEffect(() => {
    setTimeout(() => {
      const allNode = creatorNodesStore.getNodes();
      if (introTooltipObj.show && !func.isEmpty(inputRef.current) && allNode.length === 1) {
        setIsLongPress(true);
        inputRef.current.focus();
      }
    }, 300);
  }, [introTooltipObj.show]);

  useEffect(() => {
    // 类型变化需要根据当前类型重设node resize最小宽高
    setMinWidthAndHeight(CustomNodeUtil.getMinSize(data));

    if (data.displayType === "customJsFunction" && !func.isEmpty(customJsFunctionRef?.current)) {
      customJsFunctionRef?.current.injectHtml(data.textAreaValue);
    }
    if (data.displayType === "askBrain") {
      // AskImagica初始设置为自适应高度，这里主要是因为AskImagica生成时是由textNode(textNode默认值为false)转变而来需要改变为初始设置-自适应高度
      setAllowFitSize(true);
    }
    setContentFrameDataWhenTypeChange();
    lastType.value = data.displayType;
  }, [data.displayType]);

  useEffect(() => {
    if (
      data.loadingNodeHtml &&
      data.displayType === "customJsFunction" &&
      !func.isEmpty(customJsFunctionRef?.current)
    ) {
      customJsFunctionRef?.current.injectHtml(data.textAreaValue);
    }
  }, [data]);

  useEffect(() => {
    resetGroupPosition(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableAddPreview]);

  useEffect(() => {
    setErrorText(data.errorText || "");
  }, [data.errorText]);

  useEffect(() => {
    setShowRejectTip(!func.isEmpty(errorText));
  }, [errorText]);

  /// HTML根据iframe大小适应Node
  const changeSizeByIframe = useCallback((width, height) => {
    setResizeEventParams({ width: width, height: height });
    // 不能直接清除 style 里的最大高宽，会导致外层 node 没有最大高度限制。
    // CustomNodeUtil.reductionNodeEventSize(creatorNodesStore.setNodes, props.id);
  }, []);
  /// Video 重置自适应大小
  const videoNodeSetAutoHeight = useCallback(() => {
    setResizeEventParams({ ...resizeEventParams, height: undefined });
    CustomNodeUtil.reductionNodeEventSize(creatorNodesStore.setNodes, props.id);
  }, [resizeEventParams]);

  const nodeTypeElement = useCallback(() => {
    if (
      (data.displayType === "showFoodInterface" && feature_tags.enableFood === false) ||
      (data.displayType === "map" && feature_tags.showMap === false) ||
      (data.displayType === "Agent" && feature_tags.showStudiosAgent === false)
    ) {
      return null;
    }

    const node = nodeConfig.find(n => n.displayType === data.displayType);

    const rest = {
      ...props,
      ...data,
      ...node,

      resizeEventParams,
      isOpenDeleteBox,
      feedback,
      isLongPress,
      isTargetNode,
      checkNodeArr,
      setNodes: creatorNodesStore.setNodes,
      getNodes: creatorNodesStore.getNodes,
      setEdges: creatorEdgesStore.setEdges,
      getEdges: creatorEdgesStore.getEdges,
      copyContent,
      handleGroupStyle,
      updateFeedbackLike,
      updateFeedbackDislike,
      updateFeedbackLikeCancel,
      updateFeedbackDislikeCancel,
      onTextAreaFocus,
      canvasType: "canvas",
      nodeId: props.id,
      responseData: data.textAreaValue,
      isStaticApp: isStaticApp.value,
      isInCanvas: true,
      copyIcons: `${settings.S3CDN}${settings.viewAssetsPath}Icons.png`,
      isSelected: isLongPress || checkNodeArr.includes(props.id),
    };

    switch (data.displayType) {
      case "Agent":
      case "jdShopping":
      case "weeeShopping":
      case "amazonShopping":
      case "video":
        rest.content = get(textAreaValue, [0, "results", 0, "content"], {});
        rest.videoNodeSetAutoHeight = videoNodeSetAutoHeight;
        break;

      case "showFoodInterface":
        rest.content = data.textAreaValue;
        break;

      case "stockInfo":
      case "htmlTemplate":
        rest.textAreaValue = textAreaValue;
        break;
      case "html":
        rest.changeSizeByIframe = changeSizeByIframe;
        break;

      case "customJsFunction":
        rest.ref = customJsFunctionRef;
        break;

      case "imageGen":
      case "midjourney":
      case "midjourneyV2":
      case "imageSearch":
        rest.ref = customImageNodeRef;
        break;

      case "text":
      case "json":
        const showViewBuilderButton =
          data.displayType === "text" && isTargetNode && (textAreaValue[0].results[0].content || "").length > 0;

        rest.textAreaValue = textAreaValue;
        rest.allowFitSize = allowFitSize;
        rest.typeInputRef = typeInputRef;
        rest.onTextAreaBlur = onTextAreaBlur;
        rest.onTextAreaEnter = onTextAreaEnter;
        rest.inputRef = inputRef;
        rest.setTextAreaValueWithParam = setTextAreaValueWithParam;
        rest.onUpdateNode = shareAppService.setRunAllUpdateData;
        rest.transformData = {
          ...props,
          data,
        };
        // canvas 中的节点显示 viewBuilder 需要在 customNode 中传入
        rest.enableViewBuilder = showViewBuilderButton;
        break;
      case "inputTextarea":
        rest.textAreaValue = textAreaValue;
        rest.changeInputContent = changeInputContent;
        break;
      case "blueprint":
        rest.textAreaValue = textAreaValue;
        rest.onTextAreaBlur = onTextAreaBlur;
        rest.onTextAreaEnter = onTextAreaEnter;
        rest.changeInputContent = changeInputContent;
        rest.inputRef = inputRef;
        break;
      case "askBrain":
        rest.allowFitSize = allowFitSize;
        break;
      case StoryNodeDisplayType.CHATINTERFACE:
        rest.onNodeChanges = fotReactFlow.state?.triggerNodeChanges;
        break;
      default:
        break;
    }

    const isChatBox = ["chatBox", "genUI"].includes(data.displayType);

    if (isChatBox) {
      return (
        <div className={`${style["customnode-chatbox"]}`}>
          <Nodes {...rest} />
        </div>
      );
    }

    return <Nodes {...rest} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allowFitSize,
    checkNodeArr.length,
    feedback,
    isLongPress,
    isOpenDeleteBox,
    isStaticApp.value,
    isTargetNode,

    feature_tags.enableFood,
    feature_tags.showMap,
    feature_tags.showStudiosAgent,

    props.id,
    props.isConnectable,
    props.selected,
    props.dragging,
    props.zIndex,
    data,
    resizeEventParams,
    textAreaValue,
    changeInputContent,
    copyContent,
    onTextAreaBlur,
    onTextAreaEnter,
    onTextAreaFocus,
    updateFeedbackDislike,
    updateFeedbackDislikeCancel,
    updateFeedbackLike,
    updateFeedbackLikeCancel,
  ]);

  const defaultBotBoxClassName = cls(style["default-bot-box"], "nodrag");
  const getFocusedDisplay = () => {
    // 处理右边+显示
    // 输入状态下显示最右边加号
    if (isCustomNodeInputFocus && props.id === focusNodeId) {
      return style["focused-display"];
    }
    // 如何 currentHoverDirection 为空，表示鼠标没有放在其他方向的加号上
    if (func.isEmpty(currentHoverDirection)) {
      return style["directionRight"];
    }
  };

  const secondInputId = useSelector(state => state.editor.secondInputNodeId);

  const [nodeFitSize, setNodeFitSize] = useState({});
  /**
   * 添加input node时，只有在startNode中的node才显示
   * 添加output node时，全都显示
   */
  const isShow =
    (creatorPreviewState.isAddInputNode && startNodeIds.includes(props.id)) || creatorPreviewState.isAddOutputNode;
  const isShowAddToContentToolTip =
    func.isEmpty(data.parentNodeId) &&
    creatorPreviewState.isClickAddToContent &&
    isShow &&
    CreatorNodesConsts.disablePublishDisplayType.includes(data.displayType) === false;
  const wrapperStyle = { ...(!allowFitSize ? {} : nodeFitSize), ...(data.blueprintPreviewStyle || {}) };
  // FIXME: 现在版本 customNode 不会出现在 share 页面，且 nodeDataRef 被移动到了 CanvasDataRef 中，这里暂时去掉share
  const isRejectTip = showRejectTip && data.displayType !== "Agent";
  return (
    <div
      data-testid={props.id}
      data-testpid={data.parentNodeId}
      // !!! 该属性用于 flowManger 定位真实节点
      data-flow-node-id={props.id}
      ref={customNodeRef}
      className={`${style["custom-node-content"]} ${data.displayType}-minimum ${highlightName()} ${
        !func.isEmpty(data.parentNodeId) ? "nodrag" : ""
      }`}
      style={wrapperStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={e => clickWholeNode(e)}
    >
      {func.isEmpty(data.parentNodeId) && <div className={`${style["content-hover-expander"]}`} />}

      <motion.div
        style={{
          transitionProperty: !allowFitSize ? "unset" : "all",
          transitionDuration: !allowFitSize ? "unset" : ".1s",
        }}
        className={`${getFocusedDisplay()}`}
      >
        {/*右侧添加按钮的底部白色效果*/}
        {!disableEdgesTypeArray.includes(data?.displayType) &&
          !data.showStopGenerateBtn &&
          cotStoreState.oldCotAnimationState.creating !== true && (
            <AddEdgeBackView fill={"#ffffff"} className={`${style["add-edge-back-view"]}`} />
          )}
        {/* <p>{props.id}</p> */}
        {/* 选中node高亮边框 */}
        {checkNodeArr.includes(props.id) ? (
          <div
            className={`${style["selected-highlight-border"]} ${
              data.displayType === "text" ? style["selected-highlight-border-text"] : ""
            }`}
          >
            <span className={style["top-left-block"]}></span>
            <span className={style["top-right-block"]}></span>
            <span className={style["bottom-right-block"]}></span>
            <span className={style["bottom-left-block"]}></span>
          </div>
        ) : null}

        {/* tool bar */}
        {(nodeHovered.value || data.guideShowTop) && !creatorPreviewState.isClickAddToContent ? (
          <div className={style.custom_node_toolBar_expander}>
            <div className={style.toolBar_expander_content}>
              {!introTooltipObj.show ? (
                <>
                  <NodeToolBar
                    id={props.id}
                    type={props.type}
                    data={data}
                    styl={{}}
                    textAreaChangeValue={textAreaChangeValue}
                    fitItemStatus={fitItemStatus}
                    nodeAttr={props}
                    displayType={data.displayType}
                    guideShowTop={data.guideShowTop}
                    handlePreviewDeleteNode={creatorCanvasDeleteMethods.handleDeleteSingleNodeByPreviewBtn}
                    clickToPreviewNode={creatorPreviewService.addIndividual}
                    clickNodeInfo={() => toggleIsOpenBluePrintInfo(true)}
                    onShrinkAndExpand={onShrinkAndExpand}
                    onChangeDesignBoxShow={onChangeDesignBoxShow}
                    onChangeShowBluePrintFeedbackModal={onChangeShowBluePrintFeedbackModal}
                  />
                  <BluePrintInfoModal
                    isOpen={isOpenBluePrintInfo.value}
                    name={data?.textAreaValue?.data?.name ?? data?.textAreaValue?.name ?? "Tips"}
                    description={data?.textAreaValue?.data?.description ?? data?.textAreaValue?.description ?? ""}
                    closeInfo={() => (isOpenBluePrintInfo.value = false)}
                  />
                </>
              ) : null}
            </div>
          </div>
        ) : null}
        {/* add to content tool bar */}
        {isShowAddToContentToolTip && (
          <AddToolTip
            id={props.id}
            data={data}
            handlePreviewDeleteNode={creatorCanvasDeleteMethods.handleDeleteSingleNodeByPreviewBtn}
            clickToPreviewNode={creatorPreviewService.addIndividual}
          />
        )}
        {/* Handles */}
        {HANDLES_V3.map((handle, dIdx) => {
          const disableRightHandle = disableEdgesTypeArray.includes(data.displayType) && handle.direction === "right";
          return disableRightHandle ? null : (
            <div
              key={dIdx}
              className={`${style["custom-handle-box"]} ${style["custom-handle-box-" + handle.direction]}
                  ${
                    handle.direction === "left"
                      ? style["custom-handle-box-side-target"]
                      : style["custom-handle-box-side-target-right"]
                  }
                  ${
                    handle.direction === "right" && data.parentNodeId && !nodeHovered.value
                      ? style[`custom-handle-box-right-child`]
                      : ""
                  }`}
              onClick={e => {
                if (handle.direction === "right") {
                  e?.stopPropagation();
                  clickNodeAddIcon(handle);
                }
              }}
            >
              {handle.types.map((handleType, hIdx) => {
                return (
                  <Handle
                    key={hIdx}
                    id={handleType.handleId || dIdx + handle.direction}
                    className={`${style["custom-handle"]} ${style["custom-handle-" + handle.direction]}`}
                    style={handleType.type === "source" ? { zIndex: 2 } : targetHandleStyle}
                    type={handleType.type}
                    position={Position[handle.position]}
                    isConnectable={
                      !disableEdgesTypeArray.includes(data?.displayType) &&
                      !data.showStopGenerateBtn &&
                      func.isEmpty(data.parentNodeId) &&
                      !isV3LeftHandleConnectable(handle) &&
                      !cotStoreState.oldCotAnimationState.creating
                    }
                  >
                    {/* {
                            !util.isEmpty(label) ?
                              <span>{label}</span>
                            : null
                          } */}
                  </Handle>
                );
              })}

              {!disableEdgesTypeArray.includes(data?.displayType) &&
                !data.showStopGenerateBtn &&
                handle.direction === "right" &&
                !cotStoreState.oldCotAnimationState.creating && (
                  <div
                    data-testid={`custom-node-default-bot-box-${dIdx}`}
                    className={`${defaultBotBoxClassName} ${getFocusedDisplay()}`}
                    onMouseEnter={() => addIconMouseEnter(handle.direction)}
                    onMouseLeave={() => addIconMouseLeave(handle.direction)}
                  >
                    {/* 添加icon */}
                    <span
                      data-testid={`custom-node-add-icon-${dIdx}`}
                      className={`${style["edge-add-icon"]}`}
                      // onClick={() => clickNodeAddIcon(handle)}
                    >
                      <EdgeAddIcon />
                    </span>
                    {/* 预览块 */}
                    <div className={style["preview-box"]}>
                      {/* 模拟输入框 */}
                      <div className={`${style["line-input"]} ${style[`line-input-${handle.direction}`]}`}>
                        {creatorNodesStore.getNodes().length > 1
                          ? AI_INPUT_PLACEHOLDER_MULTI_NODES
                          : AI_INPUT_PLACEHOLDER}
                      </div>
                    </div>
                    {/* 左侧加号hover的4个item */}
                    <AnimatePresence>
                      {!func.isEmpty(data.textAreaValue) &&
                      handle.direction === currentHoverDirection &&
                      showLeftHoverItems &&
                      developerMode ? (
                        <motion.div
                          className="lft-hover-list"
                          variants={container}
                          initial="init"
                          animate="show"
                          exit="hide"
                        >
                          {addIconHoverItems.map((x, index) => {
                            return (
                              <motion.div
                                key={index}
                                className="lft-hover-label"
                                variants={tradeOptionsItem}
                                onClick={() => clickHoverItem(handle.direction)}
                              >
                                {x.loading ? <ThreeBotsLoading /> : x.label}
                              </motion.div>
                            );
                          })}
                        </motion.div>
                      ) : null}
                    </AnimatePresence>
                  </div>
                )}
            </div>
          );
        })}

        <div
          ref={nodeDomRef}
          className={style["node-dom-ref-box"]}
          key={data.id}
          variants={nodeVariants}
          animate={"visible"}
          initial={"hidden"}
          style={{
            height: "100%",
          }}
        >
          <TooltipWhatTodo
            id={props.id}
            type={"node"}
            show={introTooltipObj.show}
            title={introTooltipObj.title}
            content={introTooltipObj.content}
            classNameSuffix={introTooltipObj.classNameSuffix}
            tooltipClassName={cls(style.tooltipClassName, isRejectTip ? style.tooltipRejectTip : "")}
          >
            {isRejectTip ? (
              <RejectTip
                id={props.id}
                isOpenDeleteBox={isOpenDeleteBox}
                errorText={errorText}
                setShowRejectTip={setShowRejectTip}
                retry={retryLastEdge}
              />
            ) : (
              nodeTypeElement()
            )}

            {/* cot warning 按钮 */}
            {/* {data.showWarning ? (
              <div
                className={style["custom-node-warning"]}
                onClick={() => {
                  setShowWarningModal(true);
                  logEvent("click_cot_unsupported_icon");
                }}
              >
                <img src={`${settings.S3CDN}${settings.viewAssetsPath}custom-node-warning.png`} />
              </div>
            ) : null} */}
          </TooltipWhatTodo>
        </div>

        {/* Resizer */}
        <NodeResizer
          color="transparent"
          isVisible={CustomNodeUtil.enableResize(data.displayType, props.id, checkNodeArr, data.parentNodeId)}
          minWidth={minWidthAndHeight?.width}
          minHeight={minWidthAndHeight?.height}
          onResizeStart={onResizeStart}
          onResize={onResize}
          onResizeEnd={onResizeEnd}
          keepAspectRatio={CustomNodeUtil.keepAspectRatio(data.displayType)}
          handleClassName="custom-noderesizer-handle"
          lineClassName="custom-noderesizer-line"
        />
      </motion.div>

      {showWarningModal ? (
        <UsupportedTypeModal showWarningModal={showWarningModal} setShowWarningModal={setShowWarningModal} />
      ) : null}
      {data.showGroupCollapseData && (
        <div className={style.groupCollapse}>
          <div className={style.groupCollapseInnerOne}></div>
          <div className={style.groupCollapseInnerTwo}></div>
        </div>
      )}
      {feature_tags.showDesignSpaceMenu && enableDesignSpaceFeature && openDesignBox && (
        <DesignBox
          name={data?.textAreaValue?.name}
          onCenter={() => fotReactFlow.setCenter(props.xPos + 300, props.yPos + 300, { duration: 300, zoom: 0.8 })}
          onClose={() => onChangeDesignBoxShow(false)}
        />
      )}
    </div>
  );
});
