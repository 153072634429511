export interface FotNavigatePage<PageData extends Record<string, any>> {
  readonly route: FotNavigateRoutes;

  // interface 不能定义 constructor, 这里定义一个形式的 constructor
  // constructor(readonly controller: FotNavigateController, ...args: any): void;

  /** 打开页面的方法 */
  open(openData: PageData): void;

  /** 关闭页面的方法 */
  close(): void;

  /** 该方法用于修改page 数据 */
  changePageData(pageData: PageData): void;
  getPageData(): PageData;
}

export enum FotNavigateRoutes {
  /**
   * 普通函数的页面路由
   */
  FUNCTION_NORMAL_ROUTE = "function-normal/:id",

  /**
   * js 函数的页面路由
   */
  FUNCTION_JS_ROUTE = "js-function/:id",

  /**
   * api 函数的页面路由
   */
  FUNCTION_API_ROUTE = "function/:id",
}
