import { LoadingOutlined } from "@ant-design/icons";
import useIntroTooltip from "@custom-hooks/useIntroTooltip";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { CreateFunctionPageBackMsg } from "@uikit/msg/CreateFunctionPageBackMsg";
import { FilterFarget } from "@uikit/project/HomeUsePluginData";
import { DataSyncService } from "@uikit/service/DataSyncService";
import { Button, DropdownProps, Input, InputRef, Spin } from "antd";
import { TextAreaRef } from "antd/lib/input/TextArea";
import { EdgeRunMsg } from "imagica-corekit/dist/base/msg/EdgeRunMsg";
import { NodeContentEnterMsg } from "imagica-corekit/dist/base/msg/NodeContentEnterMsg";
import { useEventbus } from "imagica-uikit/dist/hooks/useEventbus";
import { isEmpty } from "lodash";
import React, { LegacyRef, MutableRefObject, Ref, memo, useEffect, useMemo, useRef } from "react";
import { useDispatch, useStore as useReduxStore, useSelector } from "react-redux";
import { Handle, HandleType, Node, Position, useStore as useReactFlowStore } from "reactflow";
import useCustomEdgeDisabled from "../../../custom-hooks/useCustomEdgeDisabled";
import { FocusNode } from "@uikit/service/FocusNode";
import { editorActions } from "../../../store/editor";
import { fotActions } from "../../../store/fot";
import useCalculateEdgePosition from "../../../uikit/hooks/useCalculateEdgePosition";
import { useFuzzySearch } from "../../../uikit/hooks/useFuzzySearch";
import SourceOfTruth from "../../../views/components/SourceOfTruth";
import { TooltipWhatTodo } from "../../../views/components/TooltipWhatTodo";
import { CustomDropdownV2 } from "../../../views/thinking-layout-editor/CustomDropdownV2";
import { AI_INPUT_PLACEHOLDER, FUNCTION_REG } from "../../../views/thinking-layout-editor/constants";
import { ChatAIStore } from "../ChatAI/ChatAIStore";
import { CommandBar } from "../CommandBar";
import { PreviewAppUtil } from "../PreviewApp/PreviewAppUtil";
import { BlueprintMessagePanel } from "./BlueprintMessagePanel";
import style from "./CustomNewEdge.module.scss";
import { CustomNewEdgeBloc } from "./CustomNewEdgeBloc";
import { CustomNewEdgeUtil } from "./CustomNewEdgeUtil";
import { CustomEedgProvider, useCustomEdgeContainer } from "./container";
import { useCreation } from "ahooks";
import { GenerativeFunctionBloc } from "../BluePrint/GenerativeFunctionBloc";
import { BlueprintBuildMsg } from "imagica-corekit/dist/base/msg/BlueprintBuildMsg";
import isBlank from "@sedan-utils/is-blank";
import { Popup } from "../BluePrint/Popup";
import { UnsupportedBlueprintFlowModal } from "../BluePrint/UnsupportedBlueprintFlowModal/UnsupportedBlueprintFlowModal";
import { ExecuteBlueprintUtil, ExecuteProps } from "@uikit/util/ExecuteBlueprintUtil";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { BlueprintInfo } from "imagica-corekit/dist/base/api/blueprintTyped/blueprintInfo";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { useNavigate } from "react-router-dom";
import { StudioProjectAttributesV2EdgeData } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV2Edge";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { FotReactFlow } from "@uikit/model/FotReactFlow";

type PositionStr = keyof typeof Position;
interface HandleData {
  direction: string;
  position: PositionStr;
  types: HandleTypeData[];
}
interface HandleTypeData {
  type: HandleType;
  handleId?: string;
}

const handles: HandleData[] = [
  {
    direction: "top",
    position: "Top",
    types: [{ type: "source" }, { type: "target" }],
  },
  {
    direction: "right",
    position: "Right",
    types: [
      { type: "source", handleId: "b" },
      { type: "target", handleId: "b" },
    ],
  },
  {
    direction: "bottom",
    position: "Bottom",
    types: [{ type: "source" }, { type: "target" }],
  },
  {
    direction: "left",
    position: "Left",
    types: [
      { type: "source", handleId: "a" },
      { type: "target", handleId: "a" },
    ],
  },
];

const ChildPage = (props: any): JSX.Element => {
  const { data, id, bloc } = props;
  const { middlePostion } = useCalculateEdgePosition(data, id);
  const creatorNodesStore = getIt(CreatorNodesStore);
  useEffect(() => {
    const nodes = creatorNodesStore.getNodes();

    const currentEdge = nodes.find((node: any) => node?.id === props.id);
    const currentPosition = bloc.getPosition(middlePostion, currentEdge);
    // 确保中涂渲染不能 NaN
    if (
      isNaN(middlePostion.x) ||
      isNaN(middlePostion.y) ||
      (currentEdge?.position?.x === currentPosition.x && currentEdge?.position?.y === currentPosition.y)
    )
      return;
    bloc.handleEdgePositionEffect(middlePostion);
    // });
  });

  return <></>;
};
const connectionNodeIdSelector = (state: any): any => state.connectionNodeId;
const creatorSaasAppStore = getIt(CreatorSaasAppStore);

const CustomNewEdge = ({ data, ...props }: CustomEdge.CustomNewEdgeProps): JSX.Element => {
  const store = useReduxStore() as any;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const creatorStoreMethods = getIt(CreatorStoreMethods);
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const canvasDataRef = getIt(CanvasDataRef);
  const fotReactFlow = getIt(FotReactFlow);
  const homeStore = getIt(HomeStore);
  const homeStoreState = useStore(homeStore).value;
  const feature_tags = homeStoreState.featureTags;

  const { id } = props;
  const connectionNodeId = useReactFlowStore(connectionNodeIdSelector);
  const isTarget = connectionNodeId && connectionNodeId !== id;
  const targetHandleStyle = { zIndex: isTarget ? 3 : 1 };

  const isEdgeSelected = useRef(false);
  const isEdgeClicked = useRef(false);
  const prevAiDescription = useRef("");
  const prevEdgeData: MutableRefObject<StudioProjectAttributesV2EdgeData> = useRef({});
  const commandInputRef: MutableRefObject<InputRef | undefined> = useRef();
  const aiDescriptionSpanRef: MutableRefObject<HTMLSpanElement | undefined> = useRef();
  const edgeTextRef: MutableRefObject<HTMLParagraphElement | undefined> = useRef();
  const edgeBoxRef: MutableRefObject<HTMLDivElement | undefined> = useRef();

  const developerMode = useSelector((state: any) => state.fot.developerMode);
  const imageGenSelectOptions = useSelector((state: any) => state.fot.imageGenSelectOptions);
  const needDisableEdgeIds = useSelector((state: any) => state.editor.needDisableEdgeIds);
  const enableToolboxFeature = useSelector((state: any) => state.fot.enableToolboxFeature);
  const hoverEdgeData = useSelector((state: any) => state.editor.hoverEdgeData);
  const isDraggingCreatingNewLine = useSelector((state: any) => state.editor.isDraggingCreatingNewLine);
  const selectedTemplate = useSelector((state: any) => state.studio.selectedTemplate);
  const imagicaStudioTutorial = useSelector((state: any) => state.fot.imagicaStudioTutorial);
  const createAPIFuncData = useSelector((state: any) => state.editor.createAPIFuncData);
  const createJSFuncData = useSelector((state: any) => state.editor.createJSFuncData);
  const checkEdgeArr = useSelector((state: any) => state.fot.checkEdgeArr);
  const currentModalLineParamStr = useSelector((state: any) => state.editor.currentModalLineParamStr);

  const commandEnterNodeLineParam = useSelector((state: any) => state.editor.commandEnterNodeLineParam);
  const setCommandEnterNodeLineParam = (val: any): void => {
    dispatch(editorActions.setCommandEnterNodeLineParam(val));
  };

  const isEdgeRunning = useSelector((state: any) => state.editor.isEdgeRunning);
  const setIsEdgeRunning = (val: any): void => {
    dispatch(editorActions.setIsEdgeRunning(val));
  };

  const selectIdArr = useSelector((state: any) => state.fot.selectIdArr);

  const createFunctionClick = useSelector((state: any) => state.fot.createFunctionClick);
  const setCreateFunctionClick = (val: any): void => {
    dispatch(fotActions.setCreateFunctionClick(val));
  };
  const { introTooltipObj } = useIntroTooltip(id, null, data.edgeState, props.sourcePosition);
  const chatAIStore = getIt(ChatAIStore);
  const container = useCustomEdgeContainer();
  const { edgeContainer } = container;
  const edgeStoreState = useStore(edgeContainer.store).value;

  const focusNode = getIt(FocusNode);
  const { disabledRun } = useCustomEdgeDisabled({
    id,
    data,
    sourceNode: undefined,
    sourceNodeId: data?.flows?.[0]?.sourceNodeId,
  });
  const bloc = useCreation(
    () =>
      new CustomNewEdgeBloc({
        store,
        dispatch,
        deleteElements: fotReactFlow.deleteElements,
        navigate,
        edgeContainer: edgeContainer,
      }),
    []
  );

  bloc.setExtraProps({
    sourceNodeId: data?.flows?.[0]?.sourceNodeId,
    isEdgeSelected,
    isEdgeClicked,
    prevAiDescription,
    prevEdgeData,
    commandInputRef,
    aiDescriptionSpanRef,
    edgeTextRef,
    edgeBoxRef,
    edgeData: data,
    edgeProps: props as unknown as any,
    container,
    disabledRun,
    createFunctionClick,
    imageGenSelectOptions,
    hoverEdgeData,
    isDraggingCreatingNewLine,
    selectedTemplate,
    imagicaStudioTutorial,
    needDisableEdgeIds,
    createAPIFuncData,
    createJSFuncData,
    enableToolboxFeature,
    checkEdgeArr,
    currentModalLineParamStr,
    getHomeNodesFunc: creatorNodesStore.getNodes,
    setCreateFunctionClick,
    focusEdgeById: focusNode.focusEdgeById,
    onNodeContentChange: creatorStoreMethods.onNodeContentChange,
    setEdgeLineParam: creatorStoreMethods.setEdgeLineParam,
  });

  // FIXME:nodesRef  GenerativeFunctionBloc use nodesRef
  const nodesRef: {
    readonly current: Node<any>[];
  } = useMemo(() => {
    return {
      get current(): Node<any>[] {
        return creatorNodesStore.getNodes();
      },
    };
  }, []);
  /// It must be written here because it keeps the data when opening the dropdown again
  const generativeFunctionBloc: GenerativeFunctionBloc = useCreation(
    // use creatorNodesStore
    () => new GenerativeFunctionBloc(nodesRef),
    []
  );
  const generativeFunctionBlocV2 = useCreation(
    // use creatorNodesStore
    () => new GenerativeFunctionBloc(nodesRef),
    []
  );

  const executeBlueprint = (blueprintInfo: BlueprintInfo): void => {
    const executeProps: ExecuteProps = {
      id: props.id,
      aiDescription: bloc.extraProps.container.aiDescriptionSingle.value,
      blueprintInfo,
      nodeIndexRef: creatorRefStore.nodeIndexRef,
      edgeIndexRef: creatorRefStore.edgeIndexRef,
      newLineDataRef: canvasDataRef.newLineDataRef,
      nodeDataRef: canvasDataRef.nodeDataRef,
      nodes: creatorNodesStore.getNodes(),
      edges: creatorEdgesStore.getEdges(),
    };
    ExecuteBlueprintUtil.execute(executeProps, value => {
      bloc.clickToSelectValue(value);
    });
  };

  const handleClickRunBtn: React.MouseEventHandler<HTMLElement> = e => {
    clearTimeout(runTimeOut.current);
    runTimeOut.current = setTimeout(() => {
      const blueprintInfo = JsonUtil.toModelFromType(BlueprintInfo, bloc.extraProps.edgeData.lineParam.blueJson);
      if (blueprintInfo) {
        executeBlueprint(blueprintInfo);
      } else {
        bloc.clickRunBtn(e, true, undefined);
      }
    }, DataSyncService.timeoutNeedNodeDataUpdate) as any;
  };

  useEventbus(BlueprintBuildMsg, msg => {
    if (data?.targetNodeId === msg.targetNodeId) {
      const blueprints = msg.blueprints;
      if (isBlank(blueprints) === false) {
        generativeFunctionBloc.state.blueprints.value = blueprints;
        const blueprint = blueprints[0];

        if (blueprint.unsupported === true) {
          const popup = new Popup(UnsupportedBlueprintFlowModal);
          return popup.open({
            onOk: () => {
              executeBlueprint(blueprint);
            },
            missing_apis: blueprint.missing_apis,
          });
        }
        executeBlueprint(blueprint);
      }
    }
  });

  useFuzzySearch(
    false,
    bloc.extraProps.container.aiDescriptionSingle.value,
    bloc.fuzzyData,
    (filterCompletedArray: FilterFarget[]): any => {
      bloc.handleFuzzySearch.call(bloc, filterCompletedArray);
    },
    enableToolboxFeature
  );

  //  这条边node如果存在autoSelectFunc，则自动选择对应的func，但不立即执行
  useEffect(() => {
    if (data?.autoSelectFunc) {
      bloc.handleValueChange("/");
      const func = bloc.extraProps.container.apiArrSignale.value?.find(
        (item: any) => item.value === data.autoSelectFunc
      );
      bloc.clickToSelectValue(func);
      edgeContainer.openCommandbar();
    }
  }, [data?.autoSelectFunc]);

  useEffect(() => {
    if (feature_tags.showSuggestionFunctions) {
      setTimeout(() => {
        bloc.updateSourceNodeContent();
        /// TextNode 失焦之后有 300ms 延时，详见 CustomNode.onTextAreaBlur() 方法
      }, DataSyncService.timeoutNeedNodeDataUpdate);
    }
  }, [feature_tags.showSuggestionFunctions]);

  useEffect(() => {
    if (feature_tags.showSuggestionFunctions) {
      bloc.getSuggestedQueryAndFunctions();
    }
  }, [bloc.state.value.sourceNodeContent, feature_tags.showSuggestionFunctions]);

  useEffect(() => {
    if (!edgeStoreState.showCommandbar && edgeStoreState.edgeDisplayType === "input") {
      edgeContainer.openCommandbar();
    }
  }, [bloc.state.value.suggestedFunctions, bloc.state.value.suggestedQueries]);

  useEffect(() => {
    bloc.handleDeveloperModeEffect();
  }, [developerMode]);

  useEffect(() => {
    bloc.initParam();
    return (): void => {
      bloc.onComponetDestory();
    };
  }, []);

  useEffect(() => {
    bloc.handleCreateFunctionClickEffect();
  }, [createFunctionClick]);

  //应该初始化的时候执行，解决切换project，没有执行，暂时监听data
  useEffect(() => {
    bloc.handleDataAttrEffect();
    // eslint-disable-next-line
  }, [
    data.isSliceAutoRun,
    data.isFromSaveData,
    //FIXME:opt, 一直调用setNodes
    data?.lineParam?.enterText,
    imageGenSelectOptions.length,
  ]);

  useEffect(() => {
    edgeContainer.commandbar.initDisplayType(data.isGetQuery, data.getQueryLoading);
  }, [data.isGetQuery, data.getQueryLoading]);

  useEffect(() => {
    bloc.handleEdgeHoverEffect();
  }, [
    bloc.extraProps.hoverEdgeData.state,
    bloc.extraProps.hoverEdgeData.edgeId,
    bloc.extraProps.hoverEdgeData.isSelected,
  ]);

  useEffect(() => {
    bloc.handleCurrentModalLineParamStrEffect();
  }, [bloc.extraProps.currentModalLineParamStr]);

  useEffect(() => {
    if (func.isEmpty(commandEnterNodeLineParam)) return;
    bloc
      .clickRunBtn(undefined, false, {
        edgeId: commandEnterNodeLineParam.id,
        type: "lineType",
        lineParam: commandEnterNodeLineParam,
      })
      .finally(() => {
        setCommandEnterNodeLineParam({});
      });
  }, [commandEnterNodeLineParam]);

  const sourceId = useMemo(() => CustomNewEdgeUtil.getSourceNodeId(bloc.extraProps.edgeData), []);
  const sourceNodeIds = data?.flows?.map((x: any) => x.sourceNodeId) || [sourceId];
  useEffect(() => {
    const commandInputRef = bloc.extraProps.container.commandInputRef?.current as any;
    if (!commandInputRef) {
      return;
    }
    const handleEscape = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
      if (e.code === "Escape") {
        bloc.handleEscape();
      }
    };
    commandInputRef.addEventListener("keydown", handleEscape);
    return (): any => {
      return commandInputRef.removeEventListener("keydown", handleEscape);
    };
  }, [bloc.extraProps.container.commandInputRef]);

  useEffect(() => {
    //如果没有chat ai app，或者chat ai app里没有output || 和当前边没有关系
    if (func.isEmpty(chatAIStore.state.chatNodePreEdgeId) || !chatAIStore.state.chatNodePreEdgeId.includes(props.id))
      return;
    //chat前面的边改为了其他的内容，需要删除chat ai里对应的output
    if (data.lineParam.enterText !== "/Chat Interface") {
      func.messageUtil("The previous target node that is in your chat ai app will be deleted", "info");
      PreviewAppUtil.deleteChatAiOutput({
        edgeId: props.id,
        saasUIData: creatorSaasAppStore.state.saasUIData,
        previewAppList: previewStore.state.previewAppList,
        nodes: creatorNodesStore.getNodes(),
        setSaasUIData: creatorSaasAppStore.setSaasUIData,
        // bsf-4912 use setSassUIData
        replacePreviewAppList: previewStore.setPreviewAppList,
      });
    }
  }, [data.lineParam.enterText]);
  // 收到手动触发edge run的消息
  useEventbus(EdgeRunMsg, msg => {
    if (msg.edgeId !== props.id) {
      return;
    }
    const ableRun = bloc.checkSourceNotEmpty();
    const callBack = msg.runResultCall;
    if (callBack) {
      callBack(ableRun);
    }
    bloc.clickRunBtn(undefined, msg.isManualRun, undefined);
  });

  /// 收到node键入command enter的消息
  useEventbus(NodeContentEnterMsg, async msg => {
    if (!sourceNodeIds.includes(msg.nodeId)) {
      return;
    }
    if (isEdgeRunning) {
      return;
    }
    setIsEdgeRunning(true);
    await bloc.clickRunBtn(undefined, true, undefined);
    setIsEdgeRunning(false);
  });
  useEventbus(CreateFunctionPageBackMsg, msg => {
    bloc.handleCreateFunctionPageBackMsg(msg);
  });

  // 正在手动连线 && 不是/开头的自定义function && 不是已经连上起点node的边
  const isDraggingNewLineHoveredThis =
    bloc.extraProps.isDraggingCreatingNewLine.state &&
    !FUNCTION_REG.test(data.lineParam.enterText) &&
    !sourceNodeIds.includes(bloc.extraProps.isDraggingCreatingNewLine.nodeId);

  const runTimeOut = useRef(0);

  const flowLineDoubleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    bloc.doubleClickLine(e);
  };

  return (
    <div
      className={`nodrag ${bloc.edgeHidden() ? style["edge-hidden"] : null}`}
      onMouseEnter={() => bloc.onMouseEnterEdge()}
      onMouseLeave={() => bloc.onMouseLeaveEdge()}
    >
      {/* 将主动触发重新渲染的hook放入子组件，避免多次刷新customnewedge */}
      <ChildPage bloc={bloc} data={data} id={id} />
      {handles.map((handle, dIdx) => {
        return (
          <div key={dIdx} className={`${style["edge-handle-box"]} ${style["edge-handle-box-" + handle.direction]}`}>
            {handle.types.map((handleType: HandleTypeData, hIdx) => {
              return (
                <Handle
                  className={`${style["edge-handle"]} ${
                    isTarget && isDraggingNewLineHoveredThis
                      ? handle.direction === "left"
                        ? `${style["custom-edge-handle-drop-left"]} ${style["custom-edge-handle-drop-left-draggingNewLine_hover"]}`
                        : style["custom-edge-handle-drop"]
                      : null
                  }`}
                  key={hIdx}
                  id={handleType?.handleId || dIdx + handle.direction}
                  style={handleType.type === "source" ? { zIndex: 2 } : targetHandleStyle}
                  type={handleType.type}
                  position={Position[handle.position]}
                  isConnectable={true}
                ></Handle>
              );
            })}
          </div>
        );
      })}
      <div className={style["custom-new-edge"]} ref={bloc.extraProps.edgeBoxRef as LegacyRef<HTMLDivElement>}>
        {
          // !data.isCreatedBySlice && sameTargetEdgeIds.includes(id) ?
          !data.isCreatedBySlice ? (
            // todo: 暂时屏蔽Tooltip相关
            <TooltipWhatTodo
              id={id}
              type={"edge"}
              show={introTooltipObj.show && edgeStoreState.edgeDisplayType === "input"}
              title={introTooltipObj.title}
              content={introTooltipObj.content}
              classNameSuffix={introTooltipObj.classNameSuffix}
              overlayStyle={{}}
              trigger={""}
              placement={""}
              tooltipClassName={style.tooltipClassName}
            >
              <div
                // style={getRenderBoxStyl()}
                className={bloc.getCustomEdgeClass()}
                data-testid={id}
              >
                {/* AI description输入框 */}
                {edgeStoreState.edgeDisplayType === "input" ? (
                  <>
                    <span
                      className={style["ai-text-span"]}
                      ref={bloc.extraProps.aiDescriptionSpanRef as LegacyRef<HTMLSpanElement>}
                    >
                      {bloc.extraProps.container.aiDescriptionSingle.value}
                    </span>
                    <div
                      // !!! 该属性用于 flowManger 定位真实节点
                      data-flow-edge-id={props.id}
                      id={"commandbar"}
                      className={style["ai-textArea-wrap"]}
                      // style={bloc.textAreaWrapStyl.value}  BSF-6075 输入框内容过长时，双击边框，tooltip会和输入框重叠
                    >
                      <CommandBar.Dropdown
                        open={edgeStoreState.showCommandbar}
                        dropdownAlign={bloc.state.value.dropdownAlign as DropdownProps["align"]}
                        overlay={
                          <CustomDropdownV2
                            id={props.id}
                            generativeFunctionBloc={generativeFunctionBloc}
                            generativeFunctionBlocV2={generativeFunctionBlocV2}
                            updateApiArr={() => bloc.updateApiArr()}
                            apiArr={bloc.extraProps.container.apiArrSignale.value}
                            suggestedLoading={bloc.state.value.suggestedLoading}
                            suggestedFunctions={bloc.state.value.suggestedFunctions}
                            suggestedQueries={bloc.state.value.suggestedQueries}
                            aiDescription={bloc.extraProps.container.aiDescriptionSingle.value}
                            disableHover={bloc.extraProps.container.disableHoverSignal.value}
                            onCommandKeyDown={(e: any) => bloc.onCommandKeyDown(e)}
                            searchFunc={bloc.extraProps.container.searchFuncSignal.value}
                            selectedBlueprintIds={bloc.extraProps.container.selectedBlueprintsSingle.value}
                            handleValueChange={(value: any) => bloc.handleValueChange(value)}
                            clickToSelectValue={(value: any) => bloc.clickToSelectValue(value)}
                            clickToHandlePromptGeneration={(value: string) => bloc.clickToHandlePromptGeneration(value)}
                            showGenerateFunction={CustomNewEdgeUtil.isNormalEdgeByEdgeText(
                              bloc.extraProps.container.aiDescriptionSingle.value
                            )}
                            clickCreateFunction={(item: any) => bloc.clickCreateFunction(item)}
                            onHideDropdown={(e: any) => bloc.outClickBindObj.onOutClick(e)}
                            // from:hzh stud-1281
                            serchFunDescription={bloc.extraProps.container.searchFuncSignal.value}
                            sourceNodeDisplayType={CustomNewEdgeUtil.getSourceNodeDisplayType(
                              bloc.extraProps.edgeData,
                              creatorNodesStore.getNodes
                            )}
                            subSourceNodeDisplayType={CustomNewEdgeUtil.getSubSourceNodeDisplayType(
                              bloc.extraProps.edgeData,
                              creatorNodesStore.getNodes
                            )}
                            inputOnRef={(inputRef: any) => {
                              bloc.extraProps.container.commandInputRef = inputRef;
                            }}
                          />
                        }
                      >
                        <Input.TextArea
                          className={`nowheel ${style["ai-textArea"]}`}
                          ref={bloc.extraProps.container.aiDescriptionInputRef as Ref<TextAreaRef>}
                          style={{
                            width: bloc.state.value.aiDescriptionWidth + "px",
                          }}
                          onClick={() => bloc.disableZoomAndPan()}
                          autoSize={{ minRows: 1, maxRows: 9 }}
                          value={bloc.extraProps.container.aiDescriptionSingle.value}
                          onChange={e => bloc.onAiDescriptionChange(e)}
                          onPressEnter={e => bloc.onPressEnter(e)}
                          onFocus={e => bloc.onFocusInput(e)}
                          onBlur={() => bloc.handleInputBlur()}
                          placeholder={AI_INPUT_PLACEHOLDER}
                          onKeyDown={e => {
                            if (e.code === "Escape") {
                              bloc.handleEscape();
                            }
                          }}
                        />
                      </CommandBar.Dropdown>
                    </div>
                  </>
                ) : null}
                {/* loading */}
                {edgeStoreState.edgeDisplayType === "generating" ? (
                  <Spin
                    // !!! 该属性用于 flowManger 定位真实节点
                    data-flow-edge-id={props.id}
                  />
                ) : null}

                {/* run按钮及弹窗 */}
                {edgeStoreState.edgeDisplayType === "run" ? (
                  <>
                    <div
                      // !!! 该属性用于 flowManger 定位真实节点
                      data-flow-edge-id={props.id}
                      className={style["line-button-box"]}
                    >
                      <div className={style["line-background"]} style={bloc.state.value.lineBackgroundStyl}></div>
                      <BlueprintMessagePanel
                        id={props.id}
                        blueprintMessages={bloc.extraProps.edgeData.blueprintMessages}
                        loading={data.lineParam.loading}
                      />
                      <Button
                        data-testid="EdgeRunButton"
                        className={`${style["flow-line-button"]} ${
                          isDraggingNewLineHoveredThis ? style["flow-line-button-draggingNewLine_hover"] : ""
                        }`}
                        onClick={handleClickRunBtn}
                        type="primary"
                        shape="round"
                        disabled={disabledRun || needDisableEdgeIds.includes(id)}
                      >
                        {data.lineParam.loading ? (
                          <>
                            <LoadingOutlined
                              style={{
                                marginRight: "5px",
                                verticalAlign: "baseline",
                              }}
                              spin
                            />
                            <span>Stop</span>
                          </>
                        ) : (
                          <span>Run</span>
                        )}
                      </Button>
                      <p
                        ref={bloc.extraProps.edgeTextRef as LegacyRef<HTMLParagraphElement>}
                        // style={{
                        //   width: bloc.aiDescriptionWidth.value + 'px',
                        // }}
                        className={`${style["flow-line-p"]} ${
                          bloc.extraProps.hoverEdgeData.state ? style["all-line-text"] : ""
                        } ${!isEmpty(selectIdArr) ? style["not-optional"] : ""}`}
                        onClick={() => bloc.clickLine()}
                        onDoubleClick={flowLineDoubleClick}
                      >
                        {data.lineParam.enterText}
                      </p>
                    </div>
                    {data.lineParam.enterText === "/Add Data Source" ? (
                      <SourceOfTruth
                        setEdgeLineParam={(params: any) => bloc.childSetEdgeLineParam(params)}
                        lineParam={data.lineParam}
                      />
                    ) : null}
                  </>
                ) : null}
              </div>
            </TooltipWhatTodo>
          ) : null
        }
      </div>
    </div>
  );
};

const CustomNewEdgePrvoder = memo((props: CustomEdge.CustomNewEdgeProps) => {
  const _props = { ...props, isV3: true };
  return (
    <CustomEedgProvider initialState={_props}>
      <CustomNewEdge {..._props} />
    </CustomEedgProvider>
  );
});

export default CustomNewEdgePrvoder;
