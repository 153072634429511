import { useEffect } from "react";
import type { MenuProps } from "antd";
import { Dropdown, Menu } from "antd";
import classNames from "classnames";
import style from "./FontStyleCp.module.scss";
import { useComputed, useSignal } from "@preact/signals-react";
import { TOOLBAR_CONFIG, ToolBarConfigKeyType } from "../PreviewV2ToolBarUtil";
import func, { matchMobile } from "@uikit/func";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { CreatorAISaasStore } from "@uikit/store/CreatorAISaasStore";
import { getIt } from "@uikit/getIt";
import { logEvent } from "@uikit/service/amplitude";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";

const creatorAISaasStore = getIt(CreatorAISaasStore);

export function FontStyleCp(props: any): JSX.Element {
  // Dropdown list
  const menu = (
    <Menu
      items={[
        {
          key: "fontTitle",
          label: <p>Title</p>,
          style: {
            fontFamily: "ak-regular",
            fontWeight: 700,
            fontSize: "36px",
            lineHeight: "40px",
          },
          onClick: onItemSelected,
        },
        {
          key: "fontSub",
          label: <p>Sub</p>,
          style: {
            fontFamily: "ak-regular",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "25px",
          },
          onClick: onItemSelected,
        },
        {
          key: "fontLabel",
          label: <p>Label</p>,
          style: {
            fontFamily: "ak-regular",
            fontSize: "16px",
            lineHeight: "30px",
            fontWeight: 800,
          },
          onClick: onItemSelected,
        },
        {
          key: "fontBody",
          label: <p>Body</p>,
          style: {
            fontFamily: "ak-regular",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "25px",
          },
          onClick: onItemSelected,
        },
      ]}
    />
  );

  const items: MenuProps["items"] = [
    {
      label: "Title", // toolbar font style文本
      key: "fontTitle",
      style: {
        fontFamily: "ak-regular",
        fontWeight: 700,
        fontSize: "36px",
        lineHeight: "40px",
      },
    },
    {
      label: "Sub",
      key: "fontSub",
      style: {
        fontFamily: "ak-regular",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "25px",
      },
    },
    {
      label: "Label",
      key: "fontLabel",
      style: {
        fontFamily: "ak-regular",
        fontWeight: 800,
        fontSize: "16px",
        lineHeight: "30px",
      },
    },
    {
      label: "Body",
      key: "fontBody",
      style: {
        fontFamily: "ak-regular",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "25px",
      },
    },
  ];

  // const { previewHoverData } = useAISaasContainer()
  const creatorAISassState = useImagicaStore(creatorAISaasStore).value;
  const previewHoverData = creatorAISassState.aiSaasPreviewHoverData;
  const hoverType = previewHoverData.type;
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const featureTags = homeStore.state.featureTags;

  const currentFontItem = useSignal(
    items.find((x: any) => {
      const fontStyleKey = TOOLBAR_CONFIG[hoverType as ToolBarConfigKeyType]?.fontStyleKey || "fontTitle";
      return x.key === fontStyleKey;
    })
  );
  const currentFontLabel = useComputed(() => {
    return (currentFontItem as any).value?.label || "";
  });
  const setSaasBasicData = (params: AISaas.PreviewFontStyleSelectParam): void => {
    const keyMap: {
      [key: string]: any;
    } = {
      title: {
        oldKey: "title",
        newKey: "titleV3",
      },
      chatTitle: {
        oldKey: "title",
        newKey: "titleV3",
      },
      description: {
        oldKey: "subTitle",
        newKey: "subTitleV3",
      },
    };
    const currOldKey = keyMap[hoverType]?.oldKey;
    const currNewKey = keyMap[hoverType]?.newKey;

    props.setSaasUIData((prevData: any) => {
      if (typeof prevData[currOldKey] === "string") {
        return Object.assign({}, prevData, {
          [currNewKey]: {
            [currOldKey]: prevData[currOldKey],
            fontStyleKey: params.key,
            style: items.find((x: any) => x.key === params.key)?.style || {},
          },
        });
      }
      return prevData;
    });
  };
  const setInputNodeLabelUIData = (params: AISaas.PreviewFontStyleSelectParam, type: "node" | "label"): void => {
    props.setSaasUIData((prevData: any) => {
      const newInputs = prevData.input.map((i: any) => {
        if (i.id === previewHoverData.id) {
          return {
            ...i,
            data: {
              ...i.data,
              fontStyleKey: params.key,
              [`${type}FontStyle`]: items.find((x: any) => x.key === params.key)?.style || {},
            },
          };
        }
        return i;
      });
      return {
        ...prevData,
        input: newInputs,
      };
    });
  };
  const setTextOutputNodeUIData = (params: AISaas.PreviewFontStyleSelectParam): void => {
    const styleKey = params.key;
    const isBold = ["fontTitle", "fontLabel"].includes(styleKey);

    props.setSaasUIData((prevData: any) => {
      const newOutputs = prevData.output.map((i: any) => {
        const currentId = props.isGroup ? i.groupId : i.results[0].previewId;
        if (currentId === previewHoverData.id) {
          return {
            ...i,
            results: [
              {
                ...i.results[0],
                isBold,
                fontStyleKey: styleKey,
                nodeFontStyle: {
                  ...(i.results[0].nodeFontStyle || {}),
                  ...(items.find((x: any) => x.key === styleKey)?.style || {}),
                },
              },
            ],
          };
        }
        return i;
      });
      return {
        ...prevData,
        output: newOutputs,
      };
    });
  };
  function onItemSelected(params: any): void {
    // 修改数据
    const setDataConfig: {
      [key: string]: (param: AISaas.PreviewFontStyleSelectParam) => void;
    } = {
      title: setSaasBasicData,
      chatTitle: setSaasBasicData,
      description: setSaasBasicData,
      inputNodeLabel: params => {
        setInputNodeLabelUIData(params, "label");
      },
      inputField: params => {
        setInputNodeLabelUIData(params, "node");
      },
      input: params => {
        setInputNodeLabelUIData(params, "label");
      },
      textOutputNode: setTextOutputNodeUIData,
      askImagica: setTextOutputNodeUIData,
      image: setTextOutputNodeUIData,
    };
    setDataConfig[hoverType]?.(params);

    const key = params.key;
    if (key) {
      logClickEvent(key);
    }
  }

  const getSaasBasicStyleKey = (type: "titleV3" | "subTitleV3"): any => {
    const keyMap = {
      titleV3: "title",
      subTitleV3: "description",
    };
    // 数据没值则使用配置的默认值
    return props.saasUIData?.[type]?.fontStyleKey || TOOLBAR_CONFIG[keyMap[type] as ToolBarConfigKeyType].fontStyleKey;
  };
  const getInputNodeLabelStyleKey = (): string => {
    const currentInput = props.saasUIData.input.find((x: any) => x.id === previewHoverData.id);
    // 数据没值则使用配置的默认值
    return currentInput?.data?.fontStyleKey || TOOLBAR_CONFIG["inputNodeLabel"].fontStyleKey;
  };
  const getTextOutputNodeStyleKey = (key: ToolBarConfigKeyType = "textOutputNode"): string => {
    const currentOutput = props.saasUIData.output.find((x: any) => {
      const currentId = props.isGroup ? x.groupId : x.results[0].previewId;
      return currentId === previewHoverData.id;
    });
    // 数据没值则使用配置的默认值
    return currentOutput?.results?.[0]?.fontStyleKey || TOOLBAR_CONFIG[key].fontStyleKey;
  };
  const setFontItemByHoverData = (): void => {
    const keyConfig: {
      [key: string]: () => string;
    } = {
      title: () => {
        return getSaasBasicStyleKey("titleV3");
      },
      chatTitle: () => {
        return getSaasBasicStyleKey("titleV3");
      },
      description: () => {
        return getSaasBasicStyleKey("subTitleV3");
      },
      inputNodeLabel: getInputNodeLabelStyleKey,
      inputField: getInputNodeLabelStyleKey,
      input: getInputNodeLabelStyleKey,
      textOutputNode: getTextOutputNodeStyleKey,
      askImagica: getTextOutputNodeStyleKey.bind(null, "askImagica"),
      image: getTextOutputNodeStyleKey.bind(null, "image"),
    };
    const key = keyConfig[hoverType]?.();
    if (func.isEmpty(key)) return;
    currentFontItem.value = items.find((x: any) => x.key === key);
  };

  useEffect(() => {
    // 只应当在初始或修改saasuidata时执行
    setFontItemByHoverData();
  }, [props.saasUIData?.titleV3, props.saasUIData?.subTitleV3, props.saasUIData?.input, props.saasUIData?.output]);

  useEffect(() => {
    // 只应当在hover切换元素时执行
    if (!previewHoverData.show) return;
    setFontItemByHoverData();
  }, [previewHoverData.show]);

  const logClickEvent = (title: string): void => {
    logEvent("click_preview_toolbar_button", { button_name: title });
  };

  const isMobileFlag = isMobile && featureTags.disableMobilePage;

  return (
    <Dropdown
      disabled={props.disable}
      overlay={menu}
      trigger={["click"]}
      // getPopupContainer={() => document.getElementById("preview-toollbar-box")!}
      getPopupContainer={e => e}
      overlayClassName="font-style-dropdown"
    >
      <div
        className={classNames(
          style["cp-box"],
          { [style.disable]: props.disable, [style["cp-box-mobile"]]: isMobileFlag },
          props.className
        )}
        onClick={() => {
          logClickEvent(currentFontLabel.value);
        }}
      >
        <p className={style["item-label"]}>{currentFontLabel.value}</p>
      </div>
    </Dropdown>
  );
}
