import { useCreation } from "ahooks";
import { CustomJSFunctionMainBloc } from "./CustomJSFunctionMainBloc";
import style from "./CustomJSFunction.module.css";
import { useEffect, useRef } from "react";
import LoadingOutline from "@views/components/LoadingOutline";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { Drawer, Popover } from "antd";
import VariablePopoverContent from "@views/thinking-layout-editor/VariablePopoverContent";
import GuidanceContent from "@views/thinking-layout-editor/GuidanceContent";
import { ChatBoxV2 } from "@uiview/views/Nodes/ChatBox";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import classNames from "classnames";

export function CustomJSFunctionMain(): React.JSX.Element {
  const bloc = useCreation(() => new CustomJSFunctionMainBloc(), []);
  const contentBoxRef = useRef<HTMLDivElement>(null);
  const createJSFuncData = useSelector((state: RootState) => state.editor.createJSFuncData);

  useEffect(() => {
    bloc.contentBoxRef = contentBoxRef.current;
  }, []);

  useEffect(() => {
    bloc.onChangeFunction(createJSFuncData);
  }, [createJSFuncData.function]);

  useEffect(() => {
    bloc.onChangeCodeData(createJSFuncData);
  }, [createJSFuncData.select]);

  const { htmlStr, cssStr, jsStr } = bloc.state.codeData.value;

  return (
    <Drawer
      className={classNames(style["customJSFunction-box"], createJSFuncData.open && style["occlusion"])}
      placement="bottom"
      open={createJSFuncData.open}
      closable={false}
      afterOpenChange={open => {
        !open && bloc.container.fotFunctionJSPage.gobackRoute();
      }}
      data-testid="CustomJSFunctionMain"
    >
      <div
        className={style["content-box"]}
        style={bloc.featureTags.enableStudiosCustomJsFunctionChatBox ? undefined : { width: "100%" }}
        ref={contentBoxRef}
      >
        {bloc.state.isLoading.value ? (
          <div className={style["example"]}>
            <LoadingOutline />
          </div>
        ) : null}
        <div className={style["code-box"]}>
          <div className={style["code-editor"]}>
            <div className={style["code-title"]}>HTML</div>
            <div className={style["code-text-box"]}>
              <CodeEditor
                className={style["CodeEditor"]}
                value={htmlStr}
                language="html"
                onChange={e => bloc.changeCodeData("htmlStr", e.target.value)}
              />
            </div>
          </div>
          <div className={style["code-editor"]}>
            <div className={style["code-title"]}>CSS</div>
            <div className={style["code-text-box"]}>
              <CodeEditor
                className={style["CodeEditor"]}
                value={cssStr}
                language="css"
                onChange={e => bloc.changeCodeData("cssStr", e.target.value)}
              />
            </div>
          </div>
          <div className={style["code-editor"]}>
            <div className={style["code-title"]}>JS</div>
            <div className={style["code-text-box"]}>
              <CodeEditor
                className={style["CodeEditor"]}
                value={jsStr}
                language="js"
                onChange={e => bloc.changeCodeData("jsStr", e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* <iframe className={style['preview-code']} ref={iframeRef}></iframe> */}

        <div className={style["tool-bar-box"]}>
          <div className={style["bottom-tool-bar"]}>
            <Popover content={<VariablePopoverContent showDelet={false} />}>
              <span className="iconfont icon-bianliang"></span>
            </Popover>

            {/* Custom JS function guidance */}
            {
              <Popover content={<GuidanceContent type="customJsFunc" />}>
                <span className="iconfont icon-i-info"></span>
              </Popover>
            }
          </div>
        </div>
      </div>
      {bloc.featureTags.enableStudiosCustomJsFunctionChatBox && (
        <div className={style.chatBoxWrap}>
          <ChatBoxV2 isOpen={createJSFuncData.open} />
        </div>
      )}
    </Drawer>
  );
}
