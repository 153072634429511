import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CreatorBackground } from "@uiview/views/ProjectCanvas/CreatorRoot/CreatorBackground";
import LoadingOutline from "@views/components/LoadingOutline";
import { getIt } from "@uikit/getIt";
import { FotFunctionJSPage } from "@uikit/cases/fotNavigate/FotFunctionJSPage";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { CustomJSFunctionMain } from "./CustomJSFunctionMain";

/**
 * 该组件表示 普通函数的路由页面, 因为没有单独实现 ui, 原来的ui 基本就是 canvas,所有相当于重新渲染一次 canvas
 * @returns
 */
export function CreatorFunctionJSPage(): React.JSX.Element | null {
  const fotFunctionJSPage = getIt(FotFunctionJSPage);
  const fotFunctionJSPageState = useImagicaStore(fotFunctionJSPage).value;

  const params = useParams();

  useEffect(() => {
    fotFunctionJSPage.init(params.id);

    return () => {
      fotFunctionJSPage.close(true);
    };
  }, [params.id]);

  if (!fotFunctionJSPageState.success) {
    return (
      <CreatorBackground>
        <LoadingOutline />
      </CreatorBackground>
    );
  }

  return <CustomJSFunctionMain />;
}
