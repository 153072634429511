import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import { createPortal } from "react-dom";
import style from "./PreviewV2ToolBar.module.scss";
import { useSignal } from "@preact/signals-react";
import {
  ToolBarConfigValue,
  ToolBarConfigKeyType,
  TOOLBAR_CONFIG,
  TOOLBAR_CONFIG_MOBILE,
  TipsType,
} from "./PreviewV2ToolBarUtil";
import { Popover } from "antd";
import { useAISaasPreviewItem } from "../AISaas/hooks/useAISaasPreviewItem";
import { GROUPID_REG } from "@views/thinking-layout-editor/constants";
import { logEvent } from "@uikit/service/amplitude";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { getIt } from "@uikit/getIt";
import { DomUtil } from "imagica-uikit/dist/base/util/DomUtil";
import { PreviewPaneStore, SettingPageContentType } from "../PreviewPane/PreviewPaneStore";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { DisableChatUtil } from "../MultiRoundChatOnboarding/DisableChatUtil";
import { matchMobile } from "@uikit/func";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";

const creatorSaasAppStore = getIt(CreatorSaasAppStore);

const initState: ToolBarConfigValue = {
  fontStyleKey: "",
  componentsConfig: [
    {
      component: null,
      tips: "",
    },
  ],
};
// main view
export function PreviewV2ToolBar(props: any): JSX.Element {
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const featureTags = homeStore.state.featureTags;
  const previewPaneStore = getIt(PreviewPaneStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const currentConfig = useSignal<ToolBarConfigValue>(initState);

  const {
    previewHoverData: {
      id,
      page = "",
      type,
      show,
      container,
      edit,
      remove,
      editPlaceholder,
      selectTips,
      talkBuilderEdit,
    },
    onPreviewItemMouseLeave,
  } = useAISaasPreviewItem();

  const isGroup = GROUPID_REG.test(id || "");
  const currentOutput = useMemo(() => {
    return (
      saasUIData.output.find((x: any) => {
        const currentId = isGroup ? x.groupId : x.results?.[0]?.previewId;
        return currentId === id;
      }) || {}
    );
  }, [saasUIData.output, id]);

  const onClickEdit = (tips: string | string[]): void => {
    edit && edit(tips);
  };

  const onClickTalkBuilderEdit = (): void => {
    talkBuilderEdit && talkBuilderEdit();
  };

  //  删除
  const onClickRemove = (): void => {
    console.log("remove");
    remove && remove();
  };
  //  编辑placeholder
  const onClickEditPlaceholder = (tips: string | string[]): void => {
    editPlaceholder && editPlaceholder(tips);
  };

  const onClickOpenAppDesign = (): void => {
    previewPaneStore.settingPageStatus(true);
    previewPaneStore.setSettingPageContentType(SettingPageContentType.design);
  };

  const clickOutputStateCp = (key: string): void => {
    const newOutputs = saasUIData.output.map((o: any) => {
      const currentId = isGroup ? o.groupId : o.results[0].previewId;
      if (currentId === id) {
        const isShowCaption = !o.results[0]?.[key] ?? false;

        logEvent("publish_preview_caption", { value: isShowCaption });
        return {
          ...o,
          results: [
            {
              ...o.results[0],
              [key]: !o.results[0]?.[key],
            },
          ],
        };
      }
      return o;
    });
    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        output: newOutputs,
      };
    });

    /// BSF-4552: 同时更新 thoughts 数据
    // bsf-4912 use setSassUIData
    creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: newOutputs }));
  };

  const clickOutputCssStyleCp = (key: string): void => {
    const keyObj: {
      [key: string]: any;
    } = {
      isBold: {
        styleKey: "fontWeight",
        style: {
          fontWeight: "bold",
        },
      },
      isItalic: {
        styleKey: "fontStyle",
        style: {
          fontStyle: "italic",
        },
      },
      isUnderLine: {
        styleKey: "textDecoration",
        style: {
          textDecoration: "underline",
        },
      },
      isBeautify: {
        styleKey: "background",
        defaultStyle: "rgba(255, 255, 255, 0.1)",
        style: {
          background: "transparent",
        },
      },
    };

    const newOutputs = saasUIData.output.map((i: any) => {
      const currentId = isGroup ? i.groupId : i.results[0].previewId;
      if (currentId === id) {
        // fontLabel是通过fontFamily来设定是否加粗
        let boldReset = {};
        const needHandleBold = key === "isBold" && i.results[0]?.fontStyleKey === "fontLabel";
        // 已设置则重置为空
        if (i.results[0]?.[key]) {
          if (needHandleBold) {
            boldReset = { fontFamily: "ak-regular" };
          }

          return {
            ...i,
            results: [
              {
                ...i.results[0],
                [key]: false,
                nodeFontStyle: {
                  ...(i.results[0].nodeFontStyle || {}),
                  [keyObj[key].styleKey]: keyObj[key]?.defaultStyle || "unset",
                  ...boldReset,
                },
              },
            ],
          };
        }

        // 设置值
        if (needHandleBold) {
          boldReset = {
            fontFamily: "ak-regular",
            fontWeight: 800,
          };
        }
        return {
          ...i,
          results: [
            {
              ...i.results[0],
              [key]: true,
              nodeFontStyle: {
                ...(i.results[0].nodeFontStyle || {}),
                ...keyObj[key].style,
                ...boldReset,
              },
            },
          ],
        };
      }
      return i;
    });

    creatorSaasAppStore.setSaasUIData((prevData: any) => {
      return {
        ...prevData,
        output: newOutputs,
      };
    });

    /// BSF-4552: 同时更新 thoughts 数据
    // bsf-4912 use setSassUIData
    creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: newOutputs }));
  };

  const getCurrentTips = (param: { dataStateKey?: string; tips: string | string[] }): any => {
    const { tips, dataStateKey } = param;
    if (Array.isArray(tips) === false) return tips;
    if (dataStateKey) {
      return (currentOutput as any).results?.[0]?.[dataStateKey] ? tips[1] : tips[0];
    }
    return tips;
  };

  useEffect(() => {
    if (isMobile && featureTags.disableMobilePage) {
      currentConfig.value = TOOLBAR_CONFIG_MOBILE[type as ToolBarConfigKeyType] || initState;
    } else {
      currentConfig.value = TOOLBAR_CONFIG[type as ToolBarConfigKeyType] || initState;
    }
  }, [currentConfig, type]);

  /**
   * chatOnboardingState.selectTemplateInModal !== ChoosingFormat.AUTOIMAGICA
   * 当进入chatonboarding模式，则不展示此toolbar
   * */
  const showToolBar = show && container;

  function disableClick(e: MouseEvent): void {
    /// Bar内部按钮不拦截
    if (
      !DomUtil.hasAttr(e.target, "id", "preview-toollbar-box") &&
      !DomUtil.hasAttr(e.target, "id", "AppDesignTextStyle")
    ) {
      onPreviewItemMouseLeave(e);
    }
  }

  const filterComponent = (each: any): boolean => {
    if (each.tips === "Ask about this feature") {
      return DisableChatUtil.isDisabled() === false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    document.addEventListener("click", disableClick, true);
    return (): void => {
      document.removeEventListener("click", disableClick);
    };
    // eslint-disable-next-line
  }, []);

  const logClickEvent = (title: string): void => {
    logEvent("click_preview_toolbar_button", { button_name: title });
  };

  const AllComponent = currentConfig.value.componentsConfig.filter(x => filterComponent(x));
  const TopComponent = AllComponent.filter(item => {
    const tip = Array.isArray(item.tips) ? item.dataStateKey || "" : item.tips;
    return TipsType.includes(tip);
  });
  const BottomComponent = AllComponent.filter(item => {
    const tip = Array.isArray(item.tips) ? item.dataStateKey || "" : item.tips;
    return !TipsType.includes(tip);
  });

  const renderItem = (c: any): JSX.Element => {
    const params = {
      ...props,
      id,
      isGroup,
      fontStyleKey: currentConfig.value.fontStyleKey,
      currentOutput,
      saasUIData,
      setSaasUIData: creatorSaasAppStore.setSaasUIData,
      onClickEdit: (): void => {
        onClickEdit(getCurrentTips(c));
      },
      onClickRemove,
      onClickTalkBuilderEdit,
      onClickEditPlaceholder: (): void => {
        onClickEditPlaceholder(getCurrentTips(c));
      },
      clickOutputStateCp,
      clickOutputCssStyleCp,
      onClickOpenAppDesign,
      highlightBackground: selectTips?.id === id && selectTips?.tips === getCurrentTips(c) && selectTips?.type === type,
      isMobile: isMobile && featureTags.disableMobilePage,
    };
    return React.createElement(c.component, { ...params });
  };
  return (
    <>
      {" "}
      {showToolBar
        ? createPortal(
            <div
              data-testid="PreviewV2ToolBar"
              className={style["toolbar-main"]}
              style={page === "chat" ? {} : { marginTop: 5 }}
            >
              <div
                id="preview-toollbar-box"
                className={classNames(
                  isMobile && featureTags.disableMobilePage ? style["toolbar-box-mobile"] : style["toolbar-box"],
                  { [style["toolbar-box-one-line"]]: AllComponent.length <= 2 }
                )}
              >
                {isMobile && featureTags.disableMobilePage ? (
                  <>
                    <div className={style.mobileTop}>
                      {TopComponent.map((c, index) => {
                        return c.component && <div key={index}>{renderItem(c)}</div>;
                      })}
                    </div>
                    <div className={style.mobileBottom}>
                      {BottomComponent.map((c, index) => {
                        return c.component && <div key={index}>{renderItem(c)}</div>;
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    {AllComponent.map((c, index) => {
                      return (
                        c.component && (
                          <Popover
                            autoAdjustOverflow={false}
                            overlayClassName={style.textPopover}
                            getPopupContainer={(e: any) => {
                              return e;
                            }}
                            content={<div className={style.tooltipContainer}>{getCurrentTips(c)}</div>}
                            key={index}
                          >
                            <div
                              className={style["popover-box"]}
                              onClick={() => logClickEvent(getCurrentTips(c) as any)}
                            >
                              {renderItem(c)}
                            </div>
                          </Popover>
                        )
                      );
                    })}
                  </>
                )}
              </div>
            </div>,
            container
          )
        : null}
    </>
  );
}
