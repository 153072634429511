import { Carousel } from "antd";
import styles from "./DesignTemplate.module.scss";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { CarouselRef } from "antd/lib/carousel";
import { CSSProperties, useEffect, useRef } from "react";
import { useSignal } from "@preact/signals-react";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { CarouselType } from "./Const";
import { PreviewPaneBloc } from "../../PreviewPaneBloc";
import { ThemeType } from "imagica-corekit/dist/cases/theme/appTheme/AiAppThemeData";
import { ChatThemeType } from "imagica-corekit/dist/cases/theme/chatTheme/ChatAppThemeData";
import { PowerMaskView } from "@uiview/views/PowerMask/PowerMaskView";
import { AppearanceUtil, TemplateThemeType } from "./AppearanceUtil";
import func, { matchMobile } from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { PowerMaskUitl } from "@uiview/views/PowerMask/PowerMaskUtil";
import { logEvent } from "@uikit/service/amplitude";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import classNames from "classnames";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";

export function DesignTemplate(props: { bloc: PreviewPaneBloc }): JSX.Element {
  const carouselRef = useRef<CarouselRef | null>(null);
  const currentSlideIndx = useSignal(0);
  const currentSelectDesign = useSignal("light"); // 默认为light

  const creatorSaasAppStore = getIt(CreatorSaasAppStore);
  const creatorSaasAppState = useImagicaStore(creatorSaasAppStore).value;
  const { saasUIData } = creatorSaasAppState;
  const webConfigState = useImagicaStore(getIt(WebConfigStore)).value;
  const isChatApp = saasUIData?.v2App?.type === "chat_ai";
  const templatesList = webConfigState.imagicaAppTemplates;
  const isMobile = matchMobile();
  const homeStore = getIt(HomeStore);
  const featureTags = homeStore.state.featureTags;

  const CarouselList = isChatApp
    ? AppearanceUtil.getThemes(TemplateThemeType.CHATAI, templatesList)
    : AppearanceUtil.getThemes(TemplateThemeType.AIAPP, templatesList);
  // const CarouselList = isChatApp ? chatCarouselList : defaultCarouselList;
  const title = isChatApp ? "Chat template" : "Design template";

  const isFirstSlide = currentSlideIndx.value === 0;
  const isLastSlide = currentSlideIndx.value === CarouselList.length - 1;

  const onChange = (currentSlide: number): void => {
    currentSlideIndx.value = currentSlide;
  };
  const changecurrentCarousel = (direction: string): void => {
    if (!carouselRef.current) return;
    if (direction === "left" && !isFirstSlide) {
      carouselRef.current.prev();
    }
    if (direction === "right" && !isLastSlide) {
      carouselRef.current.next();
    }
  };
  const directionStyl = (boo: boolean): CSSProperties => {
    return {
      fontSize: "10px",
      width: "20px",
      height: "20px",
      textAlign: "center",
      lineHeight: "20px",
      color: boo ? "#12192B59" : "#12192B",
      cursor: boo ? "not-allowed" : "pointer",
    };
  };

  const clickTemplate = async (feature: string[], theme: ThemeType | ChatThemeType | undefined): Promise<void> => {
    if (await PowerMaskUitl.disAbleByFeature(feature)) {
      return;
    }
    if (!theme) return;
    if (isChatApp) {
      props.bloc.changeChatTheme(theme as ChatThemeType);
    } else {
      props.bloc.changeTheme(theme as ThemeType);
    }
    currentSelectDesign.value = theme;
    logEvent("publish_preview_settings", { color_theme: theme });
  };

  useEffect(() => {
    if (saasUIData?.theme) {
      currentSelectDesign.value = saasUIData.theme;
      if (!carouselRef.current) return;
      const idx = CarouselList.findIndex((x: any) => {
        return x.some((j: CarouselType) => j.key === saasUIData.theme);
      });
      carouselRef.current.goTo(idx);
    }
  }, [saasUIData?.theme]);

  return (
    <div
      className={classNames(styles.DesignTemplate, {
        [styles.DesignTemplateMobile]: isMobile && featureTags.disableMobilePage,
      })}
    >
      <div className={styles.title}>
        <div>{title}</div>
        <div>
          <LeftOutlined style={directionStyl(isFirstSlide)} onClick={() => changecurrentCarousel("left")} />
          <RightOutlined style={directionStyl(isLastSlide)} onClick={() => changecurrentCarousel("right")} />
        </div>
      </div>
      <Carousel afterChange={onChange} dotPosition="bottom" ref={carouselRef} className="designCarouselBox">
        {!func.isEmpty(CarouselList) &&
          CarouselList.map((arr: Array<any>, index: number) => {
            return (
              <div key={"DesignTemplate" + index} className={styles.carouselBox}>
                {arr.map((x: CarouselType) => {
                  return (
                    <PowerMaskView
                      key={x.key}
                      feature={x.features}
                      featureItem={`DesignTemplate-${isChatApp ? "chat" : "ai-app"}-${x.key}`}
                      enableCls={true}
                    >
                      <img
                        key={x.key}
                        onClick={() => clickTemplate(x.features, x.key)}
                        className={classNames(
                          styles.carouselImg,
                          currentSelectDesign.value === x.key ? styles.activeImg : ""
                        )}
                        src={x.image}
                        alt="icon"
                      />
                    </PowerMaskView>
                  );
                })}
              </div>
            );
          })}
      </Carousel>
    </div>
  );
}
