import { DEFAULT_ALLOWZOMMORPAN } from "@custom-hooks/HomeUsePluginRoot";
import { TextAreaRef } from "antd/lib/input/TextArea";
import { GenUtil } from "imagica-corekit/dist/base/cutil/GenUtil";
import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { MutableRefObject } from "react";

class CustomNewEdgeState {
  showCommandbar = !DEFAULT_ALLOWZOMMORPAN;
  edgeDisplayType: CustomEdge.EdgeType | undefined;

  copyWith(params: { showCommandbar?: boolean; edgeDisplayType?: CustomEdge.EdgeType }): CustomNewEdgeState {
    return GenUtil.copyWith(new CustomNewEdgeState(), this, params);
  }
}

export type CustomNewEdgeRefs = {
  aiDescriptionInputRef: React.RefObject<TextAreaRef>;
  commandInputRef: React.LegacyRef<HTMLInputElement>;
  isManualRun: MutableRefObject<boolean>;
};
export class CustomNewEdgeStore extends StoreBase<CustomNewEdgeState> {
  constructor(public edgeProps: CustomEdge.CustomNewEdgeProps, public refs: CustomNewEdgeRefs) {
    super(() => new CustomNewEdgeState());
  }

  changeDisplayType(edgeDisplayType?: CustomEdge.EdgeType): void {
    this.emit(this.state.copyWith({ edgeDisplayType }));
  }

  isDisplayInput(): boolean {
    return this.state.edgeDisplayType === "input";
  }

  hasShowCommandbar(): boolean {
    return this.state.showCommandbar && this.isDisplayInput();
  }
}
