import { FocusNode } from "@uikit/service/FocusNode";
import { CustomNewEdgeRefs, CustomNewEdgeStore } from "./CustomNewEdgeStore";
import { MoveToHandlerResult } from "@uikit/cases/canvasFocus/FocusController";
import { HomeToolBarStore } from "@uikit/store/homeToolBarStore";
import { EdgeCommandbar } from "./EdgeCommandbar";

export type CustomNewEdgeArgsContainer = {
  focusNode: FocusNode;
  homeToolBarStore: HomeToolBarStore;
};

/**
 * 该 class 后期相关的服务或逻辑都放在这里
 *
 * 也就是一条边对应个 CustomNewEdgeContainer 实例
 *
 * !!! 尽量拆分代码到不同的 class 中,否则这个 class 会变得异常的多
 */
export class CustomNewEdgeContainer {
  readonly store: CustomNewEdgeStore;
  readonly commandbar: EdgeCommandbar;

  constructor(
    private edgeProps: CustomEdge.CustomNewEdgeProps,
    public refs: CustomNewEdgeRefs,
    container: CustomNewEdgeArgsContainer
  ) {
    this.store = new CustomNewEdgeStore(edgeProps, refs);
    this.commandbar = new EdgeCommandbar(this.store, container);
  }

  get edgeType(): CustomEdge.EdgeType | undefined {
    return this.store.state.edgeDisplayType;
  }

  updateEdgeProps(args?: Partial<CustomEdge.CustomNewEdgeProps>): void {
    if (args) {
      this.edgeProps = { ...this.edgeProps, ...args };
    }
  }

  /**
   * 打开 commandbar
   * @returns
   */
  openCommandbar(): Promise<MoveToHandlerResult> | undefined {
    return this.commandbar.openCommandbar();
  }

  /**
   * 关闭 commandbar
   * @param targetDom
   */
  closeCommandbar(targetDom?: unknown): void {
    return this.commandbar.closeCommandbar(targetDom);
  }
}
