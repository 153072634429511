import { useRef } from "react";
import { createContainer } from "unstated-next";
import { useEdgeCommonState } from "./useEdgeCommonState";
import { getIt } from "@uikit/getIt";
import { HomeToolBarStore } from "@uikit/store/homeToolBarStore";
import { useCreation } from "ahooks";
import { FocusNode } from "@uikit/service/FocusNode";
import { CustomNewEdgeContainer } from "./CustomNewEdgeContainer";
import { TextAreaRef } from "antd/lib/input/TextArea";

// eslint-disable-next-line
function useContainer(props?: CustomEdge.CustomNewEdgeProps) {
  const aiDescriptionInputRef = useRef<TextAreaRef>(null);
  const commandInputRef = useRef<HTMLInputElement>(null);
  const isManualRun = useRef(false);

  const edgeContainer = useCreation(
    () =>
      new CustomNewEdgeContainer(
        props as any,
        { aiDescriptionInputRef, commandInputRef, isManualRun },
        {
          focusNode: getIt(FocusNode),
          homeToolBarStore: getIt(HomeToolBarStore),
        }
      ),
    []
  );

  edgeContainer.updateEdgeProps(props);

  const { source = "", id = "" } = props || {};

  // state
  const edgeCommonState = useEdgeCommonState();

  return {
    ...edgeCommonState,

    sourceId: source,
    edgeId: id,

    store: edgeContainer.store,
    edgeContainer,

    aiDescriptionInputRef,
    commandInputRef,
  };
}

export type CustomNewEdgeContainerType = ReturnType<typeof useCustomEdgeContainer>;

export const { Provider: CustomEedgProvider, useContainer: useCustomEdgeContainer } = createContainer(useContainer);
